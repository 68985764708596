async function getSchoolColors() {
    const a = await import('../files/schoolcolors.csv');
    return readCsv(a.default);
}
const colors = await getSchoolColors();
export function getSchoolColor(fullSchoolName) {
    if(!fullSchoolName)return null;
    const color = colors.find((value) => {
        if (value.University === fullSchoolName) {
            return value;
        }
    });
    return color;
}

async function readCsv(path) {
    const b = await fetch(path);
    const text = await b.text();
    return await csvToObject(text);
}

async function csvToObject(text) {
    const [keys, ...rest] = text.trim()
        .split('\n')
        .map((item) => item.split(','));

    const obj = rest.map((item) => {
        const object = {};
        keys.forEach((key, index) => {
            key = key ? key.replaceAll(' ', '').trim() : key;
            const value = item.at(index) ? item.at(index).trim() : item.at(index);
            object[key] = value;
        });
        return object;
    });

    return obj;
}