import { BsInput } from "../served-components/form/Input";
import { BsSelect } from "../served-components/form/select";
import { BsButton } from "../served-components/form/button";
import { BsTextarea } from "../served-components/form/textarea";
import { BsCheckBox } from "../served-components/form/checkbox";

export default function Inputs({ inputs }) {
    let inputType = 'text';

    return (
        <>
            {inputs.map(obj => {
                if (!obj) return '';
                if (obj.jsx) {
                    return obj.jsx;
                }
                if (obj.inputType) { inputType = obj.inputType; }
                if (inputType === 'select') {
                    return <BsSelect key={'_' + obj.id} {...obj} />
                }
                else if (inputType === 'button') {
                    return <BsButton key={'_' + obj.id} buttonType={obj.buttonType} btnClass={obj.btnClass} buttonName={obj.buttonName} id={obj.id} fullWidth={obj.fullWidth} jsEvent={obj.jsEvent} />
                }
                else if (inputType === 'textarea') {
                    return <BsTextarea key={'_' + obj.id} {...obj} />
                }
                else if (inputType === 'checkbox') {
                    return <BsCheckBox key={'_' + obj.id} {...obj} />
                }
                else {
                    return <BsInput key={'_' + obj.id} {...obj} />
                }
            })}
        </>
    )
}
