import { useEffect } from "react";
import { setColorByFullSchool } from "../../helpers/setColors"

const NoleCoin = () => {
    useEffect(()=>{
        setColorByFullSchool('Florida State University');
    })
    return (
        <div className="container">
            <h1 id="empowering-student-athletes-a-tokenized-nil-funding-solution">Empowering Student Athletes: Tokenized NIL Funding</h1>
<h2 id="abstract">Abstract</h2>
<p>In recent years, the issue of compensating student-athletes in college sports has become a subject of intense debate. While collegiate sports generate billions of dollars in revenue, the athletes themselves often struggle to meet their financial needs. This white paper presents a novel solution: the creation of a tokenized funding system designed to raise money specifically for university student-athletes. By leveraging blockchain technology and tokenization, this project aims to provide financial support to student-athletes while fostering transparency and accountability in college sports programs.</p>
<h2 id="introduction">Introduction</h2>
<p>College sports in the United States have long been a source of pride and entertainment, attracting millions of fans and generating substantial revenue for universities. However, the current system has faced criticism for its treatment of student-athletes, who often receive limited financial support despite their contributions to the success of collegiate sports programs. This white paper proposes a new approach to address this issue, offering a tokenized funding model that empowers student-athletes and ensures they receive the support they deserve.</p>
<h2 id="the-problem">The Problem</h2>
<p>Student-athletes face numerous challenges, both on and off the field. While they dedicate countless hours to training and competition, many struggle to afford basic necessities such as food, housing, and healthcare. Additionally, NCAA regulations restrict student-athletes from earning income through endorsements or other commercial opportunities, further limiting their financial resources. As a result, student-athletes are often forced to prioritize athletics over academics or even consider leaving school altogether to pursue professional opportunities.</p>
<h2 id="the-solution">The Solution</h2>
<p>Our proposed solution involves the creation of a tokenized funding system that allows supporters to contribute directly to student-athletes&#39; financial needs. Through the issuance of digital tokens on a blockchain platform, we can facilitate transparent and secure transactions while enabling greater flexibility and autonomy for student-athletes. These tokens can be redeemed for various goods and services, including tuition assistance, housing stipends, and training expenses, providing student-athletes with the support they need to succeed both in sports and academics.</p>
<h2 id="tokenomics-and-fair-launch">Tokenomics and Fair Launch</h2>
<p>This project operates on a fair launch principle, leveraging a Bonding Curve to seed Raydium and burn all the liquidity tokens. The fair launch ensures equitable distribution and participation, fostering community engagement and trust. Through the Bonding Curve mechanism, supporters can acquire tokens at a fair and transparent price, while excess liquidity is removed from the market, enhancing token value stability and reducing volatility. This approach aligns with the project&#39;s commitment to fairness, transparency, and sustainability.</p>
<table>
<thead>
<tr>
<th style={{textAlign:'left'}}>Noleonomics</th>
<th style={{textAlign:'center'}}>Percentage</th>
<th style={{textAlign:'center'}}>Tokens</th>
<th style={{textAlign:'center'}}>Supply%</th>
</tr>
</thead>
<tbody>
<tr>
<td style={{textAlign:'left'}}>Total Supply</td>
<td style={{textAlign:'center'}}>100%</td>
<td style={{textAlign:'center'}}>1,000,000,000</td>
<td style={{textAlign:'center'}}>-</td>
</tr>
<tr>
<td style={{textAlign:'left'}}>Project Wallet(s)</td>
<td style={{textAlign:'center'}}>51%</td>
<td style={{textAlign:'center'}}>510,000,000</td>
<td style={{textAlign:'center'}}>-</td>
</tr>
<tr>
<td style={{textAlign:'left'}}>Dev/Marketing Wallet</td>
<td style={{textAlign:'center'}}>25%</td>
<td style={{textAlign:'center'}}>127,500,000</td>
<td style={{textAlign:'center'}}>12.75%</td>
</tr>
<tr>
<td style={{textAlign:'left'}}>NIL Donations Wallet</td>
<td style={{textAlign:'center'}}>25%</td>
<td style={{textAlign:'center'}}>127,500,000</td>
<td style={{textAlign:'center'}}>12.75%</td>
</tr>
<tr>
<td style={{textAlign:'left'}}>Staking Wallet</td>
<td style={{textAlign:'center'}}>25%</td>
<td style={{textAlign:'center'}}>127,500,000</td>
<td style={{textAlign:'center'}}>12.75%</td>
</tr>
<tr>
<td style={{textAlign:'left'}}>Burn Wallet</td>
<td style={{textAlign:'center'}}>25%</td>
<td style={{textAlign:'center'}}>127,500,000</td>
<td style={{textAlign:'center'}}>12.75%</td>
</tr>
<tr>
<td style={{textAlign:'left'}}>Community Allocation</td>
<td style={{textAlign:'center'}}>49%</td>
<td style={{textAlign:'center'}}>490,000,000</td>
<td style={{textAlign:'center'}}>-</td>
</tr>
<tr>
<td style={{textAlign:'left'}}>Total Supply After Roadmap</td>
<td style={{textAlign:'center'}}>100%</td>
<td style={{textAlign:'center'}}>872,500,000</td>
<td style={{textAlign:'center'}}>100%</td>
</tr>
<tr>
<td style={{textAlign:'left'}}>Community @ 1B MC</td>
<td style={{textAlign:'center'}}>70.77%</td>
<td style={{textAlign:'center'}}>617,500,000</td>
<td style={{textAlign:'center'}}>70.77%</td>
</tr>
<tr>
<td style={{textAlign:'left'}}>Dev/Marketing Wallet</td>
<td style={{textAlign:'center'}}>14.61%</td>
<td style={{textAlign:'center'}}>127,500,000</td>
<td style={{textAlign:'center'}}>14.61%</td>
</tr>
<tr>
<td style={{textAlign:'left'}}>Staking for LP/AirDrops</td>
<td style={{textAlign:'center'}}>14.61%</td>
<td style={{textAlign:'center'}}>127,500,000</td>
<td style={{textAlign:'center'}}>14.61%</td>
</tr>
</tbody>
</table>
<h2 id="implementation" className="mt-2">Implementation</h2>
<p>The implementation of our tokenized funding system will involve collaboration with universities, athletic departments, and regulatory bodies to ensure compliance with existing rules and regulations. Smart contracts will be utilized to automate the distribution of tokens and track how funds are spent, providing transparency and accountability at every step of the process. We will also develop a user-friendly platform where supporters can purchase tokens, track their contributions, and engage with the student-athletes they are supporting.</p>
<h2 id="conclusion">Conclusion</h2>
<p>By leveraging blockchain technology and tokenization, we have the opportunity to revolutionize the way we support student-athletes in college sports programs. Our tokenized funding system offers a transparent, secure, and efficient way for supporters to contribute to the success of student-athletes while empowering them to pursue their athletic and academic goals. Together, we can create a brighter future for the next generation of student-athletes and ensure they receive the support they need to thrive both on and off the field.</p>
<p>This white paper serves as a roadmap for the development and implementation of our token project to raise money for university student-athletes. Through collaboration and innovation, we can build a more equitable and sustainable future for college sports programs and the athletes who make them possible.</p>
<h2 id="execution">Execution</h2>
<p>Fair Launch Project Deployment: </p>
<p>6:00 AM EST on 4/20/2024</p>
<p>Token Project will be launched on Pump.Fun (A easy to use Fair Use LaunchPad) and then seeded into Raydium automatically once 80k Market Cap is Reached. The Mint is Revoked (No new Tokens can be Made) and Liquidity Pool Tokens Burned.</p>
<p>Dev is Based and will be burning Tokens though out the launch to continue to see Big Green Candles.</p>
<p>Simply Connect you Wallet (Phantom or Solflare are common wallets) to Pump.Fun to buy NOLE Tokens with SOLANA</p>
{/* <p>If you are not able Aququire Token prior  to seeding to Raydium you will have another chance to Buy Tokens when goes live in Raydium 5-30m after the Bonding Curve has Completed. </p>
<p>Tokens can be traded on Pump/Fun, Trojan Solana Bot on Telegram, Raydium/Jupiter from DexScreener, or any DEX of your choice that supports the Solana Network.</p> */}
      
      <p>If you are not able Purchase the $NOLE Token prior  to seeding to Raydium you will have another chance to Buy Tokens when the Token goes live in Raydium 5-30m after the Bonding Curve has Completed. </p>
<p>Tokens can be traded Directly on Pump.Fun,  <a href="https://t.me/solana_trojanbot?start=r-clstealth654753">Trojan Solana Bot on Telegram (Fastest)</a> , Radyium/Jupiter from DexScreener, or any DEX of your choice that supports the Solana Network.</p>
        </div>
    )
}

export default NoleCoin