export function sData(key, value) {
    if (!key) {
        return getSData();
    }
    if (value === undefined) {
        return getSInfo(key);
    }
    return setSInfo(key, value);
}
export function setSData(data) {
    if (!data) return false;

    if (typeof data === 'object') {
        localStorage.setItem('sData', JSON.stringify(data));
        return true;
    }
}
export function clearSData() {
    localStorage.removeItem('sData');
    return true;
}

function getSData(localStorageKey = 'sData') {
    const data = localStorage.getItem(localStorageKey);
    if (!data) {
        return {};
    }
    return JSON.parse(data);
}

function getSInfo(key, localStorageKey = 'sData') {
    const data = getSData(localStorageKey);
    return data[key];
}

function setSInfo(key, value, localStorageKey = 'sData') {
    if (!key) return false;
    const data = getSData();
    data[key] = value;
    localStorage.setItem(localStorageKey, JSON.stringify(data));
    return true;
}


/**
 * storageData
 * 
 * @param {string} localStorageKey 
 */
const storageData = (localStorageKey) => {
    if (!localStorageKey) return;
    // ----------------------------------------------
    const setFullData = (data) => {
        if (!data) return false;

        if (typeof data === 'object') {
            localStorage.setItem(localStorageKey, JSON.stringify(data));
            return true;
        }
    }
    // ----------------------------------------------
    const clearAll = () => {
        localStorage.removeItem(localStorageKey);
        return true;
    }
    // ----------------------------------------------
    const val = (key, value) => {
        if (!key) {
            return getSData(localStorageKey);
        }
        if (value === undefined) {
            return getSInfo(key, localStorageKey);
        }
        return setSInfo(key, value);
    }
    // ----------------------------------------------

    return { setFullData, clearAll, val };
}

/**
 * uData - handle user data.
 */
export const uData = () => {
    return storageData('uData');
}
