export function val(selector, value) {
    const element = document.querySelector(selector);
    if (element) {
        if (typeof value === 'undefined') {
            return element.value;
        }
        element.value = value;
    }
    return '';
}

export const multiSelectValue = (element, value) => {
    if (value) {
        if (typeof value === 'string') {
            value = value.split(',');
        }
        for (const option of element.options) {
            if (value.includes(option.value) && value) {
                option.selected = true;
            } else {
                option.selected = false;
            }
        }
    } else if (typeof element === 'object') {
        return Array.from(element.selectedOptions)
            .map(option => option.value)
            .reduce((acc, element) => element ? [...acc, element] : acc, [])
            .join(',');
    }
    return '';
}

export function isObjEmpty(obj) {
    if (obj && typeof obj === 'object')
        return Object.keys(obj).length === 0;
    return true;
}

export const formatCurrency = (value) => {
    if (!value) return '';
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(value).replace('.00', '');
};
