import FormInput from "../../helpers/form";
import { ShieldLockFill } from "../../served-components/icons";
import { getFormData } from "../../helpers/formData";
import { useNavigate, useParams } from "react-router-dom";
import { addPassword } from "../../api/rankcollegeApi";
import { setLoginDetail } from "../../helpers/loginCheck";
import { useEffect } from "react";
import { setColorByFullSchool } from "../../helpers/setColors";
import { sData } from "../../helpers/sData";
import Footer from "../../served-components/bottom/footer";
import { formateErrors } from "../../helpers/showErrors";

export default function CreatePassword() {
    const params = useParams();
    const navigate = useNavigate();

    function submitLogin(e) {
        const FD = getFormData(e.currentTarget.id);
        addPassword(params.id, FD).then(res => {
            alert(formateErrors(res.msg));
            if (res.success == true) {
                setLoginDetail(res.data);
                navigate('/u/leaderboard');
            }
        });
    }
    useEffect(() => {
        setColorByFullSchool(sData('FullSchoolName'));
    }, [])

    return (
        <>
            <div className="main-body school-main d-flex align-items-center justify-content-center">
                <div className="container p-2 d-flex flex-column justify-content-center align-items-center">
                    <div className="mt-5"></div>
                    <div className="w-100 d-flex justify-content-center">
                        <FormInput inputs={[
                            { inputType: 'password', label: 'Password', id: 'password', inputGroup: <ShieldLockFill />, required: true },
                            { inputType: 'password', label: 'Confirm Password', id: 'password_confirmation', inputGroup: <ShieldLockFill />, required: true },
                            { inputType: 'button', buttonName: 'Add Password', btnClass: 'primary rounded-pill', id: 'addPassword', fullWidth: true },
                        ]} forms={{ onSubmit: submitLogin, id: 'loginForm' }} />
                    </div>
                </div>
            </div>
            {sData('FullSchoolName') === 'Florida State University' && <Footer />}
        </>
    );
}
