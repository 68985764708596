import { uniqKey } from "../../helpers/key";

export function TablePlaceholder({ tr, td }) {
    return (
        <>
            {Array.from(new Array(Number(tr))).map((v, i) => {
                return (<tr key={uniqKey(i)} className="placeholder-glow">
                    {Array.from(new Array(Number(td))).map((v, i) => {
                        return <td key={uniqKey(i)} ><span className="placeholder col-12"></span></td>
                    })}
                </tr>)
            })}
        </>
    );
}