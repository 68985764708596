import { useNavigate } from "react-router-dom";
import FormInput from "../../helpers/form";
import { EnvelopeAt } from "../../served-components/icons";
import { useEffect } from "react";
import { setColorByFullSchool } from "../../helpers/setColors";
import { sData } from "../../helpers/sData";
import { isLogedIn } from "../../helpers/loginCheck";
import { getFormData } from "../../helpers/formData";
import { forgotPassword } from "../../api/rankcollegeApi";
import { formateErrors } from "../../helpers/showErrors";

function ForgotPassword() {
    const navigate = useNavigate();
    useEffect(() => {
        setColorByFullSchool(sData('FullSchoolName'));
        if (isLogedIn()) {
            navigate('/');
        }
    }, [navigate]);

    async function handleSubmit(e) {
        const FD = getFormData(e.currentTarget.id);
        const res = await forgotPassword(FD);
        alert(formateErrors(res.msg));
        if (res.success) {
            navigate('/login');
        }
    }

    return (
        <div className="vh-80 d-flex align-items-center justify-content-center main-body">
            <div className="container p-2 d-flex flex-column justify-content-center align-items-center">
                <img src='navlogo/logo-png.png' className="img-fluid mb-3" alt="logo" height={200} width={200} />
                {/* <div className="mt-5"></div> */}
                <div className="w-100 d-flex justify-content-center">
                    <FormInput inputs={[
                        { inputType: 'text', label: 'Email', id: 'email', inputGroup: <EnvelopeAt />, required: false },
                        { inputType: 'button', buttonName: 'Send Password Set/Reset Link', btnClass: 'primary rounded-pill', id: 'forgetPasswordFormBtn', fullWidth: true },
                    ]} forms={{ onSubmit: handleSubmit, id: 'forgetPasswordForm' }} />
                </div>
                {/* <span><Link to={'/referral'}>Sign Up</Link></span> */}
            </div>
        </div>
    );
}

export default ForgotPassword;
