import { useNavigate } from "react-router-dom"
import { deleteLoginDetail } from "../helpers/loginCheck";
import { useEffect } from "react";
import { logout } from "../api/rankcollegeApi";

export default function Logout(){
    const navigate = useNavigate();
    useEffect(()=>{
        logout().then(()=>{
            deleteLoginDetail();
            navigate('/');
        });
    },[])
    return(
        <p>Loging out...</p>
    )
}
