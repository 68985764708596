import { forwardRef, useRef, useState } from "react";
import ReactInputMask from "react-input-mask";
import { formatCurrency } from "../../helpers/val";
import { validator } from "./validation";
// import { checkUsernameEmail } from "../../api/rankcollegeApi";

export function BsInput({ inputType, inputName, id, label, placeholder, onInput = null, className = '', row = false, inputGroup = null, required = false, useref = null, formateType = null, defaultValue = '', validation = '', onBlurEvent = null, validationOnChange }) {

    const invalidFeedback = useRef();
    const [isInValid, setIsInValid] = useState(false);

    const onBlur = async (e, validation) => {
        const validate = await validator(e.currentTarget.value, validation);
        if (!validate.isValid) {
            if (invalidFeedback.current) {
                if (validate.errros) {
                    invalidFeedback.current.innerText = validate.errros;
                } else {
                    invalidFeedback.current.innerText = `Value for ${label} field is not valid.`;
                }
            }
            if (validate.newValue) {
                e.target.value = validate.newValue;
            }
            setIsInValid(true);
        } else {
            setIsInValid(false);
        }
        if (onBlurEvent && typeof onBlurEvent === 'function') {
            onBlurEvent(e);
        }
    }

    const handleOnChange = async (e, validation) => {
        if (!validation) return;
        await onBlur(e, validation);
    }
    if (inputGroup) {
        return (
            <InputGroup inputGroup={inputGroup} inputElement={<BsInputA label={label} inputType={inputType} id={id} onInput={onInput} className={className} required={required} onBlur={onBlur} onChange={handleOnChange} ref={invalidFeedback} validation={validation} validationOnChange={validationOnChange} isInValid={isInValid} />} isInValid={isInValid} />
        );
    }
    else if (formateType === 'phone') {
        return (
            <div className="form-floating mb-3">
                <ReactInputMask
                    mask={'(999) 999-9999'}
                    className={`form-control ${className}`}
                    placeholder={placeholder ?? label}
                    ref={useref}
                    id={id}
                    name={inputName}
                    maskChar={null}
                />
                <label htmlFor={id}>{label}</label>
            </div>
        );
    }
    else if (formateType === 'currency') {
        return (
            <Currency label={label} inputType={inputType} id={id} onInput={onInput} className={className} required={required} useref={useref} />
        );
    }
    return (
        inputType === 'hidden'
            ?
            <input type="hidden" id={id} name={inputName} ref={useref} />
            :
            <div className={`form-floating mb-3 ${isInValid ? 'is-invalid' : ''}`}>
                <input type={inputType} className={`form-control ${className} ${isInValid ? 'is-invalid' : ''}`} id={id} placeholder={placeholder ?? label} name={inputName} onInput={e => onInput && onInput(e)} onChange={(e) => handleOnChange(e, validationOnChange)} ref={useref} defaultValue={defaultValue} onBlur={(e) => onBlur(e, validation)} data-validation-failed-msg={isInValid?invalidFeedback.current.innerText:''}/>
                <label htmlFor={id}>{label}</label>
                <div ref={invalidFeedback} className="invalid-feedback">
                    Please choose a username.
                </div>
            </div>
    );
}

export function Input({ inputType, inputName, id, placeholder }) {
    return (
        <input type={inputType} name={inputName} id={id} placeholder={placeholder} />
    );
}
function InputGroup(props) {
    return (
        <div className={`input-group mb-3 ${props.isInValid ? 'has-validation' : ''}`}>
            <span className="input-group-text">{props.inputGroup}</span>
            {props.inputElement}
        </div>
    )
}

const BsInputA = forwardRef(function BsInputA(props, ref) {
    return (
        <div className={`form-floating ${props.isInValid ? 'is-invalid' : ''}`}>
            <input type={props.inputType} className={`form-control ${props.className}`} id={props.id} placeholder={props.placeholder ?? props.label} name={props.inputName} onInput={e => props.onInput && props.onInput(e)} required={props.required} onBlur={(e) => props.onBlur(e, props.validation)} onChange={(e) => props.onChange(e, props.validationOnChange)} />
            <label htmlFor={props.id}>{props.label}</label>
            <div ref={ref} className="invalid-feedback">
                Please choose a username.
            </div>
        </div>
    )
});

function Currency(props) {
    const handleChange = (e) => {
        const input = e.target.value.replace(/[^0-9.]/g, '');
        e.target.value = formatCurrency(input).replace('.00', '');
    };

    return (
        <div className="form-floating mb-3">
            <input type='text' className={`form-control ${props.className}`} id={props.id} placeholder={props.placeholder ?? props.label} name={props.inputName} onInput={e => props.onInput && props.onInput(e)} required={props.required} onChange={handleChange} ref={props.useref} />
            <label htmlFor={props.id}>{props.label}</label>
        </div>
    );
}
