import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom"
import { getSchoolData } from "../../api/rankcollegeApi";
import SchoolNavbar from "../../served-components/header/schoolnav";
import BsTable from "../../served-components/table";
import { Link } from "react-router-dom";
import CopyLink from "../../served-components/copy-link";
import { setColorByFullSchool } from "../../helpers/setColors";
import { sData, setSData, uData } from "../../helpers/sData";
import { FullScreenBubbleLoader } from "../../served-components/spinner/growingSpinner";
import Footer from "../../served-components/bottom/footer";

export default function SchoolDetail() {
    const params = useParams();
    const [data, setData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const location = useLocation();
    const [showFullScreenSpinner, setShowFullScreenSpinner] = useState(true);
    const [sortOrder, setSortOrder] = useState('desc');
    const [sortBy, setSortBy] = useState('TotalRasied');

    useEffect(() => {
        setShowFullScreenSpinner(true);
        const forUser = location.pathname.startsWith('/u/leaderboard');
        getSchoolData(params.school, forUser, currentPage, itemsPerPage, sortOrder, sortBy).then((res) => {
            setShowFullScreenSpinner(false);
            const resData = res.data ? res.data : {};
            setData(resData);
            setSData(resData);
            if (forUser) {
                uData().setFullData(resData);
            }
            sData('ParamsSchool', params.school);
            sData('currentSchool', params.school ? params.school : resData.School);
            setTotalPages(res.totalPages);
        });
    }, [params.school, location.pathname, currentPage, itemsPerPage, sortOrder, sortBy]);
    let fullSchoolName = location.state && location.state.full_school_name ? location.state.full_school_name : '';
    if (!fullSchoolName) {
        fullSchoolName = data ? data.FullSchoolName : '';
    }
    setColorByFullSchool(fullSchoolName);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const setItem = (e) => {
        setItemsPerPage(e.currentTarget.value);
        setCurrentPage(1);
    }

    const handleApiSort = (key) => {
        if (sortBy === key && sortOrder === 'asc') {
            setSortOrder('desc');
        } else {
            setSortOrder('asc');
        }
        setSortBy(key);
    }


    // const cards = [
    //     { title: data.FullSchoolName, img: { src: logo }, btn: { name: 'Read more' }, text: <TextTruncation text={data.Description} /> },
    //     { title: data.FullSchoolName, img: { src: logo }, btn: { name: 'Read more' }, text: <TextTruncation text={data.Description} /> },
    //     { title: data.FullSchoolName, img: { src: logo }, btn: { name: 'Read more' }, text: <TextTruncation text={data.Description} /> }
    // ];
    // const columns = ['Tier', 'CA User@', 'RE Referrals', 'CA Referrals', 'Deals Rasied'];
    const columns = ['Tier', 'User@', 'Referrals|colpsan=2', 'Deals', 'Raised'];
    const tableData = data.leaderboard;
    const tcolumns = ['Members', 'Referrals', 'Raised'];
    const totalTable = data.totalTalbe ?? {};
    const ttableData = [{ Members: totalTable.Members ? totalTable.Members : 0, Referrals: totalTable.Referrals ? totalTable.Referrals : 0, TotalRaised: totalTable.TotalRaised ? totalTable.TotalRaised : 0 }];
    const schoolName = params.school ? params.school : data.School;

    const headerKeyMap = {
        Tier: 'Tier',
        'User@': 'CAUser',
        RE: 'REReferrals',
        CA: 'CAReferrals',
        Deals: 'Deals',
        Raised: 'TotalRasied',
        Referrals: 'CaReSum'
    }

    return (
        <>
            <SchoolNavbar data={data} params={params} mainClass='school-detail' />
            <div className="school-main">
            <div className="nav w-100 justify-content-center flex-row gap-2 main-body" style={{ display: 'block', paddingTop: '5px' }}>
                    <div className="d-flex flex-column align-items-center" >
                        <span className="text-center reftxt d-flex flex-column">
                            <Link to={schoolName ? `/${schoolName}/referral` : ''} state={{ data, cauname: params.cauname }}>
                                Start Fundraising for NIL {schoolName === 'FloridaState' ? 'and Seminole Boosters' : ''}
                            </Link>
                            {data.caPath ? <CopyLink link={`${window.location.origin}/${data.caPath}`}>Anonymous Referral Ambassador link</CopyLink> : ''}
                            {data.caPath2 ? <CopyLink link={`${window.location.origin}/${data.caPath2}`}>Referral Ambassador Link</CopyLink> : ''}
                        </span>
                    </div>
                </div>
                <FullScreenBubbleLoader show={showFullScreenSpinner} />

                <div className="school-detail container px-0 main-body">
                    {/* {fullSchoolName === 'Florida State University' ?
                    <div className="logo d-flex justify-content-center">
                        <img src={FloridaState} alt="..." height={100} width={100} />
                    </div> : ''
                } */}
                    <>

                        <div className="text-center" style={{paddingBottom: '5px' }}>{data.School === "FloridaState" ? "Florida State" : data.School} Referral Network
                        </div>
                        <BsTable columns={tcolumns} data={ttableData} border={false} bgNone={true} align='center' fixed={true} mb0={false} mb2={true} />
                        <div className="text-center mb-2"><span className="border p-1">LEADERBORAD</span></div>
                        <BsTable columns={columns} data={tableData} border={false} defaultRow={true} accordion={{
                            key: 'CAReferrals', data: data ? data.CaReferralsInfo : [], school: data.FullSchoolName
                        }} secondHeader={['', '', 'RE', 'CA', '', '']} align="center" sorting={true} onSort={handleApiSort} headerKeyMap={headerKeyMap} fixed={true}
                            pagination={{ totalPages: totalPages, currentPage: currentPage, itemsPerPage: itemsPerPage, onChange: setItem, onClick: handlePageChange }} skip={['CaReSum','id']} />
                        {/* <Pagination totalPages={totalPages} currentPage={currentPage} itemsPerPage={itemsPerPage} onChange={setItem} onClick={handlePageChange} /> */}
                    </>
                    {/* <div className="card-section text-center mt-3">
                    <h1>Recruiting News</h1><hr />
                </div>
                <CardGroup key={'card_school'} cardCols={cards} />
                <div className="card-section text-center mt-3">
                    <h1>Portal News</h1><hr />
                </div>
                <CardGroup key={'card_school2'} cardCols={cards} /> */}
                </div>
            </div>
            <Footer />
        </>
    )
}
