import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { sData } from '../../helpers/sData';
import { isLogedIn } from '../../helpers/loginCheck';

function MobileOffcanvasNavbar(props) {
  const closeBtn = useRef();
  function setActiveClass(e) {
    document.querySelectorAll('.headerLinks')
      .forEach(element => {
        element.classList.remove('active');
      })
    e.currentTarget.classList.add('active');
  }

  const handleToggle = () => {
    setTimeout(() => {
      document.querySelectorAll('.offcanvas-backdrop.fade.show')
        .forEach(element => element && element.addEventListener('click', () => {
          closeBtn.current.click();
        }));
    }, 100);
  }

  const path = props.path ?? '';

  const location = useLocation();
  const [currentSchool, setCurrentSchool] = useState('')

  useEffect(() => {
    if (location.pathname && location.pathname.includes('/u/')) {
      sData('ParamsSchool', '');
    }
    let school = sData('ParamsSchool');
    if (!school)
      school = props && props.params && props.params.school ? props.params.school : '';
    school = school ? `/${school}` : '';
    setCurrentSchool(school ? school : sData('currentSchool'));
  }, [location.pathname, props])
  return (
    <>
      <nav className={`${props.mainClass} main-navbar navbar-expand-lg navbar bg-body-tertiary px-2`} style={{ padding: '0', margin: '0' }}>
        <div className="container-fluid">
          <ul className="navbar-nav mb-2 mb-lg-0 float-end d-flex flex-row gap-3 nav-underline">
            <li className="nav-item">
              <Link className={`headerLinks nav-link text-sm` + (path === '/' ? ' active' : '')} onClick={(e) => { setActiveClass(e) }} aria-current="page" to="/" style={{ pointerEvents: path === '/' ? 'none' : '' }}>Home</Link>
            </li>
          </ul>

          {/* {currentSchool === 'FloridaState' || currentSchool === '/FloridaState' ?
            <ul className="navbar-nav mb-2 mb-lg-0 float-end d-flex flex-row gap-3 nav-underline ds-center-nav-link">
              <li className="nav-item">
                <Link className={`headerLinks nav-link text-sm fw-bold` + (path.includes('/nole_coin') ? ' active' : '')} onClick={(e) => { setActiveClass(e) }} to={'/nole_coin'} state={{ data: props.data, cauname: props.params ? props.params.cauname : '' }} style={{ pointerEvents: path.includes('/nole_coin') ? 'none' : '' }}>$NOLE Token for NIL</Link>
              </li>
            </ul> : ''} */}

          <button className="navbar-toggler mobile-offcanvas-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" onClick={handleToggle}>
            <span className="navbar-toggler-icon"></span>
          </button>
          {/* Desktop nav */}
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {/* Add your navbar content here, which will be hidden on mobile */}
            <NavLinks {...props} />
          </div>
        </div>
      </nav>
      {/* Phone nav */}
      <div className="offcanvas offcanvas-start main-navbar" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
        <div className="offcanvas-header">
          <h6 className="offcanvas-title" id="offcanvasNavbarLabel">Navigation</h6>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ref={closeBtn}></button>
        </div>
        <div className="offcanvas-body">
          {/* Add your mobile navbar content here */}
          <NavLinks {...props} closeBtn={closeBtn.current} ulMb1Rem={true} />
        </div>
      </div>
    </>
  );
}



function NavLinks(props) {

  const handleClose = () => {
    if (props.closeBtn) {
      props.closeBtn.click();
    }
  };

  function setActiveClass(e) {
    handleClose();
    document.querySelectorAll('.headerLinks')
      .forEach(element => {
        element.classList.remove('active');
      })
    e.currentTarget.classList.add('active');
  }

  const location = useLocation();
  const path = props.path ?? '';
  const [paramsSchool, setParamsSchool] = useState('')

  useEffect(() => {
    if (location.pathname && location.pathname.includes('/u/')) {
      sData('ParamsSchool', '');
    }
    let school = sData('ParamsSchool');
    if (!school)
      school = props && props.params && props.params.school ? props.params.school : '';
    school = school ? `/${school}` : '';
    setParamsSchool(school);
  }, [location.pathname, props])
  return (
    <>
      <ul className={`navbar-nav me-auto ${props.ulMb1Rem ? 'mb-3' : 'mb-2'} mb-lg-0 nav-underline`}>
        {isLogedIn() ?
          <>
            <li className="nav-item">
              <Link className={"headerLinks nav-link text-sm" + (path === '/u/school_dashboard' ? ' active' : '')} onClick={(e) => { setActiveClass(e) }} aria-current="page" to="/u/school_dashboard" style={{ pointerEvents: path.includes('/u/school_dashboard') ? 'none' : '' }}>Leaderboard</Link>
            </li>
            <li className="nav-item">
              <Link className={"headerLinks nav-link text-sm" + (path === '/u/leaderboard' ? ' active' : '')} onClick={(e) => { setActiveClass(e) }} aria-current="page" to="/u/leaderboard" style={{ pointerEvents: path.includes('/u/leaderboard') ? 'none' : '' }}>My Dashboard</Link>
            </li>
          </>
          : ''}

      </ul>

      <ul className="navbar-nav ms-auto mb-2 mb-lg-0 nav-underline">
        <li className="nav-item">
          <Link className={`headerLinks nav-link text-sm` + (path.includes('/referral') ? ' active' : '')} onClick={(e) => { setActiveClass(e) }} to={`${paramsSchool}/referral`} style={{ pointerEvents: path.includes('/referral') ? 'none' : '' }} state={{ data: props.data, cauname: props.params ? props.params.cauname : '' }}>{isLogedIn() ? 'Profile' : 'Referral'}</Link>
        </li>
        <li className="nav-item">
          <Link className={`headerLinks nav-link text-sm` + (path.includes('/how_it_works') ? ' active' : '')} onClick={(e) => { setActiveClass(e) }} to={`${paramsSchool}/how_it_works`} state={{ data: props.data }} style={{ pointerEvents: path.includes('/how_it_works') ? 'none' : '' }}>How It Works</Link>
        </li>
        {isLogedIn() ?
          <li className="nav-item">
            <Link className="nav-link" onClick={handleClose} to='/logout'>Logout</Link>
          </li>
          :
          <li className="nav-item">
            <Link className="nav-link" onClick={handleClose} to='/login' style={{ pointerEvents: path.includes('/login') ? 'none' : '' }}>Login</Link>
          </li>
        }
      </ul>
    </>
  )
}

export default MobileOffcanvasNavbar;

