export async function validator(value, validation) {
    if (!validation || typeof validation !== 'string')
        return {
            isValid: true,
            errros: '',
            newValue: undefined
        };

    validation = validation.split('|');
    let isValid = true;
    let errros = '';
    let newValue;
    for (let i = 0; i < validation.length; i++) {
        const validationType = validation[i];
        if (!validationType) continue;
        if (validationType === 'required') {
            if (!value || (Number.isNaN(value) && !value.trim())) {
                isValid = false;
                errros = 'This field is required';
                break;
            }
        }
        if (validationType === 'email') {
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
                isValid = false;
                break;
            }
        }
        if (validationType === 'username') {
            if (!/^(?![0-9]+$)[a-zA-Z0-9._]+$/.test(value) || value.startsWith('.') || value.endsWith('.')) {
                isValid = false;
                break;
            }
        }
        if (value && validationType && validationType.startsWith('fn:')) {
            let validationTypeB = validationType.replace('fn:', '');
            validationTypeB = validationTypeB.split(':');
            let path = validationTypeB[0];
            path = path.split('/');
            const fn = path.pop();
            console.log('fn',fn);
            let args = validationTypeB[1];
            args = args.split(',');
            args = args.map(arg => {
                if (arg === '{value}') {
                    return value;
                }
                return arg;
            })
            try {
                path = path.join('/');
                if(fn === 'checkUsernameEmail'){
                    const mod = await import("../../api/rankcollegeApi");
                    console.log('mod',mod[fn]);
                    const res = await mod[fn](...args);
                    isValid = res.success;
                    errros = res.msg
                }else{
                    const mod = await import('./validationFn');
                    const res = await mod[`${fn}Fn`](...args);
                    isValid = res.success;
                    errros = res.msg
                }
            } catch (err) {
                console.warn(err);
            }
        }
        if (validationType && validationType.startsWith('max:')) {
            const max = Number(validationType.split(':')[1]);
            if (value && value.length > max) {
                isValid = false;
                errros = `The value entered exceeds the maximum allowed limit of ${max}`;
                break;
            }
        }
        if (validationType.startsWith('maxEntries:')) {
            const max = Number(validationType.split(':')[1]);
            if (value && value.length > max) {
                isValid = false;
                newValue = value.substring(0, max);
                errros = `The entered value cannot exceed the maximum allowed limit of ${max}`;
                break;
            }
        }
        if (validationType==='numeric') {
            if (value && isNaN(Number(value))) {
                isValid = false;
                errros = `Only numeric values are allowed`;
                break;
            }
        }
    }
    return {
        isValid: isValid,
        errros: errros,
        newValue: newValue
    }
}