import { createContext, useContext, useRef, useState } from "react";

const CountyContext = createContext();

export const CountyProvider = ({ children }) => {
    const [lastCounty, setLastCounty] = useState(null);
    const [lastCountyState, setLastCountyState] = useState(null);

    return (
        <CountyContext.Provider 
        value={{lastCounty,setLastCounty,lastCountyState,setLastCountyState}}
        >
            {children}
        </CountyContext.Provider>
    );
};

export const useCounty = () => {
    return useContext(CountyContext);
};
