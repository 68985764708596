export function formateErrors(errors){
    let fErrors = '';
    if(typeof errors === 'object' && errors){
        for (const key in errors) {
            if (Object.hasOwnProperty.call(errors, key)) {
                const errArr = errors[key];
                if(Array.isArray(errArr) && errArr.length){
                    fErrors += `${key} :\n`;
                    errArr.forEach(err => {
                        fErrors += `${err}\n`;
                    });
                }else if(errArr){
                    fErrors += `${key} :\n${errArr}`;
                }else{
                    fErrors = errArr;
                }
            }
        }
    }else{
        fErrors = errors;
    }
    return fErrors;
}