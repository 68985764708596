import { get as bget } from "./api";

const API_PATH = 'commonApi/';
const STATE_API = `${API_PATH}allenablestates`;
const COUNTY_API = `${API_PATH}allcounties`;
const MSA_API = `${API_PATH}allmsa`;
const ZIP_API = `${API_PATH}allzipbycounty`;
const ZIP_BY_MSA_API = `${API_PATH}allzipbymsa`;
const CHECK_ZIPCODE = `${API_PATH}checkZipcode`;

export function getStates() {
    return new Promise((resolve, reject) => {
        bget(STATE_API)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function getMsas(state, state_type) {
    return new Promise((resolve, reject) => {
        if (!state || !state_type) {
            resolve(true);
            return;
        }
        bget(MSA_API, `state=${state}&state_type=${state_type}`)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                console.warn(err);
                reject(err);
            });
    });
}

export async function getZipByMsa(state, state_type, msa) {
    if (!msa) return;
    try {
        const res = await bget(ZIP_BY_MSA_API, `state=${state}&state_type=${state_type}&msa=${msa}`);
        return res;
    } catch (error) {
        return;
    }
}

export function getZipByCounty(state, state_type, county) {
    return new Promise((resolve, reject) => {
        if (!county) {
            resolve(true);
            return;
        }
        bget(ZIP_API, `state=${state}&state_type=${state_type}&county=${county}`)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function getCounties(msaName = '', stateAbb = '') {
    return new Promise((resolve, reject) => {
        if (!msaName && !stateAbb) {
            resolve(true);
            return;
        }
        bget(COUNTY_API, `msaName=${msaName}&stateAbb=${stateAbb}`)
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export const checkZipcode = async (zipcode) => {
    try {
        return await bget(CHECK_ZIPCODE, `zipcode=${zipcode}`);
    } catch (error) {
        console.warn(error);
    }
}
