import React, { useState, useEffect, useRef } from "react";
import { getCounties, getZipByCounty } from "../../api/commonApi";
import { getAgentInfo, getRankData, getReferralLead, saveReferralForm } from "../../api/rankcollegeApi";
import FormInput from "../../helpers/form";
import { AddressFormUser } from "./components/addressForm";
import CounterButton from "./components/counter";
import { getFormData } from "../../helpers/formData";
import { json, useLocation, useNavigate } from "react-router-dom";
import { isLogedIn } from "../../helpers/loginCheck";
import { setColorByFullSchool } from "../../helpers/setColors";
import { sData } from "../../helpers/sData";
import { formateErrors } from "../../helpers/showErrors";
import { multiSelectValue } from "../../helpers/val";
import { useStateData } from "../../served-components/field/state";
import { useMsa } from "../../served-components/field/msa";

const allPropertyTypeOptions = {
    '': 'Select',
    RESI: 'RESI (Residential for Sale)',
    RLSE: 'RLSE (Residential Lease)',
    RINC: 'RINC (Residential Income)',
    COMM: 'COMM (Commercial)',
    COML: 'COML (Commercial Lease)',
    BZOP: 'BZOP (Business Oppurtunity)',
    LAND: 'LAND (Vacant/Agricultural)'
}


export default function Form() {
    const location = useLocation();
    let data = location.state;

    let cauname = data ? data.cauname : 0;
    if (!data || !data.data) {
        data = sData();
        cauname = data && data.cauname ? data.cauname : 0;
    } else {
        data = data.data;
    }

    const [yourFavoriteCollege, setYourFavoriteCollege] = useState(data.FullSchoolName ? data.FullSchoolName : '');
    const stateRef = useRef({});
    const [favCollege, setFavCollege] = useState([]);
    const [collectives, setCollectives] = useState([]);
    const [userReferralsDisplay, setUserReferralsDisplay] = useState('none');
    const [userDirectReferralsDisplay, setUserDirectReferralsDisplay] = useState('none');
    const [agentInfo, setAgentInfo] = useState(null);
    const [agentReferral, setAgentReferral] = useState(null);
    const [addressFormUserDisplay, setAddressFormUserDisplay] = useState('none');
    const [buyerAddressFormUserDisplay, setBuyerAddressFormUserDisplay] = useState('none');
    const [refinanceAddressFormDisplay, setRefinanceAddressFormDisplay] = useState('none');
    const navigate = useNavigate();
    // refs
    const UsernameRef = useRef('');
    const ReferringFirstNameRef = useRef('');
    const ReferringLastNameRef = useRef('');
    const ReferringEmailRef = useRef('');
    const ReferringPhoneRef = useRef('');
    const ReferringMsaRef = useRef('');
    const ReferringCountyRef = useRef('');
    // const ReferringZipCodeRef = useRef('');
    const YourRealEstateNeedsRef = useRef('');
    const ReferringPropertyTypeRef = useRef('');
    const [propertyTypeOptions, setPropertyTypeOptions] = useState(allPropertyTypeOptions);
    const CollectiveRef = useRef('');
    const NoteForAgent = useRef('');


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        getRankData('favCollege').then(res => {
            setFavCollege(res.data);
        })
        if (data.Collective) {
            setCollectives(data.Collective.split('|').reverse())
        }
        if (data.FullSchoolName) {
            setYourFavoriteCollege(data.FullSchoolName);
            if (!data.Collective)
                getCollectives(null, data.FullSchoolName);
            else if (data.FullSchoolName === 'Florida State University') {
                setTimeout(() => {
                    if (CollectiveRef.current)
                        CollectiveRef.current.value = "The Battle's End";
                }, 100);
            }
        }

        isLogedIn() && getAgentInfo().then(res => {
            if (!res) return;
            const info = res.data;
            if (res.success === true) {
                // refs
                if (UsernameRef.current)
                    UsernameRef.current.value = info.username;
                if (ReferringFirstNameRef.current)
                    ReferringFirstNameRef.current.value = info.first_name;
                if (ReferringLastNameRef.current)
                    ReferringLastNameRef.current.value = info.last_name;
                if (ReferringEmailRef.current)
                    ReferringEmailRef.current.value = info.email;
                if (ReferringPhoneRef.current)
                    ReferringPhoneRef.current.value = info.phone;
                // if (ReferringCountyRef.current)
                //     ReferringCountyRef.current.value = info.county;
                // if (ReferringZipCodeRef.current)
                //     ReferringZipCodeRef.current.value = info.zip_codes;
                if (NoteForAgent.current)
                    NoteForAgent.current.value = info.note_for_agent;
                if (YourRealEstateNeedsRef.current) {
                    YourRealEstateNeedsRef.current.value = leadType(info.real_estate_needs);
                    handleRealEstateChange(null, info.real_estate_needs);
                }
                setYourFavoriteCollege(info.full_school_name);
                getCollectives(null, info.full_school_name);
                if (ReferringPropertyTypeRef.current)
                    ReferringPropertyTypeRef.current.value = info.specialization_requested;
                setTimeout(() => {
                    if (CollectiveRef.current && info.collective)
                        CollectiveRef.current.value = info.collective;
                }, 1000);
                setAgentInfo(info);
            }
        });
        setColorByFullSchool(data ? data.FullSchoolName : '');
        handleRealEstateChange();
    }, []);

    const leadType = (RealEstateNeeds) => {
        if (RealEstateNeeds === 'Seller') {
            return 'selling';
        } else if (RealEstateNeeds === 'Buyer') {
            return 'buying';
        } else if (RealEstateNeeds === 'refinancing') {
            return 'Refinancing';
        }
        return RealEstateNeeds;
    }

    const getCollectives = (e, v) => {
        getRankData('collectives', v).then(res => {
            setCollectives(res.data);
            setTimeout(() => {
                if (!isLogedIn() && v === 'Florida State University' && CollectiveRef.current)
                    CollectiveRef.current.value = "The Battle's End";
            }, 100);
        })
    }
    const handleRealEstateChange = (e, value) => {
        if (!value) {
            value = e && e.currentTarget && e.currentTarget.value ? e.currentTarget.value : '';
        }
        if (value !== 'not_at_this_time') {
            ReferringPropertyTypeRef.current.parentNode.style.display = '';
        } else {
            ReferringPropertyTypeRef.current.parentNode.style.display = 'none';
        }
        if (['Tenant', 'Landlord'].includes(value)) {
            if (ReferringPropertyTypeRef.current) {
                setPropertyTypeOptions(Object.fromEntries(
                    Object.entries(allPropertyTypeOptions).filter(([key]) => ['RLSE', 'COML'].includes(key))
                ));
                ReferringPropertyTypeRef.current.value = 'RLSE';
            }
        } else if (['selling', 'buying'].includes(value)) {
            setPropertyTypeOptions(Object.fromEntries(
                Object.entries(allPropertyTypeOptions).filter(([key]) => !['RLSE', 'COML'].includes(key))
            ));
            if (ReferringPropertyTypeRef.current)
                ReferringPropertyTypeRef.current.value = 'RESI';
        } else {
            setPropertyTypeOptions(allPropertyTypeOptions);
            if (ReferringPropertyTypeRef.current)
                ReferringPropertyTypeRef.current.value = '';
        }
        handleUserFormToggle('AddressFormUser', value);
    }

    const userForm = [
        { inputType: 'text', label: 'Username', id: 'Username', useref: UsernameRef, validation: 'required|username|max:12|fn:../../api/rankcollegeApi/checkUsernameEmail:username,{value}', validationOnChange: 'maxEntries:12' },
        [
            { inputType: 'text', label: 'Your First Name', id: 'ReferringFirstName', useref: ReferringFirstNameRef },
            { inputType: 'text', label: 'Your Last Name', id: 'ReferringLastName', useref: ReferringLastNameRef }
        ],
        { inputType: 'email', label: 'Your Email', id: 'ReferringEmail', useref: ReferringEmailRef, validation: 'required|email|fn:../../api/rankcollegeApi/checkUsernameEmail:email,{value}' },
        { inputType: 'text', label: 'Your Phone', id: 'ReferringPhone', useref: ReferringPhoneRef, formateType: 'phone' },
        { inputType: 'select', label: "Your State of Current Residence", id: 'ReferringState', useref: stateRef, changeEvent: setMsas },
        { inputType: 'select', label: " Your Sphere of Influence (City you Live)", id: 'ReferringMsa', changeEvent: setCounties, useref: ReferringMsaRef },
        '{{<hr>}}',
        { inputType: 'select', label: "Your Real Estate Needs", id: 'YourRealEstateNeeds', options: { not_at_this_time: 'Not At This Time', buying: 'Buying', selling: 'Selling', Refinancing: 'Refinancing', Tenant: 'Tenant', Landlord: 'Landlord' }, useref: YourRealEstateNeedsRef, changeEvent: handleRealEstateChange },
        { inputType: 'select', label: 'Your Property Type', id: 'ReferringPropertyType', options: propertyTypeOptions, useref: ReferringPropertyTypeRef },
        { label: "Your County", id: 'ReferringCounty', useref: ReferringCountyRef, multiple: true, /*changeEvent: setZipcode*/ },
        // { inputType: 'textarea', label: "Your Zipcode(s)", id: 'ReferringZipCode', useref: ReferringZipCodeRef },
        { inputType: 'select', label: "Your Favorite College", id: 'YourFavoriteCollege', options: favCollege, changeEvent: getCollectives, suggestionBox: true, value: yourFavoriteCollege },
        { label: "Collective", id: 'Collective', options: collectives, useref: CollectiveRef },
    ];

    const userFormB = [
        
        { inputType: 'textarea', label: "Note for Agent", id: 'NoteForAgent', useref: NoteForAgent },
        // { inputType: "select", label: "Greek Life Involvement", id: 'GreekLifeInvolvement', options: { Y: 'Yes', N: 'No' } },
        // { label: "If yes, choose chapter", id: 'Chapter', options: { alpha: 'Alpha', beta: 'Beta', gamma: 'Gamma' } },
    ];
    const button = [
        { inputType: 'button', buttonType: 'button', btnClass: 'primary', buttonName: 'Submit', id: 'referralFormSubmitBtn', jsEvent: sendReferralForm, fullWidth: true }
    ];

    // const allStates = useStateData();
    const { allStates, setDefaultState } = useStateData();
    const getMsa = useMsa();

    useEffect(() => {
        // getStates().then((res) => {
        if (!allStates) return;
        setStates(allStates);
        stateRef.current.value = data.State ? data.State : 'FL';
        if (agentInfo) {
            stateRef.current.value = agentInfo.state;
            setMsas(agentInfo.state).then(() => {
                if (ReferringMsaRef.current) {
                    ReferringMsaRef.current.value = agentInfo.msa;
                }
                setCounties(agentInfo.msa, false).then(() => {
                    if (ReferringCountyRef.current)
                        multiSelectValue(ReferringCountyRef.current, agentInfo.county);
                    // if (ReferringZipCodeRef.current)
                    //     ReferringZipCodeRef.current.value = agentInfo.zip_codes;
                });
            });
        } else if (!isLogedIn()) {
            setMsas(stateRef.current.value);
        }
        setTimeout(() => {
            setDefaultState(stateRef.current ? stateRef.current.value : 'FL');
        }, 100);
        // }).catch(err => {
        //     console.warn(err);
        // });
    }, [agentInfo, allStates]);

    async function setMsas(state_abb) {
        console.log("inside member form call");
        if (typeof state_abb === 'object') {
            state_abb = state_abb.currentTarget ? state_abb.currentTarget.value : '';
        }
        // let res = msaData.current[state_abb];
        // if (!msaData.current[state_abb]) {
        //     // Fetch MSA data for the selected state
        //     res = await getMsas(state_abb, 'abb')
        //     // setMSAData({ ...msaData, [state_abb]: res });
        //     msaData.current = { ...msaData.current, [state_abb]: res }
        // }
        const res = await getMsa(state_abb)
        const element = document.getElementById('ReferringMsa');
        if (!element) return;

        element.innerHTML = '';
        element.innerHTML += "<option value=''>Select</option>";
        if (res.msa) {
            for (const key in res.msa) {
                if (Object.hasOwnProperty.call(res.msa, key)) {
                    const msa = res.msa[key];
                    element.innerHTML += `<option value="${msa}">${msa}</option>`
                }
            }
        }
    }

    function sendReferralForm(e) {
        const btn = e.currentTarget;
        // makeBtnProssess(btn);
        const FD = getFormData('userForm', 'rankData', 'referralForm', 'form_user', 'form_user_SpsForm', 'form_referral', 'userFormB');
        mergeInnerFormsData(FD, 'DynamicReferralForms', 'referralData');
        FD.append('cauname', cauname);
        const elementId = FD.get('DATA-VALIDATION-FAILED-ELEMENT-ID');
        if (elementId) {
            const element = document.getElementById(elementId);
            // alert(element.dataset.validationFailedMsg);
            element.focus();
            return;
        }
        makeBtnProssess(btn);
        saveReferralForm(FD).then((res) => {
            alert(formateErrors(res.msg));
            makeBtnProssess(btn, true);
            if (res.success) {
                if (isLogedIn()) {
                    navigate('/u/leaderboard');
                } else {
                    if (res && res.submitLogin && typeof res.submitLogin === 'object'
                        && res.submitLogin.accountCreated && res.submitLogin.encLoginId
                        && window.confirm('New account created, Do you want to create password now?')
                    ) {
                        navigate(`/create_password/${res.submitLogin.encLoginId}`);
                    } else {
                        navigate('/');
                    }
                }
            }
        }).catch(err => { console.warn(err); makeBtnProssess(btn, true); });
    }
    const [userFormDisplay, setUserFormDisplay] = useState(isLogedIn() ? 'none' : '');
    const handleUserFormToggle = (flag, val) => {
        if (flag === 'AddressFormUser') {
            // -----------------------------------
            if (['Landlord', 'selling', 'Refinancing'].includes(val)) {
                setAddressFormUserDisplay('');
                if (val === 'Refinancing') {
                    setRefinanceAddressFormDisplay('');
                } else {
                    setRefinanceAddressFormDisplay('none');
                }
            } else {
                setAddressFormUserDisplay('none');
                setRefinanceAddressFormDisplay('none')
            }
            // -----------------------------------
            if (['buying'].includes(val)) {
                setBuyerAddressFormUserDisplay('');
            } else {
                setBuyerAddressFormUserDisplay('none')
            }
            // -----------------------------------
        }
        else {
            if (userFormDisplay) {
                setUserFormDisplay('');
            } else {
                setUserFormDisplay('none');
            }
        }

    }
    const [isDirectReferral, setIsDirectReferral] = useState(false);
    const handleUserReferralForm = async (e, isDirectReferral = false) => {

        const element = e && e.currentTarget;
        const id = element && element.dataset && element.dataset.id;
        if (document.getElementById(`referralForm_${id}`)) {
            const ele = document.getElementById(`referralForm_${id}`);
            if (ele.style.display === 'none') {
                ele.style.display = '';
            } else {
                ele.style.display = 'none';
            }
        } else {
            element.style.pointerEvents = 'none';
            const res = await getReferralLead(id);
            element.style.pointerEvents = '';
            setAgentReferral(res.data);
            setIsDirectReferral(isDirectReferral);
        }
    }
    return (
        <>
            <div className="referral-forms-title w-100 p-2 main-body">
                <h1 className="text-center w-100 defaultTextColor">Real Estate Referral Program</h1>
            </div>
            <div className="referral-forms container max-w-md mx-auto bg-success shadow p-3 mb-5 bg-body rounded main-body">
                {isLogedIn() && <div className="cursor-pointer underline-hover mb-3 fw-bold" onClick={handleUserFormToggle}>User Profile</div>}
                <div style={{ display: userFormDisplay }}>
                    <FormInput inputs={userForm} forms={{ id: 'userForm', method: 'Post', name: 'userForm' }} />
                    <AddressFormUser user={{ style: { display: addressFormUserDisplay }, sps: agentInfo && agentInfo.sps ? agentInfo.sps : {} }} buyer={{ style: { display: buyerAddressFormUserDisplay } }} refinance={{ style: { display: refinanceAddressFormDisplay } }} />
                    <FormInput inputs={userFormB} forms={{ id: 'userFormB', method: 'Post', name: 'userFormB' }} />
                </div>
                {/* <div className="cursor-pointer underline-hover mb-3" onClick={() => {
                    if (userReferralsDisplay === 'none') setUserReferralsDisplay('');
                    else setUserReferralsDisplay('none');
                }} >{(agentInfo && Array.isArray(agentInfo.referrals)) ? `${agentInfo.referrals.length} Referrals` : ''}</div> */}
                {/* {<div id="created-referrals" style={{ display: userReferralsDisplay }}>
                    {agentInfo && Array.isArray(agentInfo.referrals) && agentInfo.referrals.map((referral) => {
                        return <div key={`_${referral.id}`} className="cursor-pointer underline-hover mb-3" onClick={handleUserReferralForm} data-id={referral.id}>{referral.contact_name}</div>
                    })}
                </div>} */}
                <CounterButton referralFormData={agentReferral} userReferralCount={agentInfo && Array.isArray(agentInfo.referrals) ? agentInfo.referrals.length : 0} userReferralsDisplay={userReferralsDisplay} setUserReferralsDisplay={setUserReferralsDisplay} referralList={<div id="created-referrals" style={{ display: userReferralsDisplay }}>
                    {agentInfo && Array.isArray(agentInfo.referrals) && agentInfo.referrals.map((referral) => {
                        return <div key={`_${referral.id}`} className="cursor-pointer underline-hover mb-3" onClick={handleUserReferralForm} data-id={referral.id}>{referral.contact_name}</div>
                    })}
                </div>}
                    isDirectReferral={isDirectReferral}
                    userDirectReferralCount={agentInfo && Array.isArray(agentInfo.directReferral) ? agentInfo.directReferral.length : 0}
                    userDirectReferralsDisplay={userDirectReferralsDisplay}
                    setUserDirectReferralsDisplay={setUserDirectReferralsDisplay}
                    directReferralList={
                        <div id="created-referrals" style={{ display: userDirectReferralsDisplay }}>
                            {agentInfo && Array.isArray(agentInfo.directReferral) && agentInfo.directReferral.map((referral) => {
                                return <div key={`_${referral.id}`} className="cursor-pointer underline-hover mb-3" onClick={e => handleUserReferralForm(e, true)} data-id={referral.id}>{referral.contact_name}</div>
                            })}
                        </div>
                    }
                />
                <FormInput inputs={button} />
            </div>
        </>
    );
}

function setStates(res) {
    ['ReferringState'].forEach(id => {
        const element = document.getElementById(id);
        if (!element) return;

        element.innerHTML = '';
        if (res.enabled) {
            for (const key in res.enabled) {
                if (Object.hasOwnProperty.call(res.enabled, key)) {
                    const state = res.enabled[key];
                    element.innerHTML += `<option value="${key}">${state}</option>`
                }
            }
        }

        if (res.stateWait) {
            for (const key in res.stateWait) {
                if (Object.hasOwnProperty.call(res.stateWait, key)) {
                    const state = res.stateWait[key];
                    element.innerHTML += `<option value="${key}">${state}</option>`
                }
            }
        }

        if (res.disabled) {
            for (const key in res.disabled) {
                if (Object.hasOwnProperty.call(res.disabled, key)) {
                    const state = res.disabled[key];
                    element.innerHTML += `<option disabled value="${key}">${state}</option>`
                }
            }
        }
    })
}



function setCounties(msaName, fillAutomatic = true) {
    return new Promise((resolve, reject) => {
        if (typeof msaName === 'object') {
            msaName = msaName.currentTarget.value;
        }
        getCounties(msaName).then((res => {
            const element = document.getElementById('ReferringCounty');
            if (!element) return;
            element.innerHTML = '';
            if (Array.isArray(res.counties)) {
                res.counties.forEach(county => {
                    element.innerHTML += `<option value="${county}" ${fillAutomatic ? 'selected' : ''}>${county}</option>`
                })

                if (fillAutomatic && res.counties && res.counties.length) {
                    setZipcode(res.counties);
                }
                resolve(true);
            }
        }));
    });
}

function setZipcode(county) {
    return ''; // bcz no need
    if (!county) return;
    let state = document.getElementById('ReferringState').value;
    if (typeof county === 'object') {
        if (Array.isArray(county)) {
            county = county.join(',');
        } else {
            county = multiSelectValue(county.currentTarget);
        }
    }
    if (!county) return;
    getZipByCounty(state, 'abb', county).then((res => {
        const element = document.getElementById('ReferringZipCode');
        if (!element) return;
        element.value = res.zipcode;
    }));
}

function mergeFormData(FD, form, inOneKeyWithJsonStrinfy = '') {
    const DATA = {};
    if (!document.getElementById(form)) { return FD; }
    document.getElementById(form)
        .querySelectorAll('input , select, textarea')
        .forEach(element => {
            if (inOneKeyWithJsonStrinfy) {
                DATA[element.id] = element.value;
            } else {
                FD.append(element.id, element.value);
            }
        })
    if (inOneKeyWithJsonStrinfy) {
        FD.append(inOneKeyWithJsonStrinfy, JSON.stringify(DATA));
    }
    return FD;
}

function mergeInnerFormsData(FD, form, inOneKeyWithJsonStrinfy = '') {
    let DATA = [];
    let leadId = 0;
    if (!document.getElementById(form)) { return FD; }
    document.getElementById(form)
        .querySelectorAll('form')
        .forEach((f) => {
            if (f.parentElement && f.parentElement.id && typeof f.parentElement.id === 'string' && f.parentElement.id.startsWith('referralForm_')) {
                leadId = f.parentElement.id.replace('referralForm_', '');
            }
            const innerFormData = {};
            f.querySelectorAll('input , select, textarea')
                .forEach(element => {
                    if (element.getAttribute('data-validation-failed-msg')) {
                        FD.append('DATA-VALIDATION-FAILED-ELEMENT-ID', element.id);
                    }
                    if (inOneKeyWithJsonStrinfy) {
                        if (element.hasAttribute('multiple')) {
                            innerFormData[element.id] = Array.from(element.selectedOptions)
                                .map(option => option.value).reduce((acc, element) => element ? [...acc, element] : acc, []).join(',');
                        } else if (element.type === 'checkbox') {
                            innerFormData[element.id] = element.checked ? 'Y' : 'N';
                        } else {
                            innerFormData[element.id] = element.value;
                        }
                    } else {
                        FD.append(element.id, element.value);
                    }
                })
            if (leadId)
                innerFormData['leadId'] = leadId;
            DATA.push(innerFormData);
        })
    DATA = arrayChunk(DATA, 3);
    if (inOneKeyWithJsonStrinfy) {
        FD.append(inOneKeyWithJsonStrinfy, JSON.stringify(DATA));
    }
    return FD;
}

function arrayChunk(array, chunkSize) {
    if (!Array.isArray(array) || array.length === 0 || !Number.isInteger(chunkSize) || chunkSize <= 0) {
        return array;
    }

    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
    }

    return result;
}

function makeBtnProssess(btn, done = false) {
    if (done) {
        btn.disabled = false;
    } else {
        btn.disabled = true;
    }
}
