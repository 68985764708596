export function getFormData(form, inOneKeyWithJsonStrinfy = '', ...formN) {
    const FD = new FormData();
    const DATA = {};

    [form, ...formN].forEach(f => {
        if (!f) return;
        if (!document.getElementById(f)) { return; }
        document.getElementById(f)
            .querySelectorAll('input , select, textarea')
            .forEach(element => {
                if (element.getAttribute('data-validation-failed-msg')) {
                    FD.append('DATA-VALIDATION-FAILED-ELEMENT-ID',element.id);
                }
                if (inOneKeyWithJsonStrinfy) {
                    if (element.hasAttribute('multiple')) {
                        DATA[element.id] = Array.from(element.selectedOptions)
                            .map(option => option.value).reduce((acc, element) => element ? [...acc, element] : acc, []).join(',');
                    } else if (element.type === 'checkbox') {
                        DATA[element.id] = element.checked ? 'Y' : 'N';
                    } else {
                        DATA[element.id] = element.value;
                    }
                } else {
                    FD.append(element.id, element.value);
                }
            })
    })
    if (inOneKeyWithJsonStrinfy) {
        FD.append(inOneKeyWithJsonStrinfy, JSON.stringify(DATA));
    }
    return FD;
}
