import { BsInput } from "../served-components/form/Input";
import { BsSelect } from "../served-components/form/select";
import { BsButton } from "../served-components/form/button";
import { BsTextarea } from "../served-components/form/textarea";
import { BsCheckBox } from "../served-components/form/checkbox";

export default function FormInput({ inputs, forms = null }) {
    let inputType = 'text';
    function formSubmit(e) {
        e.preventDefault();
        if (forms && forms.onSubmit && typeof forms.onSubmit === 'function') {
            forms.onSubmit(e);
        }
    }
    return (
        <>
            <form id={forms ? forms.id : ''} name={forms ? forms.name : ''} method={forms ? forms.method : ''} style={forms ? forms.style : {}} onSubmit={(e) => { formSubmit(e) }}>
                {inputs.map((obj, i) => {
                    if (obj && typeof obj === 'string') {
                        return <JsxTag key={`JsxTag${i}`} exp={obj} />
                    }
                    if (obj.inputType) { inputType = obj.inputType; }
                    if (inputType === 'select') {
                        // return <BsSelect key={'_' + obj.id} label={obj.label} id={obj.id} options={obj.options} changeEvent={obj.changeEvent} suggestionBox={obj.suggestionBox} />
                        return <BsSelect key={'_' + obj.id} {...obj} />
                    }
                    else if (inputType === 'button') {
                        return <BsButton key={'_' + obj.id} buttonType={obj.buttonType} btnClass={obj.btnClass} buttonName={obj.buttonName} id={obj.id} fullWidth={obj.fullWidth} jsEvent={obj.jsEvent} />
                    }
                    else if (inputType === 'textarea') {
                        // return <BsTextarea key={'_' + obj.id} label={obj.label} inputType={inputType} id={obj.id} onInput={obj.onInput} />
                        return <BsTextarea key={'_' + obj.id} {...obj} />
                    }
                    else if (inputType === 'checkbox') {
                        return <BsCheckBox key={'_' + obj.id} {...obj} />
                    }
                    else {
                        // return <BsInput key={'_'+obj.id} label={obj.label} inputType={inputType} id={obj.id} onInput={obj.onInput} className={obj.className}/>
                        if (Array.isArray(obj)) {
                            return <div key='bsInputRow' className="row">
                                {obj.map((innerObj) => {
                                    return <div className="col" key={'_' + innerObj.id}><BsInput {...innerObj} /></div>
                                })}
                            </div>
                        }
                        else {
                            return <BsInput key={'_' + obj.id} {...obj} />
                        }
                    }
                })}
                <hr />
            </form>
        </>
    )
}


function JsxTag({exp}) {
    if (exp === '{{<hr>}}') {
        return <hr />
    }
}
