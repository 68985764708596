// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBaseball, faBasketball, faFootball, faGolfBallTee, faHockeyPuck, faVolleyball, faWeightHanging } from "@fortawesome/free-solid-svg-icons";
// import { faFutbol } from "@fortawesome/free-regular-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { isLogedIn } from "../../helpers/loginCheck";
import { sData } from "../../helpers/sData";
import { useEffect, useState } from "react";
import MobileOffcanvasNavbar from "./MobileOffcanvasNavbar";
// import { width } from "@fortawesome/free-brands-svg-icons/fa42Group";
// import floridaLogo from "./logo-transparent-png.png";
// import logo from "./logo-transparent-png(1).png";
export default function Navbar(props) {
    const location = useLocation();
    const path = props.path;
    const [paramsSchool, setParamsSchool] = useState('')
    const [currentSchool, setCurrentSchool] = useState('')

    useEffect(() => {
        if (location.pathname && location.pathname.includes('/u/')) {
            sData('ParamsSchool', '');
        }
        let school = sData('ParamsSchool');
        console.log(school);
        if (!school)
            school = props && props.params && props.params.school ? props.params.school : '';
        school = school ? `/${school}` : '';
        setParamsSchool(school);
        setCurrentSchool(school ? school : sData('currentSchool'));
    }, [location.pathname, props])
    return (
        <>
            {/* <nav className={`${props.mainClass} main-navbar navbar bg-body-tertiary px-2`} style={{ padding: '0', margin: '0' }}>
                <ul className="nav nav-underline">
                    <li className="nav-item">
                        <Link className={`${isLogedIn() ? 'login-navbar' : ''} headerLinks nav-link text-sm` + (path === '/' ? ' active' : '')} onClick={(e) => { setActiveClass(e) }} aria-current="page" to="/">Home</Link>
                    </li>
                    {isLogedIn() ?
                        <>
                            <li className="nav-item">
                                <Link className={"login-navbar headerLinks nav-link text-sm" + (path === '/u/school_dashboard' ? ' active' : '')} onClick={(e) => { setActiveClass(e) }} aria-current="page" to="/u/school_dashboard">Leaderboard</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={"login-navbar headerLinks nav-link text-sm" + (path === '/u/leaderboard' ? ' active' : '')} onClick={(e) => { setActiveClass(e) }} aria-current="page" to="/u/leaderboard">My Dashboard</Link>
                            </li>
                        </>
                        : ''}
                </ul>
                <ul className="nav nav-underline justify-content-end">
                    {
                        props.referralLink ?
                            <li className="nav-item">
                                <Link className={`${isLogedIn() ? 'login-navbar' : ''} headerLinks nav-link text-sm` + (path.includes('/referral') ? ' active' : '')} onClick={(e) => { setActiveClass(e) }} to={`${paramsSchool}/referral`} style={{ pointerEvents: path.includes('/referral') ? 'none' : '' }} state={{ data: props.data, cauname: props.params ? props.params.cauname : '' }}>{isLogedIn() ? 'Profile' : 'Referral'}</Link>
                            </li> : ''
                    }
                    <li className="nav-item">
                        <Link className={`${isLogedIn() ? 'login-navbar' : ''} headerLinks nav-link text-sm` + (path.includes('/how_it_works') ? ' active' : '')} onClick={(e) => { setActiveClass(e) }} to={`${paramsSchool}/how_it_works`} state={{ data: props.data }}>How It Works</Link>
                    </li>
                    {isLogedIn() ?
                        <li className="nav-item">
                            <Link className="login-navbar nav-link text-sm" to='/logout'>Logout</Link>
                        </li>
                        :
                        <li className="nav-item">
                            <Link className="nav-link text-sm" to='/login'>Login</Link>
                        </li>
                    }
                </ul>
            </nav> */}
            <div className="main-header" style={{ height: '150px' }}>
                <MobileOffcanvasNavbar {...props} />
                {/* <Nav2 {...props} /> */}
                <nav className={`${props.mainClass} main-navbar navbar bg-body-tertiary px-2`} style={{ padding: '0', marginTop: '-.2rem' }}>
                    <ul className="nav w-100 justify-content-center">
                        <li className="nav-item">
                            <img src={currentSchool === 'FloridaState' || currentSchool === '/FloridaState' ? '/logo-transparent-png.png' 
                            : path === '/' ? '/logo-transparent-png(2).png' 
                            : currentSchool === '/Michigan' ? '/logo-transparent missouri-png.png' 
                            : currentSchool === '/Missouri' ? '/logo-transparent Michigan-png.png' 
                            :'/logo-transparent-png(1).png'} alt="Collective Logo" className="logo-img" style={{ marginTop: '-1rem' }} />
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    );
}

Navbar.defaultProps = {
    referralLink: true
}

function setActiveClass(e) {
    document.querySelectorAll('.headerLinks')
        .forEach(element => {
            element.classList.remove('active');
        })
    e.currentTarget.classList.add('active');
}


function Nav2(props) {
    const location = useLocation();
    const path = props.path;
    const [paramsSchool, setParamsSchool] = useState('')
    const [currentSchool, setCurrentSchool] = useState('')

    useEffect(() => {
        if (location.pathname && location.pathname.includes('/u/')) {
            sData('ParamsSchool', '');
        }
        let school = sData('ParamsSchool');
        if (!school)
            school = props && props.params && props.params.school ? props.params.school : '';
        school = school ? `/${school}` : '';
        setParamsSchool(school);
        setCurrentSchool(school ? school : sData('currentSchool'));
    }, [location.pathname, props])
    return (
        <nav className={`${props.mainClass} main-navbar navbar-expand-lg navbar bg-body-tertiary px-2`} style={{ padding: '0', margin: '0' }}>
            <div className="container-fluid">
                <ul className="navbar-nav mb-2 mb-lg-0 float-end d-flex flex-row gap-3 nav-underline">
                    <li className="nav-item">
                        <Link className={`headerLinks nav-link text-sm` + (path === '/' ? ' active' : '')} onClick={(e) => { setActiveClass(e) }} aria-current="page" to="/">Home</Link>
                    </li>
                </ul>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0 nav-underline">
                        {isLogedIn() ?
                            <>
                                <li className="nav-item">
                                    <Link className={"headerLinks nav-link text-sm" + (path === '/u/school_dashboard' ? ' active' : '')} onClick={(e) => { setActiveClass(e) }} aria-current="page" to="/u/school_dashboard">Leaderboard</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={"headerLinks nav-link text-sm" + (path === '/u/leaderboard' ? ' active' : '')} onClick={(e) => { setActiveClass(e) }} aria-current="page" to="/u/leaderboard">My Dashboard</Link>
                                </li>
                            </>
                            : ''}

                    </ul>
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0 nav-underline">
                        <li className="nav-item">
                            <Link className={`headerLinks nav-link text-sm` + (path.includes('/referral') ? ' active' : '')} onClick={(e) => { setActiveClass(e) }} to={`${paramsSchool}/referral`} style={{ pointerEvents: path.includes('/referral') ? 'none' : '' }} state={{ data: props.data, cauname: props.params ? props.params.cauname : '' }}>{isLogedIn() ? 'Profile' : 'Referral'}</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`headerLinks nav-link text-sm` + (path.includes('/how_it_works') ? ' active' : '')} onClick={(e) => { setActiveClass(e) }} to={`${paramsSchool}/how_it_works`} state={{ data: props.data }}>How It Works</Link>
                        </li>
                        {isLogedIn() ?
                            <li className="nav-item">
                                <Link className="nav-link" to='/logout'>Logout</Link>
                            </li>
                            :
                            <li className="nav-item">
                                <Link className="nav-link" to='/login'>Login</Link>
                            </li>
                        }
                    </ul>
                </div>

            </div>
        </nav>
    );
}
