import FormInput from "../../helpers/form";
import { ShieldLockFill, EnvelopeAt } from "../../served-components/icons";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../api/rankcollegeApi";
import { getFormData } from "../../helpers/formData";
import { useEffect } from "react";
import { isLogedIn, setLoginDetail } from "../../helpers/loginCheck";
import { formateErrors } from "../../helpers/showErrors";
import { setColorByFullSchool } from "../../helpers/setColors";
import { sData } from "../../helpers/sData";
import AddToHome from "./addToHome";

export default function Login() {
    const navigate = useNavigate();
    useEffect(() => {
        setColorByFullSchool(sData('FullSchoolName'));
        if (isLogedIn()) {
            navigate('/');
        }
    }, []);
    function submitLogin(e) {
        const FD = getFormData(e.currentTarget.id);
        login(FD).then(res => {
            if (res.success == true) {
                //alert(res.msg);
                setLoginDetail(res.data);
                navigate('/u/leaderboard');
            } else {
                const err = formateErrors(res.msg);
                alert(err);
            }
        });
    }

    return (
        <>
            <AddToHome />
            <div className="vh-80 d-flex align-items-center justify-content-center main-body">
                <div className="container p-2 d-flex flex-column justify-content-center align-items-center">
                    <img src='navlogo/logo-png.png' className="img-fluid mb-3" alt="logo" height={200} width={200} />
                    {/* <div className="mt-5"></div> */}
                    <div className="w-100 d-flex justify-content-center">
                        <FormInput inputs={[
                            { inputType: 'text', label: 'Email', id: 'email', inputGroup: <EnvelopeAt />, required: false },
                            { inputType: 'password', label: 'Password', id: 'password', inputGroup: <ShieldLockFill />, required: false },
                            { inputType: 'button', buttonName: 'Login', btnClass: 'primary rounded-pill', id: 'loginBtn', fullWidth: true },
                        ]} forms={{ onSubmit: submitLogin, id: 'loginForm' }} />
                    </div>
                    <span><Link to={'/referral'}>Sign Up</Link></span>
                    <span><Link to={'/forgot_password'}>Forgot Password</Link></span>
                </div>
            </div>
        </>
    );
}
