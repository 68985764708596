import React, { useEffect, useState } from 'react';
import ReferralForm from './referralForm';
import { BsButton } from '../../../served-components/form/button';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { uniqKey } from '../../../helpers/key';

const CounterButton = ({ referralFormData, userReferralCount, userReferralsDisplay, setUserReferralsDisplay, referralList, userDirectReferralCount, userDirectReferralsDisplay, setUserDirectReferralsDisplay, directReferralList, isDirectReferral = false }) => {
    const [count, setCount] = useState(0);
    const [icount, setICount] = useState(0);
    const [appendedComponents, setAppendedComponents] = useState([]);
    const [appendedOtherComponents, setAppendedOtherComponents] = useState([]);
    const [appendedDirectComponents, setAppendedDirectComponents] = useState([]);

    const handleIncrement = (incrementCount = true) => {
        if (incrementCount) {
            setCount(count + 1);
            referralFormData = null;
        }
        // setICount(icount + 1);
        if (isDirectReferral) {
            const newComponents = [...appendedDirectComponents, <ReferralForm data={referralFormData} key={icount} id={uniqKey(`DynamicDiractReferralForm${icount}`)} />];
            setAppendedDirectComponents(newComponents);
        } else if (referralFormData && !referralFormData.isMyLead) {
            const newComponents = [...appendedOtherComponents, <ReferralForm data={referralFormData} key={icount} id={uniqKey(`DynamicOtherReferralForm${icount}`)} />];
            setAppendedOtherComponents(newComponents);
        } else {
            setICount(icount + 1);
            const newComponents = [...appendedComponents, <ReferralForm data={referralFormData} key={icount} id={'DynamicReferralForm' + icount} />];
            setAppendedComponents(newComponents);
        }
    };

    const handleDecrement = () => {
        if (count > 0 && count > userReferralCount) {
            setCount(count - 1);
            setICount(icount - 1);
            const newComponents = appendedComponents;
            newComponents.pop();
            setAppendedComponents(newComponents);
        }
    };

    useEffect(() => {
        if (referralFormData) {
            handleIncrement(false);
        }
    }, [referralFormData]);

    useEffect(() => {
        setCount(userReferralCount);
    }, [userReferralCount]);

    const handleReferralShowHide = (e, isDirectReferral = false) => {
        if (isDirectReferral) {
            if (userDirectReferralsDisplay === 'none') {
                setUserDirectReferralsDisplay('');
            }
            else {
                setUserDirectReferralsDisplay('none');
                hideAllRefferralForm('DynamicDirectReferralForms');
            }
        } else {
            if (userReferralsDisplay === 'none') {
                setUserReferralsDisplay('');
            }
            else {
                setUserReferralsDisplay('none');
                ['DynamicReferralForms','DynamicOtherReferralForms']
                .forEach(id=>hideAllRefferralForm(id));
            }
        }

    }

    const hideAllRefferralForm = (id) => {
        document.getElementById(id).querySelectorAll('div[id^="referralForm_"]')
            .forEach(div => {
                if (div && div.style) {
                    div.style.display = 'none';
                }
            });
    }

    return (
        <>
            <div className='mb-3'>
                <span className="counterText cursor-pointer underline-hover mb-4 fw-bold" onClick={e => handleReferralShowHide(e, true)} >{userDirectReferralCount} Direct Referral</span>
                <div className='mb-3'>{directReferralList}</div>
            </div>
            <div id='DynamicDirectReferralForms' className='mb-3' name='DynamicDirectReferralForms' method="post" ref={node => node && node.setAttribute('inert', '')}>
                {appendedDirectComponents.map((component, index) => (
                    <div key={index}>{component}</div>
                ))}
            </div>
            <div className='mb-3'>
                <span className="counterText cursor-pointer underline-hover mb-4 fw-bold" onClick={handleReferralShowHide} >{count} Referral</span>
                <div className='mb-3'>{referralList}</div>
            </div>
            <div id='DynamicReferralForms' className='mb-3' name='DynamicReferralForms' method="post">
                {appendedComponents.map((component, index) => (
                    <div key={index}>{component}</div>
                ))}
            </div>
            <div id='DynamicOtherReferralForms' className='mb-3' name='DynamicOtherReferralForms' method="post" ref={node => node && node.setAttribute('inert', '')}>
                {appendedOtherComponents.map((component, index) => (
                    <div key={index}>{component}</div>
                ))}
            </div>
            <div className='mb-3' style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                <div className="btn-group" style={{ width: 'fit-content' }} role="group" aria-label="Basic example">
                    <BsButton buttonName={<FontAwesomeIcon icon={faPlus} />} btnClass='primary' jsEvent={handleIncrement} />
                    <BsButton buttonName={<FontAwesomeIcon icon={faTrash} />} btnClass='primary' jsEvent={handleDecrement} />
                </div>
            </div>
        </>
    );
};

export default CounterButton;
