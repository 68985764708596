import { useEffect, useRef, useState } from "react";
import Inputs from "../../../helpers/inputs";
import { formatCurrency, multiSelectValue } from "../../../helpers/val";

export default function SpsForm(props) {
    const [additionalPropDisplay, setAdditionalPropDisplay] = useState('d-none');
    const [sellerDisclosuresDisplay, setSellerDisclosuresDisplay] = useState('d-none');
    const formRef = useRef();
    const [data, setData] = useState({});
    const [spsData, setSpsData] = useState({});

    // refs
    const OccupancyRef = useRef();
    const LeaseLengthRef = useRef();
    const RentedAmountRef = useRef();
    const gatedcommunityRef = useRef();
    const gatecodedescRef = useRef();
    const HomeownerAssociationRef = useRef();
    const HoaNameRef = useRef();
    const HoafeeRef = useRef();
    const HoaBillingRef = useRef();
    const HoaContactNumberRef = useRef();
    const FeesPerYearRef = useRef();
    const HeatingdescRef = useRef();
    const CoolingRef = useRef();
    const RoofAgeRef = useRef();
    const rooftypedescRef = useRef();
    const CoveredParkingSpacesRef = useRef();
    const GarageRef = useRef();
    const ConditionOfSubjectRef = useRef();
    const KitchenRenderedDescRef = useRef();
    const KitchenappdescRef = useRef();
    const KitchenFloorTypeRef = useRef();
    const mainfloortypeRef = useRef();
    const bedfloortypeRef = useRef();
    const bathfloortypeRef = useRef();
    const InGroundPoolRef = useRef();
    const InGroundSpaRef = useRef();
    const HomeLandscapingRef = useRef();
    const PropertyBasementRef = useRef();
    const AwareOfSinkholesRef = useRef();
    const StoriesRef = useRef();
    const MasterLevelRef = useRef();
    const CabinetsConditionRef = useRef();
    const AnySolarPanelsRef = useRef();
    const solarleasecompanyRef = useRef();
    const ConnectedToCityWaterRef = useRef();
    const IsSharedWellRef = useRef();
    const IsWellAgreementRef = useRef();
    const ConnectedToCitySewerRef = useRef();
    const RestrictedCommunityRef = useRef();
    const AnyLiensRef = useRef();
    const foundationRepairedRef = useRef();
    const AnyFoundationIssuesRef = useRef();
    const HomeownerInsuranceRef = useRef();
    const ProceedsCureIssueRef = useRef();
    const ClaimNatureRef = useRef();
    const NeedOfRepairRef = useRef();
    const DetailNeedOfRepairRef = useRef();
    const financingTypesAvailableRef = useRef();
    const PartiesOnTitleRef = useRef();
    const AboutHouseRef = useRef();

    const diligence = [
        { id: `Occupancy_Al${props.id}`, inputType: "select", name: "Occupancy_Al", label: "Select Occupancy", placeholder: "Select Occupancy", multiple: false, options: { "": "Select Occupancy", "Vacant": "Vacant", "Tenant-Occupied": "Tenant-Occupied", "Owner-Occupied": "Owner-Occupied", "Occupied by Parent(s)": "Occupied by Parent(s)", "Occupied by Sibling(s)": "Occupied by Sibling(s)", "Occupied by Offspring": "Occupied by Offspring" }, showHideAction: `Tenant-Occupied=>LeaseLength_Al${props.id}|RentedAmount_Al${props.id}`, useref: OccupancyRef },

        { id: `LeaseLength_Al${props.id}`, inputType: "select", name: "LeaseLength_Al", label: "Lease Length", multiple: false, options: ["{EmptySelect:true}|", "M to M", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], useref: LeaseLengthRef },

        { id: `RentedAmount_Al${props.id}`, inputType: "text", name: "RentedAmount_Al", label: "Rented Amount", placeholder: "Rented Amount", useref: RentedAmountRef },
    ]

    const propertyDetail = [
        { id: `gatedcommunity_Al${props.id}`, inputType: "select", name: "gatedcommunity_Al", label: "Is the property in gated community?", options: { "": "-select-", Yes: "Yes", No: "No" }, showHideAction: `Yes=>gatecodedesc_Al${props.id}`, useref: gatedcommunityRef },

        { id: `gatecodedesc_Al${props.id}`, inputType: "text", name: "gatecodedesc_Al", label: "Gate Code desc", useref: gatecodedescRef },

        { id: `HomeownerAssociation_Al${props.id}`, inputType: "select", name: "HomeownerAssociation_Al", label: "Homeowner's Association", options: { "": "-select-", None: "None", Mandatory: "Mandatory", Voluntary: "Voluntary" }, showHideAction: `Mandatory|Voluntary=>HoaName_Al${props.id}|Hoafee_Al${props.id}|HoaBilling_Al${props.id}|HoaContactNumber_Al${props.id}|FeesPerYear_Al${props.id}`, useref: HomeownerAssociationRef },

        { id: `HoaName_Al${props.id}`, inputType: "text", name: "HoaName_Al", label: "HOA Name", placeholder: "Enter HOA Name", useref: HoaNameRef },

        { id: `Hoafee_Al${props.id}`, inputType: "text", name: "Hoafee_Al", label: "HOA Fee", placeholder: "Enter HOA Fee", useref: HoafeeRef, formateType: 'currency' },

        { id: `HoaBilling_Al${props.id}`, inputType: "select", name: "HoaBilling_Al", label: "HOA Billing", options: { "": "-select-", Monthly: "Monthly", Quaterly: "Quaterly", "Bi-Annual": "Bi-Annual", "In Full": "In Full" }, useref: HoaBillingRef },

        { id: `HoaContactNumber_Al${props.id}`, inputType: "tel", name: "HoaContactNumber_Al", label: "HOA Contact Number", placeholder: "Enter HOA Contact Number ", useref: HoaContactNumberRef, formateType: 'phone' },

        { id: `FeesPerYear_Al${props.id}`, inputType: "text", name: "FeesPerYear_Al", label: "Fees Per Year", placeholder: "Enter Fees Per Year", useref: FeesPerYearRef, formateType: 'currency' },

        { id: `Heatingdesc_Al${props.id}`, inputType: "select", name: "Heatingdesc_Al", label: "Heating", options: { "": "-select-", "Central Heat-Electric": "Central Heat-Electric", "Central Heat-Gas": "Central Heat-Gas", "No Heat": "No Heat" }, useref: HeatingdescRef },

        { id: `Cooling_Al${props.id}`, inputType: "select", name: "Coolingdesc_Al", label: "Cooling", options: { "": "-select-", "Central Air-Electric": "Central Air-Electric", "Central Heat-Gas": "Central Air-Gas", "Window AC": "Window AC", "No AC": "No AC" }, useref: CoolingRef },

        { id: `RoofAge_Al${props.id}`, inputType: "select", name: "RoofAge_Al", label: "Roof Age", options: ["{EmptySelect:true}|", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, "Less then 1", "15+"], useref: RoofAgeRef },

        { id: `rooftypedesc_Al${props.id}`, inputType: "select", name: "rooftypedesc_Al", label: "Roof Type Desc", options: { "": "-select-", "Built-up": "Built-up", Composition: "Composition", Concrete: "Concrete", Asphalt: "Asphalt", Metal: "Metal", Other: "Other" }, useref: rooftypedescRef },

        { id: `CoveredParkingSpaces_Al${props.id}`, inputType: "select", name: "CoveredParkingSpaces_Al", label: "Carport Parking Spaces", options: { "": "-select-", "0 carport": "0 Carport", "1 carport": "1 Carport", "2 carport": "2 Carport", "3 carport": "3 Carport", "4 carport": "4 Carport" }, useref: CoveredParkingSpacesRef },

        { id: `Garage_Al${props.id}`, inputType: "select", name: "Garage_Al", label: "Garage", options: { "": "-select-", "No Garage": "No garage", "1 car": "1 car", "2 car": "2 car", "3 car": "3 car", "4 car": "4 car" }, useref: GarageRef },

        { id: `ConditionOfSubject_Al${props.id}`, inputType: "select", name: "ConditionOfSubject_Al", label: "Condition Of Subject", options: { "": "-select-", "Very Good": "Very Good", Good: "Good", "Needs Some Work": "Needs Some Work", "Needs Extensive Work": "Needs Extensive Work" }, useref: ConditionOfSubjectRef },

        { id: `KitchenRenderedDesc_Al${props.id}`, inputType: "select", name: "KitchenRenderedDesc_Al", label: "Kitchen Desc", options: { "": "-select-", "Granite slab": "Granite slab", Quartz: "Quartz", Laminate: "Corian", "Granite tile": "Tile" }, useref: KitchenRenderedDescRef },

        { id: `Kitchenappdesc_Al${props.id}`, inputType: "select", name: "Kitchenappdesc_Al", label: "Kitchen Appliances", options: { "": "-select-", Stainless: "Stainless", Black: "Black", White: "White", Mixed: "Mixed" }, useref: KitchenappdescRef },

        { id: `KitchenFloorType_Al${props.id}`, inputType: "select", name: "KitchenFloorType_Al", label: "Type of flooring in kitchen", options: { "": "-select-", Tile: "Tile", "Vinyl / Linoleum": "Vinyl / Linoleum", Laminate: "Laminate", Hardwood: "Hardwood", Carpet: "Carpet", Concrete: "Concrete", "Woodplank Tile": "Woodplank Tile", "Travertine Tile": "Travertine Tile", "Saltillo Tile": "Saltillo Tile", Other: "Other" }, useref: KitchenFloorTypeRef },

        { id: `mainfloortype_Al${props.id}`, inputType: "select", name: "mainfloortype_Al", label: "What type of flooring is used in the main areas of the home?", multiple: true, options: { "": "-select-", Tile: "Tile", "Marble / Porcelian": "Marble/Porcelian", Laminate: "Laminate", Travertine: "Travertine", Carpet: "Carpet", Concrete: "Concrete", "Vinyl / linoleum": "Vinyl/Linoleum", Hardwood: "Hardwood", "Saltillo tile": "Saltillo tile", "Woodplank tile": "Woodplank tile", Other: "Other" }, useref: mainfloortypeRef },

        { id: `bedfloortype_Al${props.id}`, inputType: "select", name: "bedfloortype_Al", label: "What type of flooring is used in the bedrooms?", multiple: true, options: { "": "-select-", Tile: "Tile", "Marble / Porcelian": "Marble/Porcelian", Laminate: "Laminate", Travertine: "Travertine", Carpet: "Carpet", Concrete: "Concrete", "Vinyl / linoleum": "Vinyl/Linoleum", Hardwood: "Hardwood", "Saltillo tile": "Saltillo tile", "Woodplank tile": "Woodplank tile", Other: "Other" }, useref: bedfloortypeRef },

        { id: `bathfloortype_Al${props.id}`, inputType: "select", name: "bathfloortype_Al", label: "What type of flooring is used in the bathrooms?", multiple: true, options: { "": "-select-", Tile: "Tile", "Marble / Porcelian": "Marble/Porcelian", Laminate: "Laminate", Travertine: "Travertine", Carpet: "Carpet", Concrete: "Concrete", "Vinyl / linoleum": "Vinyl/Linoleum", Hardwood: "Hardwood", "Saltillo tile": "Saltillo tile", "Woodplank tile": "Woodplank tile", Other: "Other" }, useref: bathfloortypeRef },

        { id: `InGroundPool_Al${props.id}`, inputType: "select", name: "InGroundPool_Al", label: "In-Ground Pool", options: { "": "-select-", Y: "Yes", N: "No" }, showHideAction: `Y=>InGroundSpa_Al${props.id}`, useref: InGroundPoolRef },

        { id: `InGroundSpa_Al${props.id}`, inputType: "select", name: "InGroundSpa_Al", label: "In-Ground Spa", options: { "": "-select-", Y: "Yes", N: "No" }, useref: InGroundSpaRef },

        { id: `HomeLandscaping_Al${props.id}`, inputType: "select", name: "HomeLandscaping_Al", label: "Does the home have landscaping?", options: { "": "-select-", "Both front and back yards": "Both front and back yards", "Front yard only": "Front yard only", "Back yard only": "Back yard only", None: "None" }, useref: HomeLandscapingRef },

        { id: `PropertyBasement_Al${props.id}`, inputType: "select", name: "PropertyBasement_Al", label: "Property have basement ?", options: { "": "-select-", "No Basement": "No Basement", "Unfinished basement": "Unfinished basement", "Partially finished": "Partially finished", "Finished basement": "Finished basement" }, useref: PropertyBasementRef },

        { id: `AwareOfSinkholes_Al${props.id}`, inputType: "select", name: "AwareOfSinkholes_Al", label: "Are you aware of sinkholes on the property?", options: { "": "-select-", Y: "Yes", N: "No" }, useref: AwareOfSinkholesRef },

    ]

    const part2 = [

        { id: `Stories_Al${props.id}`, inputType: "select", name: "Stories_Al", label: "Stories", options: ["{EmptySelect:true}|", 1, 2, 3, 1.5, 2.5, "3+"], useref: StoriesRef },

        { id: `MasterLevel_Al${props.id}`, inputType: "select", name: "MasterLevel_Al", label: "Master Bedroom Level", options: ["{EmptySelect:true}|", 1, 2, 3, "3+"], useref: MasterLevelRef },

        { id: `CabinetsCondition_Al${props.id}`, inputType: "select", name: "CabinetsCondition_Al", label: "Cabinets condition", options: { "": "-select-", Good: "Good", Average: "Average", Poor: "Poor" }, useref: CabinetsConditionRef },

        { id: `AnySolarPanels_Al${props.id}`, inputType: "select", name: "AnySolarPanels_Al", label: "Are there any solar panels on the property?", options: { "": "-select-", None: "None", Owned: "Owned", Leased: "Leased" }, showHideAction: `Leased=>solarleasecompany_Al${props.id}`, useref: AnySolarPanelsRef },

        { id: `solarleasecompany_Al${props.id}`, inputType: "text", name: "solarleasecompany_Al", label: "Solar leasing company name", placeholder: "Solar leasing company name", useref: solarleasecompanyRef },

        { id: `ConnectedToCityWater_Al${props.id}`, inputType: "select", name: "ConnectedToCityWater_Al", label: "City Water", options: { "": "-select-", Y: "Yes", N: "No" }, showHideAction: `N=>IsSharedWell_Al${props.id}`, useref: ConnectedToCityWaterRef },

        { id: `IsSharedWell_Al${props.id}`, inputType: "select", name: "IsSharedWell_Al", label: "If Not connected to City Water - Is it a shared Well?", options: { "": "-select-", "N/A": "N/A", Y: "Yes", N: "No" }, showHideAction: `Y=>IsWellAgreement_Al${props.id}`, useref: IsSharedWellRef },

        { id: `IsWellAgreement_Al${props.id}`, inputType: "select", name: "IsWellAgreement_Al", label: "Is there a well agreement?", options: { "": "-select-", Y: "Yes", N: "No" }, useref: IsWellAgreementRef },

        { id: `ConnectedToCitySewer_Al${props.id}`, inputType: "select", name: "ConnectedToCitySewer_Al", label: "City Sewer", options: { "": "-select-", Y: "Yes", N: "No" }, useref: ConnectedToCitySewerRef },

        { id: `RestrictedCommunity_Al${props.id}`, inputType: "select", name: "RestrictedCommunity_Al", label: "Is the property located in an age restricted community?", options: { "": "-select-", Y: "Yes", N: "No" }, useref: RestrictedCommunityRef },

        { id: `AnyLiens_Al${props.id}`, inputType: "select", name: "AnyLiens_Al", label: "Is Owner aware of any liens on the property? IRS or Local tax lein, mechanics or judgments", options: { "": "-select-", Y: "Yes", N: "No" }, useref: AnyLiensRef },

        { id: `foundationRepaired_Al${props.id}`, inputType: "select", name: "foundationRepaired_Al", label: "Foundation Repaired", options: { "": "-select-", N: "No", Y: "Yes" }, useref: foundationRepairedRef },

        { id: `AnyFoundationIssues_Al${props.id}`, inputType: "select", name: "AnyFoundationIssues_Al", label: "Foundation Issues", options: { "": "-select-", Y: "Yes", N: "No" }, useref: AnyFoundationIssuesRef },

        { id: `HomeownerInsurance_Al${props.id}`, inputType: "select", name: "HomeownerInsurance_Al", label: "Has the Owner ever made a claim on their homeowner's insurance?", options: { "": "-select-", Y: "Yes", N: "No" }, showHideAction: `Y=>ProceedsCureIssue_Al${props.id}|ClaimNature_Al${props.id}`, useref: HomeownerInsuranceRef },

        { id: `ProceedsCureIssue_Al${props.id}`, inputType: "select", name: "q4ProceedsCureIssue_Al", label: "Did the seller use the proceeds to cure the issue?", options: { "": "-select-", Y: "Yes", N: "No" }, useref: ProceedsCureIssueRef },

        { id: `ClaimNature_Al${props.id}`, inputType: "text", name: "ClaimNature_Al_Al", label: "What was the nature of the claim?", placeholder: "Enter Fees Per Year", useref: ClaimNatureRef },

        { id: `NeedOfRepair_Al${props.id}`, inputType: "select", name: "NeedOfRepair_Al", label: "Are you or the seller aware of any item, equipment or system that is in need of repair including defects or malfunctions?", options: { "": "-select-", Y: "Yes", N: "No" }, showHideAction: `Y=>DetailNeedOfRepair_Al${props.id}`, useref: NeedOfRepairRef },

        { id: `DetailNeedOfRepair_Al${props.id}`, inputType: "text", name: "DetailNeedOfRepair_Al", label: "Please detail the item in need of repair", placeholder: "Detail Need Of Repair", useref: DetailNeedOfRepairRef },

        { id: `financingTypesAvailable_Al${props.id}`, inputType: "select", name: "financingTypesAvailable_Al", label: "Financing Types Available", options: { "": "-select-", N: "No", Y: "Yes" }, useref: financingTypesAvailableRef },

        { id: `PartiesOnTitle_Al${props.id}`, inputType: "select", name: "PartiesOnTitle_Al", label: "Are all parties on title available to sign?", options: { "": "-select-", Y: "Yes", N: "No", Probate: "Probate" }, useref: PartiesOnTitleRef },

        { id: `AboutHouse_Al${props.id}`, inputType: "text", name: "AboutHouse_Al", label: "Anything else to tell us about the house", placeholder: "about the house?", useref: AboutHouseRef },

    ];

    function toggleForm(formName) {
        if (formName === 'Addtional Properties') {
            if (additionalPropDisplay === 'd-none') {
                setAdditionalPropDisplay('');
            } else {
                setAdditionalPropDisplay('d-none');
            }
        } else if (formName === 'Seller Disclosures') {
            if (sellerDisclosuresDisplay === 'd-none') {
                setSellerDisclosuresDisplay('');
            } else {
                setSellerDisclosuresDisplay('d-none');
            }
        }
    }

    const hanldeOnChange = (e) => {
        const element = e.target;
        if (element.dataset && element.dataset.showHideAction) {
            const value = element.value;
            setShowHide(element, value);
        }
    }

    function setShowHide(element, value) {
        if (element.dataset && element.dataset.showHideAction) {
            let showHideAction = element.dataset.showHideAction;
            if (!showHideAction) return;
            showHideAction = showHideAction.split('=>');
            const conditionValue = showHideAction[0];
            if (typeof showHideAction[1] !== 'undefined' && showHideAction[1]) {
                showHideAction = showHideAction[1].split('|'); // get ids of elements where show efect.
                showHideAction.forEach(id => {
                    const ele = document.getElementById(id);
                    if (ele && ele.parentNode) {
                        if (conditionValue && conditionValue.split('|').includes(value)) {
                            ele.parentNode.classList.remove('d-none');
                            ele.removeAttribute('data-do-not-count-on-submit');
                        } else {
                            ele.parentNode.classList.add('d-none');
                            ele.setAttribute('data-do-not-count-on-submit', true);
                        }
                    }
                });
            }
        }
    }

    function refValue(ref, value) {
        if (ref && ref.current && value) {
            if (ref.current.hasAttribute('multiple')) {
                multiSelectValue(ref.current, value);
            } else {
                ref.current.value = value;
            }
            setShowHide(ref.current, value);
        } else {
            ref.current.value = '';
        }
    }

    useEffect(() => {
        setSpsData(props.sps);
        setData(formRef.current ? formRef.current.elements : {});
    }, [props.sps])

    useEffect(() => {
        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
                const element = data[key];
                if (element) {
                    if (spsData && spsData[element.id]) {
                        if (element.hasAttribute('multiple')) {
                            for (const option of element.options) {
                                if (spsData[element.id].split(',').includes(option.value)) {
                                    option.selected = true;
                                }
                            }
                        } else {
                            element.value = spsData[element.id];
                        }
                    }
                    setShowHide(element, element.value);
                }
            }
        }
    }, [data, spsData]);

    useEffect(() => {
        if (!spsData || spsData.occupancy === undefined) return;
        refValue(OccupancyRef, spsData.occupancy);
        refValue(LeaseLengthRef, spsData.lease_length);
        refValue(RentedAmountRef, spsData.rented_amount);
        refValue(gatedcommunityRef, spsData.gated_community);
        refValue(gatecodedescRef, spsData.gate_code_desc);
        refValue(HomeownerAssociationRef, spsData.homeowner_association);
        refValue(HoaNameRef, spsData.hoa_name);
        refValue(HoafeeRef, formatCurrency(spsData.hoa_fee));
        refValue(HoaBillingRef, spsData.hoa_billing);
        refValue(HoaContactNumberRef, spsData.hoa_contact_number);
        refValue(FeesPerYearRef, formatCurrency(spsData.fees_per_year));
        refValue(HeatingdescRef, spsData.heating_desc);
        refValue(CoolingRef, spsData.cooling);
        refValue(RoofAgeRef, spsData.roof_age);
        refValue(rooftypedescRef, spsData.roof_type_desc);
        refValue(CoveredParkingSpacesRef, spsData.covered_parking_spaces);
        refValue(GarageRef, spsData.carport);
        refValue(ConditionOfSubjectRef, spsData.condition_of_subject);
        refValue(KitchenRenderedDescRef, spsData.kitchen_rendered_desc);
        refValue(KitchenappdescRef, spsData.kitchenapp_desc);
        refValue(KitchenFloorTypeRef, spsData.kitchen_floor_type);
        refValue(mainfloortypeRef, spsData.main_floor_type);
        refValue(bedfloortypeRef, spsData.bed_floor_type);
        refValue(bathfloortypeRef, spsData.bath_floor_type);
        refValue(InGroundPoolRef, spsData.in_ground_pool);
        refValue(InGroundSpaRef, spsData.in_ground_spa);
        refValue(HomeLandscapingRef, spsData.home_landscaping);
        refValue(PropertyBasementRef, spsData.property_basement);
        refValue(AwareOfSinkholesRef, spsData.aware_of_sinkholes);
        refValue(StoriesRef, spsData.stories);
        refValue(MasterLevelRef, spsData.master_level);
        refValue(CabinetsConditionRef, spsData.cabinets_condition);
        refValue(AnySolarPanelsRef, spsData.any_solar_panels);
        refValue(solarleasecompanyRef, spsData.solar_lease_company);
        refValue(ConnectedToCityWaterRef, spsData.connected_to_city_water);
        refValue(IsSharedWellRef, spsData.is_shared_well);
        refValue(IsWellAgreementRef, spsData.is_well_agreement);
        refValue(ConnectedToCitySewerRef, spsData.connected_to_city_sewer);
        refValue(RestrictedCommunityRef, spsData.restricted_community);
        refValue(AnyLiensRef, spsData.any_liens);
        refValue(foundationRepairedRef, spsData.foundation_repaired);
        refValue(AnyFoundationIssuesRef, spsData.any_foundation_issues);
        refValue(HomeownerInsuranceRef, spsData.homeowner_insurance);
        refValue(ProceedsCureIssueRef, spsData.proceeds_cure_issue);
        refValue(ClaimNatureRef, spsData.claim_nature);
        refValue(NeedOfRepairRef, spsData.need_of_repair);
        refValue(DetailNeedOfRepairRef, spsData.detail_need_of_repair);
        refValue(financingTypesAvailableRef, spsData.financing_types_available);
        refValue(PartiesOnTitleRef, spsData.parties_on_title);
        refValue(AboutHouseRef, spsData.about_house);
    }, [data, spsData])

    return (
        <form {...props} onChange={hanldeOnChange} ref={formRef}>
            <div onClick={() => toggleForm('Addtional Properties')} className="cursor-pointer fw-bold underline mb-3">Add More Property Details</div>
            <div className={additionalPropDisplay}>
                <Inputs inputs={diligence} />
                <Inputs inputs={propertyDetail} />
            </div>
            <hr />
            <div onClick={() => toggleForm('Seller Disclosures')} className="cursor-pointer fw-bold underline mb-3">Seller Disclosures</div>
            <div className={sellerDisclosuresDisplay}><Inputs inputs={part2} /></div>
            <hr />
        </form>
    );
}

function checkId(id) {

}
