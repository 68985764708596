import { Link, useLocation, useParams } from 'react-router-dom';
import './style.css';
import '../../color.css';
import { schools, setColorByFullSchool } from '../../helpers/setColors';
import { sData } from '../../helpers/sData';
import ForFsuHowItWorks from '../../served-components/how-it-works/forFsuHowitworks';
import ForOtherSchools from '../../served-components/how-it-works/forotherschools';

export default function HowItWorks() {
    const location = useLocation();
    const params = useParams()
    const data = location && location.state ? location.state.data : {};
    let fullSchoolName = data ? data.FullSchoolName : sData('FullSchoolName');
    if(params.school)
        sData('ParamsSchool',params.school);
    if(!fullSchoolName){
        fullSchoolName = schools[params.school];
        if(fullSchoolName)
            sData('FullSchoolName',fullSchoolName);
    }
    let cauname = params.cauname;
    if(cauname){
        sData('cauname',cauname);
    }
    setColorByFullSchool(fullSchoolName);
    let paramsSchool = sData('ParamsSchool');
    paramsSchool = paramsSchool ? `/${paramsSchool}` : '';
    const JoinLink = () => <Link className="join-button" to={`${paramsSchool}/referral`} state={{data:{FullSchoolName:fullSchoolName},cauname:cauname}}>Join Now</Link>
    return (
        <div className="container main-body how-it-works p-2">
            {
                fullSchoolName === 'Florida State University'
                    ? <ForFsuHowItWorks JoinLink={JoinLink}/>
                    : <ForOtherSchools JoinLink={JoinLink}/>
            }
        </div>
    );
}
