import { createContext, useContext, useRef, useState } from "react";
import { getMsas } from "../../api/commonApi";

const MsaContext = createContext();

export const MsaProvider = ({ children }) => {
    // const [msa, setMsa] = useState(null);
    const msa = useRef(null);

    const getMsa = async (state) => {
        if (msa.current && msa.current[state]) {
            return msa.current[state];
        } else {
            const response = await getMsas(state, 'abb')
            if (!msa.current)
                msa.current = { [state]: response };
            else
                msa.current = { ...msa.current, [state]: response };
            return response;
        }
    };

    return (
        <MsaContext.Provider value={getMsa}>
            {children}
        </MsaContext.Provider>
    );
};

export const useMsa = () => {
    return useContext(MsaContext);
};
