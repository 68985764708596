import { isLogedIn } from "../helpers/loginCheck";
import { get, post } from "./api";

// API URLs
// const API_PATH = 'RankApiUpdate/';
// const API_PATH = 'RankApi/';
const API_PATH = 'rankcollege/';
// const GET_COLLEGE_RANK = `${API_PATH}getRanksCollege`;
const GET_COLLEGE_RANK = `rankcollege`;
// const SET_REFERRAL_DATA = `${API_PATH}setData`;
const SET_REFERRAL_DATA = `${API_PATH}store`;
const GET_RANK_DATA = `${API_PATH}getRankData`;
// const GET_SCHOOL_DATA = `${API_PATH}schoolData`;
const GET_SCHOOL_DATA = `${API_PATH}show`;
const GET_USER_SCHOOL_NAME = `${API_PATH}userSchoolName`;
const GET_CA_REFERRAL_DATA = `${API_PATH}caRaferralData`;
const SEARCH_SCHOOL = `${API_PATH}searchSchool`;

export async function getCollegeRank(currentPage, itemsPerPage, forUser, school) {
    forUser = forUser ? 'Y' : 'N';
    return await get(GET_COLLEGE_RANK, `page=${currentPage}&limit=${itemsPerPage}&forUser=${forUser}&school=${school}`);
}

export async function saveReferralForm(data) {
    return await post(SET_REFERRAL_DATA, data);
}

export function getRankData(key, fullSchoolName = '') {
    return new Promise((resolve, reject) => {
        get(GET_RANK_DATA, `key=${key}&fullSchoolName=${fullSchoolName}`).then((res => {
            resolve(res);
        })).catch((err) => {
            console.warn('GET_RANK_DATA=> ', err);
        })
    })
}

export async function searchSchool(school) {
    try {
        if (!school || typeof school !== 'string' || !school.trim() || school.length > 30) return {};
        const res = await get(SEARCH_SCHOOL, `school=${school}`);
        return res;
    } catch (error) {
        console.warn(error);
    }
}

export function getSchoolData(school, forUser, currentPage, itemsPerPage, sortOrder, sortBy) {
    forUser = forUser ? 'Y' : 'N';
    return new Promise((resolve, reject) => {
        get(GET_SCHOOL_DATA, `school=${school}&forUser=${forUser}&page=${currentPage}&limit=${itemsPerPage}&sortOrder=${sortOrder}&sortBy=${sortBy}`).then((res => {
            resolve(res);
        })).catch((err) => {
            console.warn('GET_SCHOOL_DATA=> ', err);
            reject(err);
        })
    });
}

export function getUserSchoolName() {
    return new Promise((resolve, reject) => {
        get(GET_USER_SCHOOL_NAME).then((res => {
            resolve(res);
        })).catch((err) => {
            console.warn('GET_USER_SCHOOL_NAME=> ', err);
        })
    });
}

export function getCaRaferralData(caReferralId, fullSchoolName) {
    return new Promise((resolve, reject) => {
        get(GET_CA_REFERRAL_DATA, `caReferralId=${caReferralId}&fullSchoolName=${fullSchoolName}`).then((res => {
            resolve(res);
        })).catch((err) => {
            console.warn('GET_SCHOOL_DATA=> ', err);
        })
    });
}


// Login API
// const ADD_PASSWORD = `${API_PATH}addPassword`;
const ADD_PASSWORD = `addPassword`;
// const LOGIN = `${API_PATH}login`;
const LOGIN = `login`;
const LOGOUT = 'logout';
// const AGENT_INFO = `${API_PATH}agentInfo`;
const AGENT_INFO = `info`;
const CHECK_USERNAME_EMAIL = `checkUsernameEmail`;
const FORGET_PASSWORD = `forgotPassword`;
const SET_UP_ACCOUNT = `setUpAccount`;
const REFERRAL_DECLINE = `referralDecline`;

export function addPassword(id, data) {
    return new Promise((resolve, reject) => {
        post(`${ADD_PASSWORD}/${id}`, data).then(res => resolve(res))
            .catch(err => console.warn(err));
    })
}

export function login(data) {
    return new Promise((resolve, reject) => {
        post(LOGIN, data).then(res => resolve(res))
            .catch(err => console.warn(err));
    })
}
export async function logout() {
    try {
        return await post(LOGOUT);
    } catch (error) {
        console.warn(error);
    }
}

export async function getAgentInfo() {
    try {
        return await get(AGENT_INFO);
    } catch (error) {
        console.warn(error);
    }
}

export async function checkUsernameEmail(flag, emailOrUsername, forceCheck) {
    if (isLogedIn()) return;
    try {
        return await get(CHECK_USERNAME_EMAIL, `flag=${flag}&emailOrUsername=${emailOrUsername}`);
    } catch (error) {
        console.warn(error);
    }
}

export async function forgotPassword(data) {
    try {
        return await post(FORGET_PASSWORD, data);
    } catch (error) {
        console.warn(error);
    }
}

export async function setUpAccount(data) {
    try {
        return await post(SET_UP_ACCOUNT, data);
    } catch (error) {
        console.warn(error);
    }
}

export const referralDecline = async (data) => {
    try {
        return post(REFERRAL_DECLINE, data);
    } catch (error) {
        console.warn(error);
    }
}

// user referral api
const GET_REFERRAL_LEAD = `lead/show`;

export async function getReferralLead(id) {
    if (!isLogedIn()) return;
    try {
        return await get(`${GET_REFERRAL_LEAD}/${id}`);
    } catch (error) {
        console.warn(error);
    }
}
