// https://www.instagram.com/nilcollectiveagent/
// https://twitter.com/collectivenil
// https://www.tiktok.com/@collectiveagent
// https://www.youtube.com/@CollectiveAgent

import { faDiscord, faFacebook, faGoogle, faLinkedin, faRedditAlien, faSquareInstagram, faTelegram, faTiktok, faXTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"

const icons = [
    { icon: faSquareInstagram, link: 'https://www.instagram.com/nilcollectiveagent/' },
    { icon: faXTwitter, link: 'https://twitter.com/collectivenil' },
    { icon: faDiscord, link: 'https://discord.gg/re6c8cKbxK' },
    { icon: faYoutube, link: 'https://www.youtube.com/@CollectiveAgent' },
    { icon: faTiktok, link: 'https://www.tiktok.com/@collectiveagent' }
]

const iconsB = [
    { icon: faFacebook, link: 'https://www.facebook.com/groups/978385120512695/' },
    { icon: faTelegram, link: 'https://t.me/CollectiveAgent' },
    { icon: faLinkedin, link: 'https://linkedin.com/in/collective-agent-440256303' },
    { icon: faRedditAlien, link: 'https://www.reddit.com/user/CollectiveAgent/' },
    { icon: faGoogle, link: 'https://g.page/r/CWsFwGYXocyUEB0/review' },
]

const Footer = () => {
    return (
        <footer className="main-footer footer pb-0 pb75 pt75" >
            <div className="container d-flex justify-content-between align-items-center list-style-type-none">
                <aside>
                    <ul className="p-0 mb-0">
                        <li>William G. Betancourt</li>
                        <li>Broker-Associate</li>
                        <li>Collective Agent | Keller Williams</li>
                        <li><a href="mailto:nil@collectiveagent.co" className="mailtoclass">nil@collectiveagent.co</a></li>
                        {/* <li>Lic BK3200675</li> */}
                    </ul>
                </aside>
                <aside>
                    <ul className="row p-0">
                        {icons.map((icon, i) =>
                            <li className="cursor-pointer col" key={`icon${i}`}>
                                {icon.link ? <Link target="_black" to={icon.link} className="defaultTextColor">
                                    <FontAwesomeIcon className="f-icon" icon={icon.icon} />
                                </Link> :
                                    <FontAwesomeIcon className="f-icon" icon={icon.icon} />}
                            </li>
                        )}
                    </ul>
                    <ul className="row p-0 mb-0">
                        {iconsB.map((icon, i) =>
                            <li className="cursor-pointer col" key={`iconB${i}`}>
                                {icon.link ? <Link target="_black" to={icon.link} className="defaultTextColor">
                                    <FontAwesomeIcon className="f-icon" icon={icon.icon} />
                                </Link> :
                                    <FontAwesomeIcon className="f-icon" icon={icon.icon} />}
                            </li>
                        )}
                    </ul>
                </aside>
            </div>
        </footer>
    )
}

export default Footer
