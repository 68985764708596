
import './other.css';

export default function ForOtherSchools({ JoinLink }) {
    return (
        <>

            <header className='other-howitworks'>
                <h1>Welcome to Collective Agent!</h1>
                <p>Your go-to platform for real estate referrals to buy, sell, or refinance residential or commercial properties!</p>
            </header>
            <section className='other-howitworks'>
                <p>At Collective Agent, we understand the importance of community and the power of connections. Your direct impact is instrumental in growing our network and supporting NIL (Name, Image, Likeness) initiatives.</p>
                <p>Our team comprises local real estate experts with Keller Williams Realty nationwide, ensuring you receive top-notch service and guidance no matter where you are located.</p>
                <p>Feel free to connect with us or refer others to the Collective Agent Team. Together, let's make real estate transactions seamless and impactful for everyone involved!</p>
                <hr />
                <h1>Our Mission</h1>
                <p>At Collective Agent, our mission extends beyond facilitating real estate transactions. We are committed to making a positive impact on communities and supporting important causes. That's why we've pledged to donate a portion of the proceeds in each Real Estate Transaction towards NIL fundraising</p>
                <p>Through these efforts, we aim to create a positive ripple effect, supporting education, housing, and community development while ensuring that our real estate endeavors contribute to meaningful and lasting change. Together, let's build a brighter future for all.</p><hr />
                <h2>Your Role as a Member of Collective Agent</h2>
                <p><strong>Collective Agent Member:</strong> Buying, Selling, or Refinancing a Residential or Commercial Property</p>
                <p>Here's a simplified guide for achieving your goals of 4 referrals from your SOI (Sphere of Influence) and 4 Ambassador signups (Sharing your personalized ambassador link on Social Media and DMs of Family/Friends/Colleagues)</p>
                <hr />
                <h2>Sphere of Influence:</h2>
                <p><strong>STEP 1</strong> - Refer Friends, Family, Colleagues, and Strangers who are in the market/considering to <strong>BUY</strong>, <strong>SELL</strong> or <strong>REFINANCE</strong> a property.</p>
                <h4>Action Steps:</h4>
                <ul>
                    <li><strong>Friends Circle</strong> - Identify anyone that was looking for a home, is looking for a home, or could be in the market for a new home (Engagement, New Baby, Promotion).</li>
                    <li><strong>Family Circle</strong> - this could be a family member needing to Downsize (too much house) or someone that has been talking about moving to Florida and getting out of the Cold.</li>
                    <li><strong>Colleagues/Work Circle</strong> - this could be anyone you work with at your place of employment and any Customers or Clients that could be looking to Buy Sell or Refinance (purchased a home or property in the last 18 months).</li>
                    <li><strong>Acquaintance/Strangers Circle</strong> - these are people you have not formally met. They could be considered acquaintances, someone you are friendly with but not necessarily a friend. Strangers would be people at the Supermarket, at the coffee shop, or park that may happen to have College Football apparel as a great as breaker to start a conversation about the Collective Agent..</li>
                </ul>
                <hr />
                <h3>Ambassador Network Growth</h3>
                <p><strong>STEP 2</strong> - Share your Ambassador Link widely on social media on the 5 main Platforms for the greatest reach and aim for a total of 4 signups</p>
                <h4>Action Steps:</h4>
                <ul>
                    <li>Share/Post your Ambassador Link on Instagram/Facebook</li>
                    <li>Share/Post your Ambassador Link on Twitter/X</li>
                    <li>Share/Post your Ambassador Link on TikTok</li>
                    <li>Share/Post your Ambassador Link on Linkedin</li>
                </ul>
                <hr />
                <h3>Breakdown for Member Network Exponential Growth</h3>
                <div className="table-responsive">
                    <table className='table'>
                        <tbody>
                            <tr>
                                <th>Tier</th>
                                <th>Members</th>
                                <th>Referrals</th>
                                <th>Completed Transactions</th>
                                {/* <th>Network Growth</th> */}
                            </tr>
                            <tr>
                                <td onClick={()=>alert('Buying or Selling Personal Needs')}><span className='hyperlink-color underline cursor-pointer'>Tier 0</span></td>
                                <td>1</td>
                                <td>0</td>
                                <td>1</td>
                                {/* <td>Buying or Selling Personal Needs</td> */}
                            </tr>
                            <tr>
                                <td onClick={()=>alert('Member refers 4 people looking for real estate + Get 4 Tier 0 users (Personal Use) off Ambassador Link on Social Media')}><span className='hyperlink-color underline cursor-pointer'>Tier 1</span></td>
                                <td>4</td>
                                <td>4</td>
                                <td>5</td>
                                {/* <td>Member refers 4 people looking for real estate + Get 4 Tier 0 users (Personal Use) off Ambassador Link on Social Media</td> */}
                            </tr>
                            <tr>
                                <td onClick={()=>alert('Those 8 each refer 4 more people and Tier 1 (4) got 4 new members from their ambassador link')}><span className='hyperlink-color underline cursor-pointer'>Tier 2</span></td>
                                <td>16</td>
                                <td>32</td>
                                <td>24</td>
                                {/* <td>Those 8 each refer 4 more people and Tier 1 (4) got 4 new members from their ambassador link</td> */}
                            </tr>
                            <tr>
                                <td onClick={()=>alert('Those 32 referring 4 more people and 16 (Tier 2) got 4 new members from ambassador link')}><span className='hyperlink-color underline cursor-pointer'>Tier 3</span></td>
                                <td>64</td>
                                <td>128</td>
                                <td>96</td>
                                {/* <td>Those 32 referring 4 more people and 16 (Tier 2) got 4 new members from ambassador link</td> */}
                            </tr>
                            <tr>
                                <td onClick={()=>alert('Those 128 referrals refer 4 more and 64 (Tier 3) got 4 new members from ambassador link')}><span className='hyperlink-color underline cursor-pointer'>Tier 4</span></td>
                                <td>256</td>
                                <td>512</td>
                                <td>384</td>
                                {/* <td>Those 128 referrals refer 4 more and 64 (Tier 3) got 4 new members from ambassador link</td> */}
                            </tr>
                            <tr>
                                <td onClick={()=>alert('Those 512 referrals refer 4 more and 256 (Tier 4) got 4 new members from ambassador link')}><span className='hyperlink-color underline cursor-pointer'>Tier 5</span></td>
                                <td>1024</td>
                                <td>2048</td>
                                <td>1536</td>
                                {/* <td>Those 512 referrals refer 4 more and 256 (Tier 4) got 4 new members from ambassador link</td> */}
                            </tr>
                        </tbody>
                    </table>
                </div>
                <hr />
                <h3>Breakdown for One Tier 5  Member Contribution</h3>
                <div className="table-responsive">
                    <table className='table'>
                        <tbody>
                            <tr>
                                <th>Referral and Member Assumptions</th>
                                <th>&nbsp;</th>
                            </tr>
                            <tr>
                                <td>Total Tier 1 - 5 Referrals</td>
                                <td>2724</td>
                            </tr>
                            <tr>
                                <td>Total Members Added from Tier 5</td>
                                <td>1364</td>
                            </tr>
                            <tr>
                                <td>Success Ratio for Tier 1-5</td>
                                <td>25.00%</td>
                            </tr>
                            <tr>
                                <td>Total Successful Referrals</td>
                                <td>2046</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <hr />
                <h3>Example Memberships to Achieve $3,000,000 Goal</h3>
                <p>Each sales prices averages +/-  $400,000</p>
                <div className="table-responsive">
                    <table className='table'>
                        <tr>
                            <th></th>
                            <th>Members Needed</th>
                            <th>Each Member Raised</th>
                        </tr>
                        <tr>
                            <td>Tier 0 Members</td>
                            <td>2733</td>
                            <td>$1,320</td>
                        </tr>
                        <tr>
                            <td>Tier 1 Members</td>
                            <td>379</td>
                            <td>$7,920</td>
                        </tr>
                        <tr>
                            <td>Tier 2 Members</td>
                            <td>73</td>
                            <td>$40,920</td>
                        </tr>
                        <tr>
                            <td>Tier 3 Members</td>
                            <td>17</td>
                            <td>$166,880</td>
                        </tr>
                        <tr>
                            <td>Tier 4 Members</td>
                            <td>3</td>
                            <td>$733,920</td>
                        </tr>
                        <tr>
                            <td>Tier 5</td>
                            <td>1</td>
                            <td>$3,000,000</td>
                        </tr>
                    </table>
                </div>
            </section>
            <footer className='other-howitworks'>
                <p>Join the movement! Ready to be a part of something bigger? Start referring today, build your community, and unlock incredible rewards. Together, let's reach new heights!</p>
                {/* <a class="join-button" href="your_join_now_link">Join Now</a> */}
                <JoinLink />
            </footer>
        </>
    );
}
