import React, { createContext, useContext, useState } from 'react';

const BeforeInstallPromptContext = createContext();

export const useBeforeInstallPrompt = () => useContext(BeforeInstallPromptContext);

export const BeforeInstallPromptProvider = ({ children }) => {
  const [beforeInstallPromptEvent, setBeforeInstallPromptEvent] = useState(null);

  const setEvent = (event) => {
    setBeforeInstallPromptEvent(event);
  };

  return (
    <BeforeInstallPromptContext.Provider value={{ beforeInstallPromptEvent, setEvent }}>
      {children}
    </BeforeInstallPromptContext.Provider>
  );
};
