import { useEffect, useRef } from "react";
import Inputs from "../../../helpers/inputs";
import { formatCurrency } from "../../../helpers/val";

const RefinanceForm = (props) => {

	const lenderRef = useRef();
	const balanceRef = useRef();
	const interestRateRef = useRef();
	const termRef = useRef();
	const typeRef = useRef();
	const reasonRef = useRef();
	const desiredTermRef = useRef();
	const targetInterestRateRef = useRef();
	const cashOutAmountRef = useRef();

	const currentMortgageDetailsForm = [
		{ inputType: 'text', useref: lenderRef, id: `lender${props.id}`, label: 'Lender' },
		{ inputType: 'text', useref: balanceRef, id: `balance${props.id}`, label: 'Balance($)', formateType: 'currency' },
		{ inputType: 'number', useref: interestRateRef, id: `interestRate${props.id}`, label: 'Interest Rate(%)' },
		{
			inputType: 'select', useref: termRef, id: `term${props.id}`, label: 'Term', options: [
				'{EmptySelect:true}|',
				'15 years',
				'20 years',
				'30 years',
			]
		},
		{
			inputType: 'select', useref: typeRef, id: `type${props.id}`, label: 'Type', options: [
				'{EmptySelect:true}|',
				'Fixed-rate',
				'Adjustable-rate',
			]
		},
		{
			inputType: 'select', useref: reasonRef, id: `reason${props.id}`, label: 'Reason', options: [
				'{EmptySelect:true}|',
				'Lower Interest Rate',
				'Reduce Monthly Payment',
				'Shorten Loan Term',
				'Cash-out Refinance',
				'Consolidate Debt',
				'Remove PMI',
			]
		},
	];

	const refinancingGoalsForm = [
		{
			inputType: 'select', useref: desiredTermRef, id: `desiredTerm${props.id}`, label: 'Desired Term', options: [
				'{EmptySelect:true}|',
				'15 years',
				'20 years',
				'30 years',
			]
		},
		{ inputType: 'number', useref: targetInterestRateRef, id: `targetInterestRate${props.id}`, label: 'Target Interest Rate(%)' },
		{ inputType: 'text', useref: cashOutAmountRef, id: `cashOutAmount${props.id}`, label: 'Cash - out Amount($)', formateType: 'currency' },
	];

	useEffect(() => {
		if (props.data) {
			if (lenderRef.current && props.data.lender) {
				lenderRef.current.value = props.data.lender;
			}
			if (balanceRef.current && props.data.balance) {
				balanceRef.current.value = formatCurrency(props.data.balance);
			}
			if (interestRateRef.current && props.data.interestRate) {
				interestRateRef.current.value = props.data.interestRate;
			}
			if (termRef.current && props.data.term) {
				termRef.current.value = props.data.term;
			}
			if (typeRef.current && props.data.type) {
				typeRef.current.value = props.data.type;
			}
			if (reasonRef.current && props.data.reason) {
				reasonRef.current.value = props.data.reason;
			}
			if (desiredTermRef.current && props.data.desiredTerm) {
				desiredTermRef.current.value = props.data.desiredTerm;
			}
			if (targetInterestRateRef.current && props.data.targetInterestRate) {
				targetInterestRateRef.current.value = props.data.targetInterestRate;
			}
			if (cashOutAmountRef.current && props.data.cashOutAmount) {
				cashOutAmountRef.current.value = formatCurrency(props.data.cashOutAmount);
			}
		}
	}, [props.data]);

	return (
		<>
			<h4 align={'center'}>Current Mortgage Details</h4>
			<Inputs inputs={currentMortgageDetailsForm} />
			<h4 align={'center'}>Refinancing Goals</h4>
			<Inputs inputs={refinancingGoalsForm} />
		</>
	);
};

export default RefinanceForm;
