import { getSchoolColor } from "../api/schoolColors";
import { setCssVars } from "./cssVar";
import { hexToRgb } from "./rgb";
import { theme } from "./theme";

export function setColorByFullSchool(fullSchool, themeType = 'light') {
    const color = getSchoolColor(fullSchool);
    /*
        ColorCodesPrimry
        ColorCodessecondary
        ColorCodesfourth
        ColorCodesthird
        Colors
        University
    */
    if (color) {
        theme('light');
        setCssVars({
            '--ColorCodesPrimryRgb': hexToRgb(color.ColorCodesPrimry),
            '--ColorCodesPrimry': color.ColorCodesPrimry,
            '--ColorCodessecondaryRgb': hexToRgb(color.ColorCodessecondary),
            '--ColorCodessecondary': color.ColorCodessecondary,

            '--defaultTextColor': color.ColorCodesPrimry,

            '--btnColor': color.ColorCodessecondary,
            '--btnBgColor': color.ColorCodesPrimry,

            '--tableBodyColor': color.ColorCodesPrimry,
        })
    } else {
        theme(themeType ? themeType : 'light');
        setCssVars({
            '--ColorCodesPrimryRgb': themeType === 'light' ? '255,255,255' : '52, 58, 64',
            '--ColorCodesPrimry': themeType === 'light' ? '#052c3b' : '#343a40',
            '--ColorCodessecondaryRgb': '36,194,205',
            '--ColorCodessecondary': '#24c2cd',

            '--defaultTextColor': '#24c2cd',

            '--btnColor': '#fff',
            '--btnBgColor': '#24c2cd',

            '--tableBodyColor': '#fff'
        })
    }
}


export const schools = {
    'Georgia': 'University of Georgia',
    'Clemson': 'Clemson University',
    'Ohio State': 'Ohio State University',
    'Michigan': 'University of Michigan',
    'Florida': 'University of Florida',
    'Alabama': 'University of Alabama',
    'Oregon': 'University of Oregon',
    'FloridaState': 'Florida State University',
    'Louisville': 'University of Louisville',
    'Tennessee': 'University of Tennessee',
    'Iowa': 'University of Iowa',
    'Washington': 'University of Washington',
    'Penn State': 'Pennsylvania State University',
    'LSU': 'Louisiana State University',
    'Illinois': 'University of Illinois at Urbana-Champaign',
    'Texas': 'University of Texas at Austin',
    'Indiana': 'Indiana University',
    'Michigan State': 'Michigan State University',
    'Texas A&M': 'Texas A&M University',
    'Kentucky': 'University of Kentucky',
    'Wisconsin': 'University of Wisconsin',
    'Virginia': 'University of Virginia',
    'Auburn': 'Auburn University',
    'Oklahoma': 'University of Oklahoma',
    'Missouri': 'University of Missouri',
    'Ole Miss': 'University of Mississippi',
    'Arizona': 'University of Arizona',
    'Notre Dame': 'University of Notre Dame',
    'Oklahoma State': 'Oklahoma State University',
    'Kansas State': 'Kansas State University',
    'NC State': 'North Carolina State University',
    'SMU': 'Southern Methodist University',
    'Kansas': 'University of Kansas',
    'Liberty': 'Liberty University',
}
