import { useNavigate, useParams } from "react-router-dom";
import { referralDecline } from "../../api/rankcollegeApi";
import FormInput from "../../helpers/form"
import { getFormData } from "../../helpers/formData";
import { formateErrors } from "../../helpers/showErrors";

const DeclineReferral = () => {
  const params = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    const btn = e.nativeEvent.submitter;
    if (btn) { btn.disabled = true };
    const FD = getFormData(e.currentTarget.id);
    FD.append('referralFormId', params.referralFormId);
    FD.append('userId', params.userId);
    const res = await referralDecline(FD);
    if (btn) { btn.disabled = false };
    if (res.success) {
      navigate('/');
    } else {
      alert(formateErrors(res.msg));
    }
  }

  return (
    <div className="container p-2">
      <FormInput
        inputs={[
          { inputType: 'textarea', id: 'declineReason', label: 'Decline Reason' },
          { inputType: 'button', buttonName: 'Submit', btnClass: 'primary rounded-pill', fullWidth: true },
        ]}
        forms={{ onSubmit: handleSubmit, id: 'declineReasonForm' }}
      />
    </div>
  )
}

export default DeclineReferral;