import { getCounties, getMsas, getStates, getZipByCounty } from "../../../api/commonApi";
import { getRankData } from "../../../api/rankcollegeApi";
import FormInput from "../../../helpers/form";
import React, { useState, useEffect, useRef } from "react";
import { multiSelectValue, val } from "../../../helpers/val";
import { AddressFormReferral } from "./addressForm";
import { useStateData } from "../../../served-components/field/state";
import { useMsa } from "../../../served-components/field/msa";

export default function ReferralForm(props) {
    const [states, setStates] = useState([]);
    const stateRef = useRef(null);
    const [msas, setMsas] = useState([]);
    const [counties, setCounties] = useState([]);
    const [referralFavoriteCollege, setReferralFavoriteCollege] = useState('');
    const countyRef = useRef(null);
    // const [zipcodes, setZipcodes] = useState('');
    const [addressFormDisplay, setAddressFormDisplay] = useState('none');
    const [buyerAddressFormDisplay, setBuyerAddressFormDisplay] = useState('none');
    const [refinanceAddressFormDisplay, setRefinanceAddressFormDisplay] = useState('none');

    const [favCollege, setFavCollege] = useState(['{EmptySelect:true}|']);
    const [referralCollectives, setReferralCollectives] = useState(['{EmptySelect:true}|']);
    const ReferralPropertyTypeRef = useRef();

    const ReferralFirstNameRef = useRef();
    const ReferralLastNameRef = useRef();
    const ReferralEmailRef = useRef();
    const ReferralPhoneRef = useRef();
    const RelationshipToReferralRef = useRef();
    // const ReferralStateRef = useRef();
    const ReferralMsaRef = useRef();
    // const ReferralCountyRef = useRef();
    // const ReferralZipCodeRef = useRef();
    const ReferralFavoriteCollegeRef = useRef();
    const ReferralCollectiveRef = useRef();
    const RealEstateNeedsRef = useRef();
    const ReferralNoteForAgentRef = useRef();

    useEffect(() => {
        getRankData('favCollege').then(res => {
            setFavCollege(['{EmptySelect:true}|', ...res.data]);
        })
    }, []);

    useEffect(() => {
        if (props.data && typeof props.data === 'object') {
            const d = props.data;
            if (ReferralFirstNameRef.current)
                ReferralFirstNameRef.current.value = d.first_standard;
            if (ReferralLastNameRef.current)
                ReferralLastNameRef.current.value = d.last_standard;
            if (ReferralEmailRef.current)
                ReferralEmailRef.current.value = d.email;
            if (ReferralPhoneRef.current && d.phone)
                ReferralPhoneRef.current.value = d.phone;
            if (RelationshipToReferralRef.current)
                RelationshipToReferralRef.current.value = d.relationship_to_referral; // new
            // if(stateRef.current)
            //     stateRef.current.value = d.state;
            // if(ReferralMsaRef.current)
            //     ReferralMsaRef.current.value = d.msa;
            // if(countyRef.current)
            //     countyRef.current.value = d.county;
            // if (ReferralZipCodeRef.current)
            //     ReferralZipCodeRef.current.value = d.zipcode;
            if (ReferralFavoriteCollegeRef.current || d.full_school_name) {
                setReferralFavoriteCollege(d.full_school_name);
                getCollectives(null, d.full_school_name);
                // ReferralFavoriteCollegeRef.current.value = d.full_school_name; // new
            }
            if (ReferralCollectiveRef.current)
                ReferralCollectiveRef.current.value = d.collective; // new
            if (RealEstateNeedsRef.current)
                RealEstateNeedsRef.current.value = leadType(d.lead_type);
            if (ReferralPropertyTypeRef.current)
                ReferralPropertyTypeRef.current.value = d.prop_type;
            if (ReferralNoteForAgentRef.current)
                ReferralNoteForAgentRef.current.value = d.agent_notes;

        }
    }, [props.data])

    const leadType = (RealEstateNeeds) => {
        if (RealEstateNeeds === 'Seller') {
            return 'selling';
        } else if (RealEstateNeeds === 'Buyer') {
            return 'buying';
        } else if (RealEstateNeeds === 'refinancing') {
            return 'Refinancing';
        }
        return RealEstateNeeds;
    }

    const getCollectives = (e, v) => {
        getRankData('collectives', v).then(res => {
            setReferralCollectives(['{EmptySelect:true}|', ...res.data]);
            setTimeout(() => {
                if (v === 'Florida State University' && ReferralCollectiveRef.current)
                    ReferralCollectiveRef.current.value = "The Battle's End";
            }, 100);
        })
    }
    const { allStates, defaultState } = useStateData();
    const getMsa = useMsa();

    useEffect(() => {
        // getStates().then((res) => {
        if (!allStates) return;
        setStates(manageStates(allStates));
        if (props.data && typeof props.data === 'object') {
            const d = props.data;
            setTimeout(() => {
                if (stateRef.current)
                    stateRef.current.value = d.state;
            }, 100)
            if (!d.state) return;
            handleMsa(d.state).then(async () => {
                setTimeout(() => {
                    if (ReferralMsaRef.current)
                        ReferralMsaRef.current.value = d.msa;
                }, 100)
                await getCountiesByMsa(d.msa ?? '', false);
                setTimeout(() => {
                    if (countyRef.current)
                        multiSelectValue(countyRef.current, d.county);
                }, 100);
            });
        }
        else if (stateRef && stateRef.current) {
            setTimeout(() => {
                if (stateRef && stateRef.current) {
                    stateRef.current.value = defaultState;
                    handleMsa(stateRef.current.value);
                }
            }, 1000);
        };
        // }).catch((err => {
        //     console.warn(err)
        // }))
        // -----------------------------------
        if (RealEstateNeedsRef.current && ['Landlord', 'selling', 'Refinancing'].includes(RealEstateNeedsRef.current.value)) {
            setAddressFormDisplay('');
            if (RealEstateNeedsRef.current.value === 'Refinancing') {
                setRefinanceAddressFormDisplay('');
            } else {
                setRefinanceAddressFormDisplay('none');
            }
        } else {
            setAddressFormDisplay('none');
            setRefinanceAddressFormDisplay('none');
        }
        // -----------------------------------
        if (RealEstateNeedsRef.current && ['buying'].includes(RealEstateNeedsRef.current.value)) {
            setBuyerAddressFormDisplay('');
        } else {
            setBuyerAddressFormDisplay('none');
        }
        // -----------------------------------
    }, [allStates]);

    const getCountiesByMsa = (e, fillAutomatic = true) => {
        return new Promise((resolve, reject) => {
            getCounties(typeof e === 'string' ? e : e.currentTarget.value).then(res => {
                setCounties(res.counties);
                if (fillAutomatic && countyRef && countyRef.current) {
                    setTimeout(() => {
                        if (countyRef.current) {
                            for (const option of countyRef.current.options) {
                                if (option.value)
                                    option.selected = true;
                            }
                            if (res.counties) {
                                setZipcode(res.counties);
                            }
                        }
                        resolve(true);
                    }, 1000);
                } else {
                    resolve(true);
                }
            });
        });
    }
    const handleMsa = async (e) => {
        console.log("inside refferal form msa code");
        const state_abb = typeof e === 'string' ? e : e.currentTarget.value;
        // let res = msaData.current[state_abb];
        // if (msaData.current && !msaData.current[state_abb]) {
        //     // Fetch MSA data for the selected state
        //     res = await getMsas(state_abb, 'abb');
        //     // setMSAData({ ...msaData, [state_abb]: res });
        //     msaData.current = { ...msaData.current, [state_abb]: res }
        // }
        const res = await getMsa(state_abb);
        setMsas(manageMsas(res));
    }
    const setZipcode = (county) => {
        return;
        if (typeof county === 'object') {
            if (Array.isArray(county)) {
                county = county.join(',');
            } else {
                county = multiSelectValue(county.currentTarget);
            }
        }
        getZipByCounty(val(`#ReferralState${props.id}`), 'abb', county)
            .then((res) => {
                val(`#ReferralZipCode${props.id}`, res.zipcode);
            });
    }
    const toggleAdressForm = (e) => {
        const val = e.currentTarget.value;
        // -----------------------------------
        if (['Landlord', 'selling', 'Refinancing'].includes(val)) {
            setAddressFormDisplay('');
            if (val === 'Refinancing') {
                setRefinanceAddressFormDisplay('');
            } else {
                setRefinanceAddressFormDisplay('none');
            }
        } else {
            setAddressFormDisplay('none');
            setRefinanceAddressFormDisplay('none');
        }
        // -----------------------------------
        if (['buying'].includes(val)) {
            setBuyerAddressFormDisplay('');
        } else {
            setBuyerAddressFormDisplay('none')
        }
        // -----------------------------------
        if (val) {
            ReferralPropertyTypeRef.current.parentNode.style.display = '';
        } else {
            ReferralPropertyTypeRef.current.parentNode.style.display = 'none';
        }
        // -----------------------------------
        if (['Tenant', 'Landlord'].includes(val)) {
            setPropertyTypeOptions(Object.fromEntries(
                Object.entries(allPropertyTypeOptions).filter(([key]) => ['RLSE', 'COML'].includes(key))
            ));
            if (ReferralPropertyTypeRef.current) {
                ReferralPropertyTypeRef.current.value = 'RLSE';
            }
        } else if (['selling', 'buying'].includes(val)) {
            setPropertyTypeOptions(Object.fromEntries(
                Object.entries(allPropertyTypeOptions).filter(([key]) => !['RLSE', 'COML'].includes(key))
            ));
            if (ReferralPropertyTypeRef.current) {
                ReferralPropertyTypeRef.current.value = 'RESI';
            }
        } else {
            setPropertyTypeOptions(allPropertyTypeOptions);
            if (ReferralPropertyTypeRef.current)
                ReferralPropertyTypeRef.current.value = '';
        }
        // -----------------------------------
    }

    const allPropertyTypeOptions = {
        '': 'Select',
        RESI: 'RESI (Residential for Sale)',
        RLSE: 'RLSE (Residential Lease)',
        RINC: 'RINC (Residential Income)',
        COMM: 'COMM (Commercial)',
        COML: 'COML (Commercial Lease)',
        BZOP: 'BZOP (Business Oppurtunity)',
        LAND: 'LAND (Vacant/Agricultural)'
    }
    const [propertyTypeOptions, setPropertyTypeOptions] = useState(allPropertyTypeOptions);
    // Friend, Colleague, Relative, Acquaintance/Stranger
    const referralForm = [
        // { inputType: 'text', label: "Referral's Full Name", id: 'ReferralFullName' + props.id },
        [
            { inputType: 'text', label: "Referral's First Name", useref: ReferralFirstNameRef, id: 'ReferralFirstName' + props.id, validation: 'required' },
            { inputType: 'text', label: "Referral's Last Name", useref: ReferralLastNameRef, id: 'ReferralLastName' + props.id },
        ],
        { label: "Referral's Email", useref: ReferralEmailRef, id: 'ReferralEmail' + props.id, validation: 'required|email|fn:checkUsernameEmail:email,{value},true' },
        { label: "Referral's Phone", useref: ReferralPhoneRef, id: 'ReferralPhone' + props.id, formateType: 'phone' },
        { inputType: 'select', label: "Relationship to Referral", useref: RelationshipToReferralRef, id: 'RelationshipToReferral' + props.id, options: { friend: 'Friend', colleague: 'Colleague', relative: 'Relative', Acquaintance: 'Acquaintance' } },
        { label: "Referral's State", id: 'ReferralState' + props.id, options: states, changeEvent: handleMsa, useref: stateRef, validation: 'required' },
        { label: "Referral's Msa", useref: ReferralMsaRef, id: 'ReferralMsa' + props.id, options: msas, changeEvent: getCountiesByMsa, validation: 'required' },
        { label: "Referral's County", id: 'ReferralCounty' + props.id, options: counties, useref: countyRef/*, changeEvent: setZipcode*/, multiple: true },
        // { inputType: 'textarea', label: "Referral's ZipCode", useref: ReferralZipCodeRef, id: 'ReferralZipCode' + props.id },
        { inputType: 'select', label: "Referral's Favorite College", useref: ReferralFavoriteCollegeRef, id: 'ReferralFavoriteCollege' + props.id, options: favCollege, changeEvent: getCollectives, suggestionBox: true, value: referralFavoriteCollege },
        { label: "Referral's Collective", useref: ReferralCollectiveRef, id: 'ReferralCollective' + props.id, options: referralCollectives },
        { inputType: 'select', label: "Real Estate Needs", useref: RealEstateNeedsRef, id: 'RealEstateNeeds' + props.id, changeEvent: toggleAdressForm, options: { buying: 'Buying', selling: 'Selling', Refinancing: 'Refinancing', Tenant: 'Tenant', Landlord: 'Landlord' }, validation: 'required' },
        { inputType: 'select', label: "Referral's Property Type", id: 'ReferralPropertyType' + props.id, options: propertyTypeOptions, useref: ReferralPropertyTypeRef },
        "{{<hr>}}",
        { inputType: 'text', label: "Referral's Note For Agent", useref: ReferralNoteForAgentRef, id: 'ReferralNoteForAgent' + props.id },
    ];

    return (
        <div id={`${props.data && props.data.id ? `referralForm_${props.data.id}` : ''}`}>
            <hr style={{ borderTop: 'solid 5px' }} />
            <FormInput inputs={referralForm} forms={{ id: props.id, method: 'Post', name: props.id, style: { display: '' } }} />
            <AddressFormReferral data={props.data} referral={{ id: 'form_referral_address' + props.id, style: { display: addressFormDisplay } }} buyer={{ style: { display: buyerAddressFormDisplay } }} refinance={{ style: { display: refinanceAddressFormDisplay } }} />
        </div>
    );
}

function manageStates(res) {
    const statesObject = {};
    if (res.enabled) {
        for (const key in res.enabled) {
            if (Object.hasOwnProperty.call(res.enabled, key)) {
                const state = res.enabled[key];
                statesObject[key] = state;
            }
        }
    }
    if (res.stateWait) {
        for (const key in res.stateWait) {
            if (Object.hasOwnProperty.call(res.stateWait, key)) {
                const state = res.stateWait[key];
                statesObject[key] = state;
            }
        }
    }

    if (res.disabled) {
        for (const key in res.disabled) {
            if (Object.hasOwnProperty.call(res.disabled, key)) {
                const state = res.disabled[key];
                statesObject[`{disabled:true}|${key}`] = state;
            }
        }
    }
    return statesObject;
}

function manageMsas(res) {
    const msaObj = {};
    msaObj[''] = 'Select Msa';
    if (res.msa) {
        for (const key in res.msa) {
            if (Object.hasOwnProperty.call(res.msa, key)) {
                const msa = res.msa[key];
                msaObj[msa] = msa;
            }
        }
    }

    return msaObj;
}
