import { faListSquares } from "@fortawesome/free-solid-svg-icons";

export function FullScreenGrowingSpinner({ show = false, variant = 'secondary' }) {
    return (
        <div className="full-screen-loader-container" style={{ display: show ? '' : 'none' }}>
            <div class={`spinner-grow text-${variant}`} role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    );
}

export function FullScreenBubbleLoader({ show = faListSquares}) {
    return (
        <div className="full-screen-loader-container" style={{ display: show ? '' : 'none' }}>
            <div className="loader">
                <div className="bubble-1"></div>
                <div className="bubble-2"></div>
            </div>
        </div>
    );
}