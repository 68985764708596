import React, { createContext, useState, useEffect, useContext } from 'react';
import { getStates } from '../../api/commonApi';

// Create a context
const StateContext = createContext();

// Create a provider component
export const StateProvider = ({ children }) => {
    const [allStates, setStates] = useState(null);
    const [defaultState, setDefaultState] = useState('FL');

    useEffect(() => {
        // Fetch states from API
        getStates().then((data) => {
            setStates(data);
        });
    }, []);

    return (
        <StateContext.Provider value={{allStates, defaultState, setDefaultState}}>
            {children}
        </StateContext.Provider>
    );
};

// Custom hook to access states from context
export const useStateData = () => useContext(StateContext);
