import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBaseball, faBasketball, faFootball, faGolfBallTee, faHockeyPuck, faVolleyball, faWeightHanging } from "@fortawesome/free-solid-svg-icons";
import { faFutbol } from "@fortawesome/free-regular-svg-icons";
import { Link, useLocation } from "react-router-dom";
import Navbar from "./navbar";
export default function SchoolNavbar(props) {
    const location = useLocation();
    const path = location.pathname;
    return (
        <Navbar path={path} {...props}/>
    );
}



export function SchoolNavbar2(props) {
    return (
        <nav className={`${props.mainClass} navbar bg-body-tertiary px-2 sticky-top defaultTextColor`}>
            <ul className="nav w-100 justify-content-center">
                <li className="nav-item position-absolute" style={{left:'5px'}}>
                    <Link className="nav-link" to={'/'}>Home</Link>
                </li>
                {/* <li className="nav-item">
                    <h1 className="">COLLECTIVE</h1>
                </li> */}
                <li className="nav-item">
                    <img src="logo1.png" alt="Collective Logo" class="logo-img"/>
                </li>
            </ul>
            <ul className="nav w-100 justify-content-center flex-row gap-2">
                <li className="nav-item"><span className="h3"><FontAwesomeIcon icon={faFootball} /></span></li>
                <li className="nav-item"><span className="h3"><FontAwesomeIcon icon={faBasketball} /></span></li>
                <li className="nav-item"><span className="h3"><FontAwesomeIcon icon={faVolleyball} /></span></li>
                <li className="nav-item"><span className="h3"><FontAwesomeIcon icon={faWeightHanging} /></span></li>
                {/* <li className="nav-item"><span className="h3">AGENT</span></li> */}
                <li className="nav-item"><span className="h3"><FontAwesomeIcon icon={faGolfBallTee} /></span></li>
                <li className="nav-item"><span className="h3"><FontAwesomeIcon icon={faFutbol} /></span></li>
                <li className="nav-item"><span className="h3"><FontAwesomeIcon icon={faBaseball} /></span></li>
                <li className="nav-item"><span className="h3"><FontAwesomeIcon icon={faHockeyPuck} /></span></li>
            </ul>
            {/* <ul className="nav w-100 justify-content-center flex-row gap-2">
                <li className="nav-item"><Link className="nav-link" to={'/referral'} state={{data:props.data}}>Send referral to your relatives and friends</Link></li>
            </ul> */}
        </nav>
    );
}

function setActiveClass(e) {
    document.querySelectorAll('.headerLinks')
        .forEach(element => {
            element.classList.remove('active');
        })
    e.currentTarget.classList.add('active');
}