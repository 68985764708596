export const BsCheckBox = (props) => {
    return (
        <div className="form-check mb-3">
            <input className="form-check-input" type="checkbox" value={props.value} id={props.id} ref={props.useref}/>
            <label className="form-check-label" htmlFor={props.id}>
                {props.label}
            </label>
        </div>
    )
}
