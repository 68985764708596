import { useEffect, useRef, useState } from "react";
import SpsForm from "./sps";
import Inputs from "../../../helpers/inputs";
import BuyerAddressForm from "./buyerAddressForm";
import RefinanceForm from "./refinanceForm";
import { useStateData } from "../../../served-components/field/state";
import { getCounties } from "../../../api/commonApi";
import { formatCurrency } from "../../../helpers/val";
import { useCounty } from "../../../served-components/field/county";

export function AddressFormUser({ user, buyer, refinance }) {
    const address_user_ref = useRef();
    const city_user_ref = useRef();
    const state_user_ref = useRef();
    const zipcode_user_ref = useRef();
    const county_user_ref = useRef();
    const bathrooms_user_ref = useRef();
    const bedrooms_user_ref = useRef();
    const living_area_user_ref = useRef();
    const year_built_user_ref = useRef();
    const AskingPriceRef = useRef();
    const PresentPropertyToOurCashBuyerNetworkRef = useRef();

    const [states, setStates] = useState({});
    const [counties, setCounties] = useState([]);
    const [lastCountiesState, setLastCountiesState] = useState('');

    const { allStates, defaultState } = useStateData();
    const { lastCounty, setLastCounty, lastCountyState, setLastCountyState } = useCounty();
    useEffect(() => {
        if (allStates && allStates.enabled && typeof allStates.enabled === 'object') {
            setStates(allStates.enabled);
        }

        if (address_user_ref.current && user.sps && user.sps.address)
            address_user_ref.current.value = user.sps ? user.sps.address : '';
        if (city_user_ref.current && user.sps && user.sps.city)
            city_user_ref.current.value = user.sps ? user.sps.city : '';
        setTimeout(async () => {
            if (state_user_ref.current && user.sps && user.sps.seller_state) {
                state_user_ref.current.value = user.sps.seller_state;
                if(lastCountyState !== user.sps.seller_state){
                    setLastCountyState(user.sps.seller_state);
                    const c = await hanldeCounty(user.sps.seller_state, setCounties)
                    setLastCounty(c);
                }else{
                    setCounties(lastCounty);
                }
                setTimeout(() => {
                    if (county_user_ref.current)
                        county_user_ref.current.value = user.sps.seller_county;
                }, 100);
            } else if (state_user_ref.current) {
                state_user_ref.current.value = defaultState;
                if(lastCountyState !== defaultState){
                    setLastCountyState(defaultState);
                    const c = await hanldeCounty(defaultState, setCounties)
                    setLastCounty(c);
                }else{
                    setCounties(lastCounty);
                }
            }
        }, 100);
        if (zipcode_user_ref.current && user.sps && user.sps.zipcode)
            zipcode_user_ref.current.value = user.sps ? user.sps.zipcode : '';
        if (bathrooms_user_ref.current && user.sps && user.sps.baths)
            bathrooms_user_ref.current.value = user.sps ? user.sps.baths : '';
        if (bedrooms_user_ref.current && user.sps && user.sps.beds)
            bedrooms_user_ref.current.value = user.sps ? user.sps.beds : '';
        if (living_area_user_ref.current && user.sps && user.sps.living_area)
            living_area_user_ref.current.value = user.sps.living_area;
        if (year_built_user_ref.current && user.sps && user.sps.year_built)
            year_built_user_ref.current.value = user.sps.year_built;
        if (AskingPriceRef.current && user.sps && user.sps.fsbo_price)
            AskingPriceRef.current.value = formatCurrency(user.sps.fsbo_price);
        if (PresentPropertyToOurCashBuyerNetworkRef.current && user.sps && user.sps.seller_wants_cash_offer)
            PresentPropertyToOurCashBuyerNetworkRef.current.checked = user.sps.seller_wants_cash_offer === 'Y' ? true : false;
    }, [user.sps, allStates]);

    const handleStateChange = async (e) => {
        const value = e.target && e.target.value ? e.target.value : '';
        hanldeCounty(value, setCounties);
    }

    const [labelA, setLabelA] = useState('Asking Price');
    const [labelB, setLabelB] = useState('Present Property To Our Cash Buyer Network');

    useEffect(() => {
        if (refinance.style.display === '') { // 'Refinancing'
            setLabelA('Purchased Price');
            setLabelB('Connect With a Local Loan Officer');
        } else {
            setLabelA('Asking Price');
            setLabelB('Present Property To Our Cash Buyer Network');
        }
    }, [refinance.style.display]);

    const userForm = [
        { id: 'address_user', label: 'Property Address', useref: address_user_ref },

        { id: 'city_user', label: 'Property City', useref: city_user_ref },

        { inputType: 'select', id: 'StateSellingform_user', label: 'Property State', useref: state_user_ref, options: states, changeEvent: handleStateChange },

        { inputType: 'text', id: 'zipcode_user', label: 'Property Zipcode', useref: zipcode_user_ref, validation: 'required' },

        { inputType: 'select', id: 'CountySellingform_user', label: 'Property County', useref: county_user_ref, options: counties },

        { jsx: <h4 key={'userAskingPriceSection'} align='center'>Property Details</h4> },

        { inputType: 'text', id: 'AskingPrice', label: labelA, useref: AskingPriceRef, formateType: 'currency' },

        { id: 'bedrooms_user', label: 'Bedrooms', useref: bedrooms_user_ref },

        { id: 'bathrooms_user', label: 'Bathrooms', useref: bathrooms_user_ref },

        { id: 'living_area_user', label: 'Living Area (sqft)', useref: living_area_user_ref },

        { id: 'year_built_user', label: 'Year Built', useref: year_built_user_ref },

        { inputType: 'checkbox', id: 'PresentPropertyToOurCashBuyerNetwork', label: labelB, useref: PresentPropertyToOurCashBuyerNetworkRef },
    ];
    // const FullAddressFormDetail = {
    //     id: 'Sellingform_user',
    //     labels: 'Property',
    //     zipcode: { inputType: 'hidden' },
    //     data: {
    //         state: user && user.sps ? user.sps.seller_state : '',
    //         msa: user && user.sps ? user.sps.seller_msa : '',
    //         county: user && user.sps ? user.sps.seller_county : '',
    //         zipcode: user && user.sps ? user.sps.seller_zipcode : '',
    //     }
    // };
    return (
        <>
            {/* <form id='form_user' style={{ display: user.style.display }}>
                <Inputs inputs={userForm} />
                <BuyerAddressForm />
            </form> */}
            <form id='form_user' >
                <div style={{ display: user.style.display }}>
                    <h4 align={'center'}>Property Location</h4>
                    <Inputs inputs={userForm} />
                </div>
                {/* <div style={{ display: user.style.display }}>
                    <FullAddressForm {...FullAddressFormDetail} />
                </div> */}
                <div style={{ display: buyer.style.display }}>
                    <BuyerAddressForm id='form_user' data={user.sps} />
                </div>
                <div style={{ display: refinance.style.display }}>
                    <RefinanceForm id='form_user' data={user.sps} />
                </div>
            </form>
            <SpsForm id='form_user_SpsForm' style={{
                display: refinance.style.display === 'none' ? user.style.display : 'none'
            }} sps={user.sps} />
        </>
    )
}

export function AddressFormReferral({ referral, data, buyer, refinance }) {
    const address_referral_ref = useRef();
    const city_referral_ref = useRef();
    const state_referral_ref = useRef();
    const county_referral_ref = useRef();
    const zipcode_referral_ref = useRef();
    const bathrooms_referral_ref = useRef();
    const bedrooms_referral_ref = useRef();
    const ReferralAskingPriceRef = useRef();
    const living_area_referral_ref = useRef();
    const year_built_referral_ref = useRef();
    const ReferralPresentPropertyToOurCashBuyerNetworkRef = useRef();
    const { allStates, defaultState } = useStateData();
    const { lastCounty, setLastCounty, lastCountyState, setLastCountyState } = useCounty();
    const [states, setStates] = useState({});
    const [counties, setCounties] = useState({});

    const otherData = data && data.other_data ? JSON.parse(data.other_data) : {};
    useEffect(() => {
        if (allStates && allStates.enabled && typeof allStates.enabled === 'object') {
            setStates(allStates.enabled);
        }
        if (data && typeof data === 'object') {
            const d = data
            if (address_referral_ref.current && d.address)
                address_referral_ref.current.value = d.address;
            if (city_referral_ref.current && d.city)
                city_referral_ref.current.value = d.city;
            setTimeout(async () => {
                if (state_referral_ref.current && otherData && otherData.seller_state) {
                    state_referral_ref.current.value = otherData.seller_state;
                    
                    if(lastCountyState !== otherData.seller_state){
                        setLastCountyState(otherData.seller_state);
                        const c = await hanldeCounty(otherData.seller_state, setCounties);
                        setLastCounty(c);
                    }else{
                        setCounties(lastCounty);
                    }
                    setTimeout(() => {
                        if (county_referral_ref.current)
                            county_referral_ref.current.value = otherData.seller_county
                    }, 100);
                } else if (state_referral_ref.current) {
                    state_referral_ref.current.value = defaultState;
                    if(lastCountyState !== defaultState){
                        setLastCountyState(defaultState);
                        const c = await hanldeCounty(defaultState, setCounties);
                        setLastCounty(c);
                    }else{
                        setCounties(lastCounty);
                    }
                }
            }, 100);
            if (zipcode_referral_ref.current && d.zipcode)
                zipcode_referral_ref.current.value = d.zipcode;
            if (bathrooms_referral_ref.current && d.baths)
                bathrooms_referral_ref.current.value = d.baths;
            if (bedrooms_referral_ref.current && d.beds)
                bedrooms_referral_ref.current.value = d.beds;
            if (living_area_referral_ref && d.living_area)
                living_area_referral_ref.current.value = d.living_area;
            if (year_built_referral_ref && d.year_built)
                year_built_referral_ref.current.value = d.year_built;
            if (ReferralAskingPriceRef.current && d.fsbo_price)
                ReferralAskingPriceRef.current.value = formatCurrency(d.fsbo_price);
            if (ReferralPresentPropertyToOurCashBuyerNetworkRef.current && d && d.seller_wants_cash_offer)
                ReferralPresentPropertyToOurCashBuyerNetworkRef.current.checked = d.seller_wants_cash_offer === 'Y' ? true : false;
        } else {
            setTimeout(() => {
                if (state_referral_ref.current) {
                    state_referral_ref.current.value = defaultState;
                }
                hanldeCounty(defaultState, setCounties);
            }, 100);
        }
    }, [data]);

    const handleStateChange = async (e) => {
        const value = e.target && e.target.value ? e.target.value : '';
        hanldeCounty(value, setCounties);
    }

    // const FullAddressFormDetail = {
    //     id: `Selling${referral.id}`,
    //     labels: 'Property',
    //     zipcode: { inputType: 'hidden' },
    //     data: {
    //         state: otherData.seller_state,
    //         msa: otherData.seller_msa,
    //         county: otherData.seller_county,
    //         zipcode: otherData.seller_zipcode,
    //     }
    // };
    const [labelA, setLabelA] = useState('Asking Price');
    const [labelB, setLabelB] = useState('Present Property To Our Cash Buyer Network');

    useEffect(() => {
        if (refinance.style.display === '') { // 'Refinancing'
            setLabelA('Purchased Price');
            setLabelB('Connect With a Local Loan Officer');
        } else {
            setLabelA('Asking Price');
            setLabelB('Present Property To Our Cash Buyer Network');
        }
    }, [refinance.style.display]);

    const referralForm = [
        { id: 'address_referral' + referral.id, label: 'Referral Address', useref: address_referral_ref },

        { id: 'city_referral' + referral.id, label: 'Referral City', useref: city_referral_ref },

        { inputType: 'select', id: `StateSelling${referral.id}`, label: 'Property State', useref: state_referral_ref, options: states, changeEvent: handleStateChange },

        { inputType: 'text', id: 'zipcode_referral' + referral.id, label: 'Referral Zipcode', useref: zipcode_referral_ref },

        { inputType: 'select', id: `CountySelling${referral.id}`, label: 'Property County', useref: county_referral_ref, options: counties },

        { jsx: <h4 key={`ReferralAskingPriceSection${referral.id}`} align='center'>Property Details</h4> },

        { inputType: 'text', id: `ReferralAskingPrice${referral.id}`, label: `${labelA}`, useref: ReferralAskingPriceRef, formateType: 'currency' },

        { id: 'bathrooms_referral' + referral.id, label: 'Bathrooms', useref: bathrooms_referral_ref },

        { id: 'bedrooms_referral' + referral.id, label: 'Bedrooms', useref: bedrooms_referral_ref },

        { id: `living_area_referral${referral.id}`, label: 'Living Area (sqft)', useref: living_area_referral_ref },

        { id: `year_built_referral${referral.id}`, label: 'Year Built', useref: year_built_referral_ref },

        { inputType: 'checkbox', id: `ReferralPresentPropertyToOurCashBuyerNetwork${referral.id}`, label: `Referral ${labelB}`, useref: ReferralPresentPropertyToOurCashBuyerNetworkRef },
    ];

    return (
        <>
            {/* <FormInput inputs={referralForm} forms={{ id: referral.id, style: { display: referral.style.display } }} /> */}
            <form id={referral.id} >
                <div style={{ display: referral.style.display }}>
                    <h4 align={'center'}>Property Location</h4>
                    <Inputs inputs={referralForm} />
                </div>
                {/* <div style={{ display: referral.style.display }}>
                    <FullAddressForm {...FullAddressFormDetail} />
                </div> */}
                <div style={{ display: buyer.style.display }}>
                    <BuyerAddressForm id={`Referral${referral.id}`} data={otherData} realEstateNeeds={refinance.style.display === '' ? 'Refinancing' : ''} isReferralForm={true} />
                </div>
                <div style={{ display: refinance.style.display }}>
                    <RefinanceForm id={`Referral${referral.id}`} data={otherData} />
                </div>
            </form >
            <SpsForm id={`${referral.id}_SpsForm`} sps={data} style={{
                display: refinance.style.display === 'none' ? referral.style.display : 'none'
            }} />
        </>
    )
}

const hanldeCounty = (value, setCounties) => {
    return new Promise(resolve => {
        if (!value) { resolve(true); return; }
        console.log('value',value);
        getCounties('', value).then((res) => {
            if (res)
                setCounties(res.counties);
            setTimeout(() => {
                resolve(res.counties);
            }, 100);
        });
    });
}
