import FormInput from "../../helpers/form";
import { ShieldLockFill } from "../../served-components/icons";
import { getFormData } from "../../helpers/formData";
import { useNavigate, useParams } from "react-router-dom";
import { setUpAccount } from "../../api/rankcollegeApi";
import { setLoginDetail } from "../../helpers/loginCheck";
import { useEffect } from "react";
import { setColorByFullSchool } from "../../helpers/setColors";
import { sData } from "../../helpers/sData";
import Footer from "../../served-components/bottom/footer";
import { formateErrors } from "../../helpers/showErrors";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ReferralEmail() {
    const params = useParams();
    const navigate = useNavigate();

    function setUp(e) {
        const FD = getFormData(e.currentTarget.id);
        FD.append('referralFormId', params.referralFormId);
        FD.append('userId', params.userId);
        const btn = document.getElementById('addPassword');
        makeBtnProssess(btn);
        setUpAccount(FD).then(res => {
            alert(formateErrors(res.msg));
            if (res.success) {
                setLoginDetail(res.data);
                navigate('/u/leaderboard');
            }
            makeBtnProssess(btn,true);
        });
    }
    useEffect(() => {
        setColorByFullSchool(sData('FullSchoolName'));
    }, [])

    return (
        <>
            <div className="main-body school-main d-flex align-items-center justify-content-center">
                <div className="container p-2 d-flex flex-column justify-content-center align-items-center">
                    <div className="mt-5"></div>
                    <div className="w-100 d-flex justify-content-center">
                        <FormInput inputs={[
                            { inputType: 'text', label: 'Username', id: 'username', inputGroup: <FontAwesomeIcon icon={faUser} />, required: true, validation: 'required|username|max:12|fn:../../api/rankcollegeApi/checkUsernameEmail:username,{value}', validationOnChange: 'maxEntries:12' },
                            { inputType: 'password', label: 'Password', id: 'password', inputGroup: <ShieldLockFill />, required: true },
                            { inputType: 'password', label: 'Confirm Password', id: 'password_confirmation', inputGroup: <ShieldLockFill />, required: true },
                            { inputType: 'button', buttonName: 'Add Password', btnClass: 'primary rounded-pill', id: 'addPassword', fullWidth: true },
                        ]} forms={{ onSubmit: setUp, id: 'loginForm' }} />
                    </div>
                </div>
            </div>
            {sData('FullSchoolName') === 'Florida State University' && <Footer />}
        </>
    );
}

function makeBtnProssess(btn, done = false) {
    if (done) {
        btn.disabled = false;
    } else {
        btn.disabled = true;
    }
}