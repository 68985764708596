import { faMobileScreenButton } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Modal } from "bootstrap";
import { useEffect, useRef, useState } from "react";
import { useBeforeInstallPrompt } from "./beforeinstallprompt";

function AddToHome() {
    const installButtonRef = useRef();
    const installModalRef = useRef();
    const [installModal, setInstallModal] = useState();

    const { beforeInstallPromptEvent, setEvent } = useBeforeInstallPrompt();

    const handleClick = async () => {

        if (!beforeInstallPromptEvent) { return; }

        beforeInstallPromptEvent.prompt();

        const { outcome } = await beforeInstallPromptEvent.userChoice;
        // Act on the user's choice
        if (outcome === 'accepted') {
            console.log('User accepted the install prompt.');
        } else if (outcome === 'dismissed') {
            console.log('User dismissed the install prompt');
        }
        if (installModal) {
            installModal.hide();
        }
    }

    useEffect(() => {

        /* ---------------------------------------------------------------- */

        //  Select modal
        const installModal = new Modal(installModalRef.current);
        setInstallModal(installModal);

        /* ---------------------------------------------------------------- */

        // Check if the media query matches
        const mediaQuery = window.matchMedia('(display-mode: browser)');

        if (mediaQuery.matches && localStorage.getItem("a2h") !== 'yes') {
            // If the media query matches, display the element
            installModal.show();
        } else {
            // If the media query doesn't match, hide the element
            installModal.hide();
        }

        /* ---------------------------------------------------------------- */

        // if are standalone android OR safari
        if (window.matchMedia("(display-mode: standalone)").matches || window.navigator.standalone === true) {
            localStorage.setItem("a2h", "yes");
            installModal.hide();
        }

        /* ---------------------------------------------------------------- */

        // do action when finished install
        window.addEventListener("appinstalled", (e) => {
            console.log("success app install!");
            localStorage.setItem("a2h", "yes");
            installModal.hide();
        });

        /* ---------------------------------------------------------------- */

    }, [])

    useEffect(() => {
        const handleBeforeInstallPrompt = (event) => {
            // Save the beforeinstallprompt event
            setEvent(event);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, [setEvent]);

    return (
        <div className="modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" ref={installModalRef}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Add to Home Screen</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p><FontAwesomeIcon icon={faMobileScreenButton} /> Add to your device's Home Screen for a faster App-like experience.</p>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <button type="button" className="btn btn-primary" ref={installButtonRef} onClick={handleClick}>Add to Home Screen</button>
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Continue to Website</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddToHome;
