import { BsSuggestionBox } from "../suggestionbox ";

export function BsSelect({ id, label, options, changeEvent = null, suggestionBox, value = '', useref = null, showHideAction = '', multiple = false, simple, searchFunction }) {
    return (
        suggestionBox ?
            <BsSuggestionBox id={id} label={label} defaultSuggestions={options} changeEvent={changeEvent} value={value} simple={simple} searchFunction={searchFunction} />
            :
            <div className="form-floating mb-3">
                <select className="form-select" id={id} aria-label={label} onChange={(e) => { changeEvent && changeEvent(e) }} ref={useref} data-show-hide-action={showHideAction} multiple={multiple} style={{ height: `${multiple ? '100px' : ''}` }}>
                    <option value={''}>Select {setLabel(label)}</option>
                    <GetOptions options={options} />
                </select>
                <label htmlFor={id}>{label}</label>
            </div>
    )
}

function GetOptions({ options }) {
    if(!options)return;
    if (Array.isArray(options)) {
        return options.map(option => {
            return (
                !isEmptySelect(option) ?
                    <option key={'_' + option} value={option}>{option}</option>
                    : ''
                // isEmptySelect(option) ?
                //     <option key={'_' + uniqKey(i)} value={''}>Select</option>
                //     :
                //     <option key={'_' + option} value={option}>{option}</option>
            )
        });
    }

    else if (typeof options === 'object') {
        let i = 0;
        return Object.entries(options).map(([value, label]) => (
            !isDisabled(value) ?
                (value ?
                    <option key={'_' + value} value={value}>
                        {label}
                    </option> : '')
                :
                <option key={'_' + i + value.replace('{disabled:true}|', '')} disabled={true} value={value.replace('{disabled:true}|', '')}>
                    {label}
                </option>
        ))
    }
}

function isDisabled(value) {
    if (value.startsWith('{disabled:true}|')) {
        return true;
    }
    return false;
}

function isEmptySelect(value) {
    if (typeof value === 'string' && value.startsWith('{EmptySelect:true}|')) {
        return true;
    }
    return false;
}

function setLabel(label) {
    if (label && typeof label === 'string') {
        if (label.includes('State')) {
            return 'State';
        }
        else if (label.includes('County')) {
            return '';
        }
        else if (label.includes('City')) {
            return 'City';
        }
        else if (label.includes('Your Real Estate Needs')) {
            return '';
        } else if (label.includes('Your Property Type')) {
            return '';
        }
    }
    return label;
}
