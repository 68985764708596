import { useEffect, useRef, useState } from "react";
import Inputs from "../../../helpers/inputs";
import { getCounties, getZipByCounty, getZipByMsa } from "../../../api/commonApi";
import { formatCurrency, isObjEmpty, multiSelectValue, val } from "../../../helpers/val";
import { useStateData } from "../../../served-components/field/state";
import { useMsa } from "../../../served-components/field/msa";

export default function BuyerAddressForm(props) {

    const [states, setStates] = useState('');
    const [msas, setMsas] = useState('');
    const [counties, setCounties] = useState('');

    const StateRef = useRef();
    const MsaRef = useRef();
    const CountyRef = useRef();
    const ZipCodeRef = useRef();
    const PrimaryZipCodeRef = useRef();

    const handleMsa = async (e) => {
        const state_abb = typeof e === 'string' ? e : e.currentTarget.value;
        // let res = msaData.current[state_abb];
        // if (!msaData.current[state_abb]) {
        //     res = await getMsas(state_abb, 'abb');
        //     // setMSAData({ ...msaData, [state_abb]: res });
        //     msaData.current = { ...msaData.current, [state_abb]: res }
        // }
        const res = await getMsa(state_abb);
        setMsas(manageMsas(res));
    }

    const getCountiesByMsa = (e, fillAutomatic = true) => {
        return new Promise((resolve, reject) => {
            if (typeof e === 'object') {
                handleZipcode(e.currentTarget.value);
            }
            getCounties(typeof e === 'string' ? e : e.currentTarget.value).then(res => {
                setCounties(res.counties);
                if (fillAutomatic && CountyRef && CountyRef.current) {
                    setTimeout(() => {
                        if (CountyRef.current) {
                            for (const option of CountyRef.current.options) {
                                if (option.value)
                                    option.selected = true;
                            }
                        }
                        resolve(true);
                    }, 1000);
                } else {
                    resolve(true);
                }
            });
        });
    }

    const handleZipcode = (msa) => {
        if (typeof county === 'object') {
            msa = val(msa.tagret);
        }
        if (!msa) return;
        if (StateRef.current) {
            getZipByMsa(StateRef.current.value, 'abb', msa)
                .then((res) => {
                    if (ZipCodeRef.current)
                        ZipCodeRef.current.value = res.zipcode;
                });
        }
    }
    const { allStates, defaultState } = useStateData();
    const getMsa = useMsa();

    useEffect(() => {
        // allStates().then((res) => {
        if (!allStates) return;
        setStates(manageStates(allStates));
        if (props.data && typeof props.data === 'object' && !isObjEmpty(props.data)) {
            const d = props.data;
            if (PrimaryZipCodeRef.current && d.buyer_primary_zipcode)
                PrimaryZipCodeRef.current.value = d.buyer_primary_zipcode;
            if (!d.buyer_state) return;
            setTimeout(() => {
                if (StateRef.current)
                    StateRef.current.value = d.buyer_state;
            }, 100)
            handleMsa(d.buyer_state).then(async () => {
                setTimeout(() => {
                    if (MsaRef.current)
                        MsaRef.current.value = d.buyer_msa;
                }, 100)
                await getCountiesByMsa(d.buyer_msa ?? '', false);
                setTimeout(() => {
                    if (CountyRef.current)
                        multiSelectValue(CountyRef.current, d.buyer_county);
                }, 100);
                if (ZipCodeRef.current)
                    ZipCodeRef.current.value = d.buyer_zipcode;
            });
        }
        else if (StateRef && StateRef.current) {
            setTimeout(() => {
                if (StateRef && StateRef.current) {
                    StateRef.current.value = defaultState;
                    handleMsa(StateRef.current.value);
                }
            }, 100);
        };
        // }).catch((err => {
        //     console.warn(err)
        // }))
    }, [allStates, defaultState]);

    const [labelA, setLabelA] = useState('Buying');
    const [labelB, setLabelB] = useState('Buyer');

    useEffect(() => {
        if (props.realEstateNeeds === 'Refinancing') {
            setLabelA('Refinancing');
            setLabelB('Refinancing');
        } else {
            setLabelA('Buying');
            setLabelB('Buyer');
        }
    }, [props.realEstateNeeds]);

    const buyerUserForm = [
        { inputType: 'select', label: `State ${props.isReferralForm ? 'they are' : 'you are'} ${labelA} In`, id: 'BuyerState' + props.id, options: states, changeEvent: handleMsa, useref: StateRef },

        { label: `City ${props.isReferralForm ? 'they are' : 'you are'} ${labelA} In`, useref: MsaRef, id: 'BuyerMsa' + props.id, options: msas, changeEvent: getCountiesByMsa },

        { label: `County(s) ${props.isReferralForm ? 'they are' : 'you are'} ${labelA} in`, id: 'BuyerCounty' + props.id, options: counties, useref: CountyRef, /*changeEvent: handleZipcode,*/ multiple: true },

        labelB !== 'Refinancing' ? { inputType: 'textarea', label: `${labelB} ZipCode(s)`, useref: ZipCodeRef, id: 'BuyerZipCode' + props.id } : '',

        { inputType: 'text', label: `${labelB} Preferred ZipCode`, useref: PrimaryZipCodeRef, id: 'BuyerPrimaryZipCode' + props.id, validation: 'numeric|max:5|fn:checkZipcode:{value}', validationOnChange: 'maxEntries:5' },
    ];

    return (
        <>
            <h4 align='center'>Property Location</h4>
            <Inputs inputs={buyerUserForm} />
            {
                props.realEstateNeeds !== 'Refinancing' ?
                    <>
                        <h4 align='center'>Property Details</h4>
                        <PropertyDetailForm {...props} id={`Buyer${props.id}`} />
                    </>
                    : ''
            }
        </>
    );
}

export function FullAddressForm(props) {
    const labels = props.labels ? props.labels : '';
    const zipcode = props.zipcode && typeof props.zipcode === 'object' ? props.zipcode : {};

    const [states, setStates] = useState('');
    const [msas, setMsas] = useState('');
    const [counties, setCounties] = useState('');

    const StateRef = useRef();
    const MsaRef = useRef();
    const CountyRef = useRef();
    const ZipCodeRef = useRef();

    const handleMsa = async (e) => {
        const state_abb = typeof e === 'string' ? e : e.currentTarget.value;
        // let res = msaData.current[state_abb];
        // if (!msaData.current[state_abb]) {
        //     // Fetch MSA data for the selected state
        //     res = await getMsas(state_abb, 'abb');
        //     // setMSAData({ ...msaData, [state_abb]: res });
        //     msaData.current = { ...msaData.current, [state_abb]: res }
        // }
        const res = await getMsa(state_abb);
        setMsas(manageMsas(res));
    }

    const getCountiesByMsa = (e, fillAutomatic = true) => {
        return new Promise((resolve, reject) => {
            if (typeof e === 'object') {
                handleZipcode(e.currentTarget.value);
            }
            getCounties(typeof e === 'string' ? e : e.currentTarget.value).then(res => {
                setCounties(res.counties);
                if (fillAutomatic && CountyRef && CountyRef.current) {
                    setTimeout(() => {
                        if (CountyRef.current) {
                            for (const option of CountyRef.current.options) {
                                if (option.value)
                                    option.selected = true;
                            }
                        }
                        resolve(true);
                    }, 1000);
                } else {
                    resolve(true);
                }
            });
        });
    }

    const handleZipcode = (msa) => {
        if (typeof county === 'object') {
            msa = val(msa.tagret);
        }
        if (!msa) return;
        if (StateRef.current) {
            getZipByMsa(StateRef.current.value, 'abb', msa)
                .then((res) => {
                    if (ZipCodeRef.current)
                        ZipCodeRef.current.value = res.zipcode;
                });
        }
    }

    const { allStates, defaultState } = useStateData();
    const getMsa = useMsa();

    useEffect(() => {
        // getStates().then((res) => {
        if (!allStates) return;
        setStates(manageStates(allStates));
        if (props.data && typeof props.data === 'object' && !isObjEmpty(props.data)) {
            const d = props.data;
            if (!d.state) return;
            setTimeout(() => {
                if (StateRef.current)
                    StateRef.current.value = d.state;
            }, 100)
            handleMsa(d.state).then(async () => {
                setTimeout(() => {
                    if (MsaRef.current)
                        MsaRef.current.value = d.msa;
                }, 100)
                await getCountiesByMsa(d.msa ?? '', false);
                setTimeout(() => {
                    if (CountyRef.current)
                        multiSelectValue(CountyRef.current, d.county);
                }, 100);
                if (ZipCodeRef.current)
                    ZipCodeRef.current.value = d.zipcode;
            });
        }
        else if (StateRef && StateRef.current) {
            setTimeout(() => {
                if (StateRef && StateRef.current) {
                    StateRef.current.value = defaultState;
                    handleMsa(StateRef.current.value);
                }
            }, 100);
        };
        // }).catch((err => {
        //     console.warn(err)
        // }))
    }, [allStates, defaultState]);

    const buyerUserForm = [
        { inputType: 'select', label: `${labels} State`, id: 'State' + props.id, options: states, changeEvent: handleMsa, useref: StateRef },

        { label: `${labels} Msa`, useref: MsaRef, id: 'Msa' + props.id, options: msas, changeEvent: getCountiesByMsa },

        { label: `${labels} County`, id: 'County' + props.id, options: counties, useref: CountyRef, /*changeEvent: handleZipcode,*/ multiple: true },

        { inputType: 'textarea', label: `${labels} ZipCode(s)`, useref: ZipCodeRef, id: 'Zipcode' + props.id, ...zipcode },
    ];

    return (
        <Inputs inputs={buyerUserForm} />
    );
}

function PropertyDetailForm(props) {
    const id = props.id ? props.id : '';

    const bedroomsRef = useRef();
    const bathroomsRef = useRef();
    const askingPriceRef = useRef();
    const livingAreaUserRef = useRef();
    const presentPropertyToOurCashBuyerNetworkRef = useRef();

    useEffect(() => {
        if (props.data && typeof props.data === 'object' && !isObjEmpty(props.data)) {
            const d = props.data;
            const beds = d.beds ? d.beds : d.buyer_bedrooms;
            const baths = d.baths ? d.baths : d.buyer_bathrooms;
            const living_area = d.living_area ? d.living_area : d.buyer_living_area;
            const fsbo_price = d.fsbo_price ? d.fsbo_price : d.buyer_fsbo_price;
            const seller_wants_cash_offer = d.seller_wants_cash_offer ? d.seller_wants_cash_offer : d.buyer_seller_wants_cash_offer
            if (bedroomsRef.current && beds)
                bedroomsRef.current.value = beds;
            if (bathroomsRef.current && baths)
                bathroomsRef.current.value = baths;
            if (livingAreaUserRef.current && living_area)
                livingAreaUserRef.current.value = living_area;
            if (askingPriceRef.current && fsbo_price)
                askingPriceRef.current.value = formatCurrency(fsbo_price);
            if (presentPropertyToOurCashBuyerNetworkRef.current && seller_wants_cash_offer)
                presentPropertyToOurCashBuyerNetworkRef.current.checked = seller_wants_cash_offer === 'Y' ? true : false;
        }
    }, [props.data])

    const form = [
        { inputType: 'text', id: `askingPrice${id}`, label: 'Preferred Price', useref: askingPriceRef, formateType: 'currency' },

        { id: `bedrooms${id}`, label: 'Bedrooms Minimum', useref: bedroomsRef },

        { id: `bathrooms${id}`, label: 'Bathrooms Minimum', useref: bathroomsRef },

        { id: `livingArea${id}`, label: 'Living Area (sqft) Minimum', useref: livingAreaUserRef },

        { inputType: 'checkbox', id: `presentPropertyToOurCashBuyerNetwork${id}`, label: 'Get Alerts to Off Market / Distressed Properties', useref: presentPropertyToOurCashBuyerNetworkRef },
    ];

    return <Inputs inputs={form} />
}

function manageMsas(res) {
    const msaObj = {};
    msaObj[''] = 'Select';
    if (res.msa) {
        for (const key in res.msa) {
            if (Object.hasOwnProperty.call(res.msa, key)) {
                const msa = res.msa[key];
                msaObj[msa] = msa;
            }
        }
    }

    return msaObj;
}

function manageStates(res) {
    const statesObject = {};
    if (res.enabled) {
        for (const key in res.enabled) {
            if (Object.hasOwnProperty.call(res.enabled, key)) {
                const state = res.enabled[key];
                statesObject[key] = state;
            }
        }
    }
    if (res.stateWait) {
        for (const key in res.stateWait) {
            if (Object.hasOwnProperty.call(res.stateWait, key)) {
                const state = res.stateWait[key];
                statesObject[key] = state;
            }
        }
    }

    if (res.disabled) {
        for (const key in res.disabled) {
            if (Object.hasOwnProperty.call(res.disabled, key)) {
                const state = res.disabled[key];
                statesObject[`{disabled:true}|${key}`] = state;
            }
        }
    }
    return statesObject;
}
