import React, { useEffect, useRef, useState } from "react";
import { uniqKey } from "../helpers/key";
import Accordion from "./accordion";
import { getCaRaferralData } from "../api/rankcollegeApi";
import { BsCaret } from "../pages/home/components/icons";
import { getSchoolColor } from "../api/schoolColors";
import Pagination from "./pagination/pagination";
import { formatCurrency } from "../helpers/val";

export default function BsTable(props) {
    const [sortedColumn, setSortedColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [data, setData] = useState([]);
    const [accordionData, setAccordionData] = useState({});

    useEffect(() => {
        if (props.data && Array.isArray(props.data)) {
            setData(props.data);

            // Initialize accordion data
            if (props.accordion && props.accordion.key) {
                const initialAccordionData = {};
                props.data.forEach((row, index) => {
                    const accordionKey = row[props.accordion.key];
                    if (accordionKey && props.accordion.data && typeof props.accordion.data[index] === 'object') {
                        initialAccordionData[accordionKey] = props.accordion.data[index];
                    }
                });
                setAccordionData(initialAccordionData);
            }
        }
    }, [props.data, props.accordion]);

    const tableRowEvOdBg = ['even-color', 'odd-color'];
    let lastTtableRowEvOdBgKey = 1;

    const handleSort = (headerName) => {
        const key = props.headerKeyMap[headerName]; // Get the corresponding data key for the header name
        if (!key) return;
        if (typeof props.onSort === 'function') {
            props.onSort(key);
            return;
        }
        const isAsc = sortedColumn === key && sortOrder === 'asc';
        const newSortOrder = isAsc ? 'desc' : 'asc';
        const sortedData = [...data].sort((a, b) => {
            let row1 = a[key];
            let row2 = b[key];
            if (!isNaN(Number(row1)) && !isNaN(Number(row2))) {
                row1 = row1 ? Number(row1.toString().replaceAll(/[^0-9\.]/g, '')) : row1;
                row2 = row2 ? Number(row2.toString().replaceAll(/[^0-9\.]/g, '')) : row2;
            }

            if (isAsc) {
                return row1 > row2 ? 1 : -1;
            } else {
                return row1 < row2 ? 1 : -1;
            }
        });

        // Update sorted data
        setData(sortedData);

        // Re-arrange accordion data based on the sorted rows
        const updatedAccordionData = {};
        sortedData.forEach((row, index) => {
            const accordionKey = row[props.accordion.key];
            const accordionIndex = props.data.findIndex(item => item[props.accordion.key] === accordionKey);
            if (accordionIndex !== -1) {
                updatedAccordionData[accordionKey] = props.accordion.data[accordionIndex];
            }
        });
        setAccordionData(updatedAccordionData);

        setSortedColumn(key);
        setSortOrder(newSortOrder);
    };

    const findElementsByDataset = (attribute, value) => {
        const elements = document.querySelectorAll(`[data-${attribute}="${value}"]`);
        return Array.from(elements);
    }

    const color = getSchoolColor(props.accordion ? props.accordion.school : '');

    const handleShowMainAcc_bkp = async (e, data, school, key) => {
        const element = e.currentTarget;
        element.style.pointerEvents = "none";

        const elementTr = element.parentElement.parentElement;

        // const id = `child-table-${key}`;
        const id = `child-table-${data.id}`;
        if (document.getElementById(id)) {
            // findElementsByDataset('child-id', id)
            //     .forEach((ch) => {
            //         toggleSameTd(ch.id);
            //     });
            let onlyShow = false;

            if (document.getElementById(elementTr.dataset.nextNodes.replace('node', 'main-tr')).style.display !== 'none') {
                onlyShow = true;
                toggle(document.getElementById(id)?.par3entElement?.parentElement, null, element.parentNode.parentNode);
            }
            toggleSameTr(elementTr.id, true, onlyShow);
            // elementTr.dataset.nextNode;
            element.style.pointerEvents = "";
            return;
        }

        const div = document.createElement('div');
        div.id = id;
        div.classList.add('table-responsive');

        const table = document.createElement("table");
        table.classList.add("table", "table-striped", "mb-0");
        if (props.fixed) {
            table.classList.add('table-layout-fixed');
        }
        if (!id.includes('inner')) {
            table.style.borderBottom = `1px solid ${color?.ColorCodesPrimry ?? '#333'}`;
        }

        const headerRow = table.createTHead().insertRow();
        headerRow.setAttribute('align', 'center');

        const cols = ['Tier', 'User@', 'RE', 'CA', 'Deals', 'Raised'];
        cols.forEach((col) => {
            const th = document.createElement("th");
            if (col === 'RE') {
                th.classList.add('md-right');
                th.textContent = '';
            } else if (col === 'CA') {
                th.classList.add('md-left');
                th.textContent = '';
            } else {
                th.textContent = '';
            }
            headerRow.appendChild(th);
        });

        const tbody = table.createTBody();
        // -----------------------------------
        const res = await getCaRaferralData(data.id, school);
        const resData = res.data;
        if (!resData || !Array.isArray(resData)) return;
        // -----------------------------------

        let i = 0;
        resData.forEach(function (info) {
            const row = tbody.insertRow();
            row.setAttribute('align', 'center');
            // toggleSameTd(`leaderboard-table-${info.id}`, id);
            const rowId = `node-${info.id}`;
            row.id = rowId;
            const nextNodes = elementTr.dataset.nextNodes;
            elementTr.dataset.nextNodes = nextNodes ? [...nextNodes, rowId] : [rowId];
            toggleSameTr(rowId);
            for (const key in info) {
                if (key !== 'id') {
                    const cell = row.insertCell();
                    const span = document.createElement('span');
                    if (key === 'CAReferrals' && Number(info.CAReferrals)) {
                        span.dataset.index = i + '' + info.id; // Store 'i' in a data attribute
                        span.classList.add('cursor-pointer');
                        span.classList.add('underline');
                        span.addEventListener('click', (e) => {
                            const index = parseInt(span.dataset.index); // Retrieve 'i' from data attribute
                            handleShowMainAcc(e, { id: info.id }, school, `inner-${index}`);
                        });
                        cell.classList.add('md-left');
                    } else if (key === 'REReferrals') {
                        cell.classList.add('md-right');
                    }
                    else if (key === 'CAReferrals') {
                        cell.classList.add('md-left');
                    } else if (key === 'CAUser') {
                        cell.classList.add('md-left', 'md-pl-0')
                    }
                    if (['TotalRasied'].includes(key)) {
                        info[key] = formatCurrency(info[key]) || '-';
                    }
                    if (['Deals'].includes(key)) {
                        info[key] = info[key] || '-';
                    }
                    span.textContent = info[key];
                    cell.appendChild(span);
                }
            }
            i++;
        });
        const tr = document.createElement('tr');
        const td = document.createElement('td');
        td.colSpan = cols.length;
        td.style.padding = '0';
        div.appendChild(table);
        td.appendChild(div);
        tr.appendChild(td);
        element.parentNode.parentNode.after(tr);
        element.style.pointerEvents = "";
    }

    const handleShowMainAcc = async (e, data, school, key) => {

        /* --------------------------- */

        const element = e.currentTarget;
        element.style.pointerEvents = 'none';

        if (element.dataset.childOpen) {
            removeTable(document.getElementById(element.dataset.childOpen));
            element.dataset.childOpen = '';
            const mainTrIds = element.dataset.mainTrIds;
            typeof mainTrIds === 'string' && mainTrIds.split(',').forEach((id => {
                const mainTr = document.getElementById(id);
                if (mainTr) {
                    mainTr.style.display = '';
                }
            }));
            element.dataset.mainTrIds = '';
            element.style.pointerEvents = '';
            return;
        }

        /* --------------------------- */

        const res = await getCaRaferralData(data.id, school);
        const resData = res.data;
        if (!resData || !Array.isArray(resData)) return;

        /* --------------------------- */

        // Create table
        const table = document.createElement('table');
        table.className = 'table table-borderless table-layout-fixed';

        const div = document.createElement('div');
        div.id = `_dasda${data.id}`;
        div.classList.add('table-responsive');

        /* --------------------------- */

        // Create table header
        const headerRow = table.createTHead().insertRow();
        headerRow.setAttribute('align', 'center');

        const cols = ['Tier', 'User@', 'RE', 'CA', 'Deals', 'Raised'];
        cols.forEach((col) => {
            const th = document.createElement("th");
            if (col === 'RE') {
                th.classList.add('md-right');
                th.textContent = '';
            } else if (col === 'CA') {
                th.classList.add('md-left');
                th.textContent = '';
            } else {
                th.textContent = '';
            }
            headerRow.appendChild(th);
        });

        /* --------------------------- */

        // Create table body
        const tbody = table.createTBody();

        // `resData` in loop
        const mainTrIds = [];
        resData.forEach(info => {
            // Hide same main trs
            const mainTrId = `main-tr-${info.id}`;
            mainTrIds.push(mainTrId);
            // document.getElementById(mainTrId).style.display = 'none';
            hideTr(document.getElementById(mainTrId));
            // document.getElementById(mainTrId).style.display = 'none';
            // Create row
            const row = tbody.insertRow();
            row.setAttribute('align', 'center');
            lastTtableRowEvOdBgKey = lastTtableRowEvOdBgKey ? 0 : 1;

            // `info` object in loop
            let i = 0;
            for (const key in info) {
                if (Object.hasOwnProperty.call(info, key)) {
                    if (key === 'id') {
                        continue;
                    }
                    const cell = row.insertCell();
                    const span = document.createElement('span');
                    cell.classList.add(tableRowEvOdBg[lastTtableRowEvOdBgKey]);
                    // condition for columns
                    if (key === 'CAReferrals' && Number(info.CAReferrals)) {
                        span.dataset.index = i + '' + info.id; // Store 'i' in a data attribute
                        span.classList.add('cursor-pointer');
                        span.classList.add('underline');
                        span.addEventListener('click', (e) => {
                            const index = parseInt(span.dataset.index); // Retrieve 'i' from data attribute
                            handleShowMainAcc(e, { id: info.id }, school, `inner-${index}`);
                        });
                        cell.classList.add('md-left');
                    } else if (key === 'REReferrals') {
                        cell.classList.add('md-right');
                    }
                    else if (key === 'CAReferrals') {
                        cell.classList.add('md-left');
                    } else if (key === 'CAUser') {
                        cell.classList.add('md-left', 'md-pl-0')
                    }
                    if (['TotalRasied'].includes(key)) {
                        info[key] = formatCurrency(info[key]) || '-';
                    }
                    if (['Deals'].includes(key)) {
                        info[key] = info[key] || '-';
                    }
                    span.textContent = info[key];
                    cell.appendChild(span);
                }
                i++;
            }
        });
        element.dataset.mainTrIds = mainTrIds.join(',');

        /* --------------------------- */

        const tr = document.createElement('tr');
        const childRowId = uniqKey('childRowId');
        tr.id = childRowId;
        const td = document.createElement('td');
        td.colSpan = cols.length;
        td.style.padding = '0';
        div.appendChild(table);
        td.appendChild(div);
        tr.appendChild(td);
        // Cloest tr
        element.closest('tr').after(tr);
        element.dataset.childOpen = childRowId;
        element.style.pointerEvents = "";

        /* --------------------------- */

    }

    const hideTr = (tr) => {
        if (!tr) return;
        const elements = tr.querySelectorAll('td > span[data-child-open]:not([data-child-open=""])');
        elements.forEach(element => {
            /* -------------------------------- */
            const childOpen = element.dataset.childOpen;
            removeTable(document.getElementById(childOpen));
            /* -------------------------------- */
            element.dataset.childOpen = '';
            const mainTrIds = element.dataset.mainTrIds;
            typeof mainTrIds === 'string' && mainTrIds.split(',').forEach((id => {
                const mainTr = document.getElementById(id);
                if (mainTr) {
                    mainTr.style.display = '';
                }
            }));
            element.dataset.mainTrIds = '';
            /* -------------------------------- */
        });
        tr.style.display = 'none';
    }

    const removeTable = (table, isMainTable = false) => {
        if (!table) return;
        const elements = table.querySelectorAll('tr > td > span[data-child-open]:not([data-child-open=""])');

        elements.forEach(element => {
            element.dataset.childOpen = '';
            const mainTrIds = element.dataset.mainTrIds;
            typeof mainTrIds === 'string' && mainTrIds.split(',').forEach((id => {
                const mainTr = document.getElementById(id);
                if (mainTr) {
                    mainTr.style.display = '';
                }
            }));
            element.dataset.mainTrIds = '';
        });
        if (!isMainTable) {
            table.remove();
        }
    }

    const removeChildRows = (table) => {
        if (!table) return;
        let rows = table.querySelectorAll("tr[id^='_childRowId_']");
        rows.forEach(row => {
            row.remove();
        });
    };

    const toggle = (element, elementIds, lastElelment, onlyShow = false) => {
        if (elementIds && typeof elementIds === 'string') {
            elementIds = elementIds.split(',');
            elementIds.forEach((elementId) => {
                toggle(document.getElementById(elementId), null, lastElelment);
            });
        }
        if (!element || typeof element !== 'object') return;
        const elementCopy = element;
        if (lastElelment && typeof lastElelment === 'object') {
            if (element.contains(lastElelment.nextSibling)) {
                // console.log('object');
            } else {
                element.remove();
                lastElelment.after(elementCopy);

                if (elementCopy.style.display === '') {
                    elementCopy.style.display = '';
                    return;
                }
            }
        }
        if (elementCopy.style.display === 'none' || onlyShow) {
            elementCopy.style.display = '';
            return '';
        } else {
            elementCopy.style.display = 'none';
            return 'none';
        }
    }

    const toggleSameTd = (id, childeTableId) => {
        const td = document.getElementById(id);
        if (td && td.parentElement) {
            if (childeTableId)
                td.dataset.childId = childeTableId;
            toggle(td.parentElement);
        }
    }

    const toggleSameTr = (rowId, toggleAll = false, onlyShow = false) => {
        console.log('onlyShow=>', onlyShow);
        if (!rowId) return;
        const mainTr = document.getElementById(rowId.replace('node', 'main-tr'));
        if (toggleAll) {
            const nodeTr = document.getElementById(rowId);
            const nextNodes = nodeTr.dataset.nextNodes;
            if (nextNodes) {
                if (nextNodes && Array.isArray(nextNodes)) {
                    // nextNodes.forEach(nextNode => {
                    for (let i = 0; i < nextNodes.length; i++) {
                        const nextNode = nextNodes[i];
                        const t = toggle(document.getElementById(nextNode.replace('node', 'main-tr')), null, null, onlyShow);
                        nodeTr.dataset.childAreClosed = t === 'none' ? 'true' : 'false';
                        if (document.getElementById(nextNode).dataset.childAreClosed !== 'false') {
                            toggleSameTr(nextNode, true, onlyShow);
                            // if (r === false) {
                            //     break;
                            // }
                        }
                    }
                    // });
                } else {
                    const t = toggle(document.getElementById(nextNodes.replace('node', 'main-tr')), null, null, onlyShow);
                    nodeTr.dataset.childAreClosed = t === 'none' ? 'true' : 'false';
                    // toggleSameTr(nextNodes, true, document.getElementById(nextNodes).dataset.childAreClosed);
                    toggleSameTr(nextNodes, true, onlyShow);
                    // return false;


                    // if (document.getElementById(nextNodes).dataset.childAreClosed !== 'false') {
                    //     console.log(nextNodes, document.getElementById(nextNodes));
                    // } else {
                    //     console.log('else=>', nextNodes, document.getElementById(nextNodes));
                    // }
                }
            }
        } else {
            toggle(mainTr, null, null, onlyShow);
            // console.log('mainTr.style.display=>', mainTr.style.display);
            // if (mainTr.style.display === 'none') {
            //     mainTr.style.display = '';
            //     console.log('show');
            // } else {
            //     mainTr.style.display = 'none';
            //     console.log('hide');
            // }
        }
    }

    const mainTableRef = useRef();

    const onChangePagination = (e) => {
        removeTable(mainTableRef.current, true);
        removeChildRows(mainTableRef.current);
        if (typeof props.pagination.onChange === 'function') {
            props.pagination.onChange(e);
        }
    }
    const onClickPagination = (e) => {
        removeTable(mainTableRef.current, true);
        removeChildRows(mainTableRef.current);
        if (typeof props.pagination.onClick === 'function') {
            props.pagination.onClick(e);
        }
    }

    return (
        <div className={`table-responsive md-table`} style={props.ml ? { marginLeft: '7%' } : {}}>
            <table ref={mainTableRef} className={`table ${props.border === false && data && data.length ? 'table-borderless' : 'table-striped table-hover'} ${props.fixed ? 'table-layout-fixed' : ''} ${props.mb0 ? 'mb-0' : ''}`}>
                <thead className={`${!props.bgNone ? 'tableHead' : ''}`}>
                    <tr align={props.align}>
                        {props.columns.map((val, key) => {
                            return (
                                val.includes('|colpsan=2')
                                    ? <th key={key} scope="col" onClick={() => props.sorting && handleSort(val.replace('|colpsan=2', ''))} colSpan={2}>{val.replace('|colpsan=2', '')}</th>
                                    : <th key={key} onClick={() => props.sorting && handleSort(val)} scope="col">{val}
                                        <BsCaret dir={sortedColumn === val ? sortOrder : ''} />
                                    </th>
                            )
                        })}
                    </tr>
                    {
                        props.secondHeader ?
                            <tr align={props.align}>
                                {props.secondHeader.map((val, key) => {
                                    return (
                                        <th key={`sec${key}`} onClick={() => props.sorting && handleSort(val)} scope="col" className={val === 'RE' ? 'md-right' : val === 'CA' ? 'md-left' : ''}>{val}
                                            <BsCaret dir={sortedColumn === val ? sortOrder : ''} />
                                        </th>
                                    )
                                })}
                            </tr>
                            : ''
                    }
                </thead>
                <tbody>
                    {data && data.length
                        ? data.map((row, key) => {
                            lastTtableRowEvOdBgKey = lastTtableRowEvOdBgKey ? 0 : 1;
                            const tdColorClass = tableRowEvOdBg[lastTtableRowEvOdBgKey];
                            return (
                                <React.Fragment key={key}>
                                    <tr align={props.align} id={`main-tr-${row.id}`}>
                                        {
                                            Object.entries(row).map((td, i) => {
                                                if (props.skip && Array.isArray(props.skip) && props.skip.includes(td[0])) {
                                                    return '';
                                                }
                                                /*if (props.accordion && td[0] === props.accordion.key && typeof accordionData[td[1]] === 'object' && accordionData[td[1]] && typeof props.accordion.school === 'string' && props.accordion.school) {
                                                    // const accData = [];
                                                    return <td key={uniqKey(td[0])}><span onClick={(e) => handleShowMainAcc(e, accordionData[td[1]], props.accordion.school, `${key}main`, [accordionData,td])} className="underline cursor-pointer text-break">{td[1]}</span></td>
                                                    // accData.push(<SetCaReferralAccordion key={'SetCaReferralAccordion' + key} data={accordionData[td[1]]} school={props.accordion.school} />);

                                                    // return <td key={uniqKey(td[0])} className="accordion-td"><Accordion id={'_' + td[0] + key} title={td[1]}  body={accData} fit={true} handleShow={handleShowMainAcc} /></td>
                                                }*/
                                                if (['TotalRasied', 'TotalRaised'].includes(td[0])) {
                                                    td[1] = formatCurrency(td[1]) || '-';
                                                }
                                                if (['Deals'].includes(td[0])) {
                                                    td[1] = td[1] || '-';
                                                }
                                                if (props.accordion && td[0] && td[0] === props.accordion.key && td[1] && row.id) {
                                                    return <td id={`leaderboard-table-${row.id}`} key={uniqKey(td[0])} className={`md-left ${tdColorClass}`}><span onClick={(e) => handleShowMainAcc(e, { id: row.id }, props.accordion.school, `${key}main`)} className="underline cursor-pointer">{td[1]}</span></td>
                                                } else if (td[0] === 'REReferrals') {
                                                    return <td id={`leaderboard-table-${row.id}`} key={uniqKey(i)} className={`md-right ${tdColorClass}`}>{td[1]}</td>
                                                }
                                                else if (td[0] === 'CAReferrals') {
                                                    return <td id={`leaderboard-table-${row.id}`} key={uniqKey(i)} className={`md-left ${tdColorClass}`}><span>{td[1]}</span></td>
                                                }
                                                else if (td[0] === 'CAUser') {
                                                    return <td id={`leaderboard-table-${row.id}`} key={uniqKey(i)} className={`md-left md-pl-0 ${tdColorClass}`}>{td[1]}</td>
                                                }

                                                return <td id={`leaderboard-table-${row.id}`} key={uniqKey(i)} className={tdColorClass}><span>{td[1]}</span></td>
                                            })
                                        }
                                    </tr>
                                </React.Fragment>
                            )
                        })
                        : <BsTableEmpty defaultRow={props.defaultRow ? props.defaultRow : ""} />}
                </tbody>
            </table>
            {data && data.length && props.pagination && typeof props.pagination === 'object' ?
                <TablePagination {...props.pagination} onChange={onChangePagination} onClick={onClickPagination} /> : ''
            }
        </div>
    );
}

function TablePagination(props) {
    return <Pagination totalPages={props.totalPages} currentPage={props.currentPage} itemsPerPage={props.itemsPerPage} onChange={props.onChange} onClick={props.onClick} />
}

export function BsTableEmpty1(props) {
    return (
        <tr>
            <td colSpan={props.colSpan ? props.colSpan : 4} align="center">No Data Found</td>
        </tr>
    )
}

export function BsTableEmpty(props) {
    if (props.defaultRow) {
        const rows = Array.from({ length: 10 }, (_, index) => index);

        return (
            <>
                {rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {Array.from({ length: 6 }, (_, colIndex) => (
                            <td key={colIndex}>&nbsp;</td>
                        ))}
                    </tr>
                ))}
            </>
        );
    } else {
        return (
            <tr>
                <td colSpan={props.colSpan ? props.colSpan : 4} align="center">No Data Found</td>
            </tr>
        );
    }
}

function SetCaReferralAccordion({ data, school }) {
    const fetched = useRef([]);
    const [nestedAccordions, setNestedAccordions] = useState({});

    const handleShow = (event) => {
        const id = event.currentTarget.previousSibling.firstElementChild.firstElementChild.dataset.caReferralId;
        if (fetched.current.includes(id)) {
            return;
        }
        getCaRaferralData(id, school).then(res => {
            if (res.data.leaderboard && typeof res.data.leaderboard[0] === 'object' && res.data.leaderboard[0]) {
                setNestedAccordions(prevState => ({
                    ...prevState,
                    [id]: res.data
                }));
                fetched.current.push(id);
            }
        });
    };

    const renderNestedAccordions = (id, nestedData) => {
        if (!nestedData || typeof nestedData !== 'object') return null;

        return Object.entries(nestedData.leaderboard[0]).map((info, i) => {
            if (nestedData.CaReferralsInfo && typeof nestedData.CaReferralsInfo === 'object') {
                const d = nestedData.CaReferralsInfo;
                if (info[0] === 'CAReferrals') {
                    return <div key={`bodydiv${i}`}>{info[0]}: {info[1]}{
                        d.CaReferralsId.map((id, ind) => {
                            return <Accordion
                                fit={true}
                                style={{ width: 'fit-content' }}
                                handleShow={handleShow}
                                key={`ninnerAccordion${i}${d.CaReferralsId[ind]}`}
                                id={`ninnerAccordion${i}${d.CaReferralsId[ind]}`}
                                title={(
                                    <div
                                        className="caReferralDiv"
                                        data-ca-referral-id={d.CaReferralsId[ind]}
                                    >
                                        {d.CaReferralsName[ind]}
                                    </div>
                                )}
                                body={nestedAccordions[d.CaReferralsId[ind]] && renderNestedAccordions(d.CaReferralsId[ind], nestedAccordions[d.CaReferralsId[ind]])}
                            />
                        })
                    }</div>;
                }
                return <div key={`bodydiv${i}`}>{info[0]}: {info[1]}</div>;
            } else {
                return <div key={`bodydiv${i}`}>{info[0]}: {info[1]}</div>;
            }
        });
        // const body = Object.entries(nestedData).map(([key, value], index) => {
        //     return <div key={`bodydiv${id}${index}`}>{key}: {value[0]}</div>
        // });
        // return <Accordion
        //     key={`acc${id}`} // Generate a unique key for each nested accordion
        //     id={`acc${id}`}
        //     // title={key}
        //     body={body}
        //     title={(
        //         <div
        //             className="caReferralDiv"
        //             data-ca-referral-id={nestedData.CaReferralsId[0]}
        //         >
        //             {nestedData.CaReferralsName[0]}
        //         </div>
        //     )}
        // />
    };

    return data.map((info, i) => (
        <Accordion
            fit={true}
            handleShow={handleShow}
            key={`innerAccordion${i}`}
            id={`innerAccordion${i}`}
            title={(
                <div
                    className="caReferralDiv"
                    data-ca-referral-id={info.id}
                >
                    {info.username}
                </div>
            )}
            body={nestedAccordions[info.id] && renderNestedAccordions(info.id, nestedAccordions[info.id])}
        />
    ));
}

function SetCaReferralAccordionB({ data, school }) {
    const fetched = useRef([]);
    const handleShow = (event) => {
        const id = event.currentTarget.previousSibling.firstElementChild.firstElementChild.dataset.caReferralId;
        const body = event.currentTarget.firstElementChild;
        if (fetched.current.includes(id)) {
            return;
        }
        getCaRaferralData(id, school).then(res => {
            if (res.data.leaderboard && typeof res.data.leaderboard[0] === 'object' && res.data.leaderboard[0]) {
                let htmlhtml = '';
                Object.entries(res.data.leaderboard[0]).map((info, i) => {
                    htmlhtml += `<div>${info[0]}: ${info[1]}</div>`;
                });
                body.innerHTML = htmlhtml;
                fetched.current.push(id);
            }
        })
    };
    return data.map((info, i) => {
        return <Accordion
            fit={true}
            handleShow={handleShow}
            key={i + 'innerAccordioncarefdiv'}
            id={uniqKey(`innerAccordion${i}`)}
            title={
                <div key={i + 'carefdiv'}
                    className="caReferralDiv"
                    data-ca-referral-id={info.id}>
                    {info.name}
                </div>
            }
        />
    })
}
