import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Form from './pages/referral';
import Home from './pages/home/index';
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import Navbar from './served-components/header/navbar';
import Login from './pages/login';
import CreatePassword from './pages/create_password';
import SchoolDetail from './pages/detail/school';
import PrivateRoute from './PrivateRoute';
import Logout from './served-components/logout';
import HowItWorks from './pages/how_it_works';
import NoleCoin from './pages/nole_coin';
import ForgotPassword from './pages/forgot_password';
import ReferralEmail from './pages/referralemail';
import DeclineReferral from './pages/declineReferral';

function App() {
  return (
    <Router>
      {/* Header */}
      <NavbarWithCondition />
      <Routes>

        {/* Public Home Route */}
        <Route exact path='/' element={<Home />} />

        {/* Private Routes */}
        <Route path='u/leaderboard' element={<PrivateRoute Component={SchoolDetail} />} />
        <Route path='u/school_dashboard' element={<PrivateRoute Component={SchoolDetail} />} />

        {/* Public Routes */}
        <Route path='/referral' element={<Form />} />
        <Route path='/:school/referral' element={<Form />} />
        <Route path='/login' element={<Login />} />
        <Route path='/logout' element={<Logout />} />
        <Route path='/create_password/:id' element={<CreatePassword />} />
        <Route path='/:school/:cauname?' element={<SchoolDetail />} />
        <Route path='how_it_works' element={<HowItWorks />} />
        <Route path='/:school/how_it_works/:cauname?' element={<HowItWorks />} />
        <Route path='/nole_coin' element={<NoleCoin />} />
        <Route path='/forgot_password' element={<ForgotPassword />} />
        <Route path='referralemail/confirm/:referralFormId/:userId' element={<ReferralEmail />} />
        <Route path='referralemail/decline/:referralFormId/:userId' element={<DeclineReferral />} />

        {/* Page Not Found */}
        <Route path="*" element={<h1 align="center" className='m-5 text-danger'>404 Page Not Found!</h1>} />

      </Routes>

    </Router>
  );
}

function NavbarWithCondition() {
  const location = useLocation();
  const decodedPathname = decodeURIComponent(location.pathname);
  const show = ['/', '/referral', '/login', '/create_password', '/u', '/how_it_works', '/nole_coin', '/forgot_password'].includes(decodedPathname) || decodedPathname.includes('/referral') || decodedPathname.includes('/how_it_works') || decodedPathname.includes('/create_password') 
  || decodedPathname.includes('/referralemail')
  ;
  const dontShow = !['/u/leaderboard'].includes(decodedPathname);

  // const shouldShowNavbar = (!/^\/[^\/]+$/gmi.test(decodedPathname) || !/^\/[^\/]+$/gmi.test(decodedPathname) || show) && dontShow;
  const shouldShowNavbar = show && dontShow;
  if (!shouldShowNavbar) {
    document.body.removeAttribute('data-bs-theme');
  } else {
    document.body.setAttribute('data-bs-theme', 'dark');
  }
  return (
    shouldShowNavbar && <Navbar path={decodedPathname} />
  );
}

export default App;
