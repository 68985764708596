import React, { useState, useEffect } from 'react';
import { getCollegeRank, searchSchool } from "../../api/rankcollegeApi";
import { TablePlaceholder } from '../../served-components/form/placeholder';
import Pagination from '../../served-components/pagination/pagination';
import { BsCaret } from './components/icons';
import { Link, useLocation } from 'react-router-dom';
import { BsTableEmpty } from '../../served-components/table';
import { setColorByFullSchool } from '../../helpers/setColors';
import { sData, setSData, uData } from '../../helpers/sData';
import { faMagnifyingGlass, faPiggyBank, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatCurrency } from '../../helpers/val';
import { BsSelect } from '../../served-components/form/select';
import AddToHome from '../login/addToHome';

export default function Home() {
    const [data, setData] = useState([]);
    const [dataFound, setDataFound] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(15);
    const [sortedColumn, setSortedColumn] = useState('asc');
    const [sortOrder, setSortOrder] = useState(null);
    const location = useLocation();
    const [singleSchool, setSingleSchool] = useState('');
    if (!sData('cauname')) {
        setSData(uData().val());
    }
    useEffect(() => {
        getCollegeRank(currentPage, itemsPerPage, location.pathname.startsWith('/u'), singleSchool).then(res => {
            setData(res.data);
            if (!res.data.length) {
                setDataFound(false);
            }
            setTotalPages(res.totalPages);
        }).catch(err => console.warn(err))
    }, [currentPage, itemsPerPage, singleSchool]);
    setColorByFullSchool(null, 'light');

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };
    const setItem = (e) => {
        setItemsPerPage(e.currentTarget.value);
        setCurrentPage(1);
    }

    const handleSort = (key) => {
        if (key === 'team') return;
        const isAsc = sortedColumn === key && sortOrder === 'asc';
        const newSortOrder = isAsc ? 'desc' : 'asc';
        const sortedData = [...data].sort((a, b) => {
            let row1 = a[key];
            let row2 = b[key];
            if (['ap_rank', 'members', 'referrals', 'nil_raised'].includes(key)) {
                row1 = row1 ? Number(row1.toString().replaceAll(/[^0-9\.]/g, '')) : row1;
                row2 = row2 ? Number(row2.toString().replaceAll(/[^0-9\.]/g, '')) : row2;
            }

            if (isAsc) {
                return row1 > row2 ? 1 : -1;
            } else {
                return row1 < row2 ? 1 : -1;
            }
        });
        setData(sortedData);

        setSortedColumn(key);
        setSortOrder(newSortOrder);
    };

    const getSchool = async (e) => {
        const value = e.target.value;
        return await searchSchool(value);
    }

    const handleSchoolSelect = (_, school) => {
        console.log(school);
        setSingleSchool(school);
    }

    // AP Rank	
    // School (Label Will be Full School Name) still hyperlink to go direct to school page
    // Team 	
    // Members	
    // Referrals	
    // NIL Raised	
    const columnKey = {
        'AP': 'ap_rank',
        'School': 'team',
        'Members': 'members',
        'Referrals': 'referrals',
        'Raised': 'nil_raised',
    }
    const columns = [
        { name: 'AP', image: '/ap-logo.png' },
        // 'Efficiency Rank',
        // 'Total $ Rank',
        // { name: 'School', icon: faSchool },
        { name: 'School', jsx: <div className='d-flex align-items-center table-search'><FontAwesomeIcon icon={faMagnifyingGlass} /><BsSelect suggestionBox={true} value='' label='School' simple={true} searchFunction={getSchool} changeEvent={handleSchoolSelect} /></div> },

        // 'Full School Name',
        // 'Team',
        { name: 'Members', icon: faUser },
        // 'Collective',
        { name: 'Referrals', icon: faUsers },
        { name: 'Raised', icon: faPiggyBank },
        // 'Focus',
        // 'Description',
        // 'State',
        // 'Total Revenue',
        // 'Total Revenue Year',
        // 'Total Expenses',
        // 'Total Expenses Year',
        // 'Total Profit'
    ];
    return (
        <>
        <AddToHome />
            <div className="table-responsive p-2 mb-3 overflow-x-visible">
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            {columns.map((val, key) => {
                                return (
                                    <th style={{ backgroundColor: '#052c3b', width: 0 }} className={`defaultTextColor pointer sorting asc`} onClick={() => handleSort(columnKey[val.name.replaceAll(' ', '')])} key={key} scope="col">
                                        {val.icon ?
                                            <FontAwesomeIcon icon={val.icon} title={val.name} />
                                            : val.image ?
                                                <img className='icon' style={{ height: '1.5rem' }} src={val.image} alt='AP' title={val.name}></img>
                                                : val.jsx
                                                    ? val.jsx
                                                    : val.name
                                        }
                                        <BsCaret dir={sortedColumn === columnKey[val.name.replaceAll(' ', '')] ? sortOrder : ''} />
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.length
                            ? data.map((val, key) => {
                                let collectives = val.collective;
                                const collectiveBody = [];
                                const collectiveTitle = [];
                                if (collectives) {
                                    collectives = collectives.split('|');
                                    let i = 0;
                                    collectives.forEach(collective => {
                                        if (i === 0) {
                                            collectiveTitle[i] = <span key={`accordianTitleRank${key}${i}`}>{collective}</span>;
                                        } else {
                                            collectiveBody[i] = <div key={`accordianBodyRank${key}${i}`}><span>{i + 1}: </span>{collective}</div>;
                                        }
                                        i++;
                                    });
                                }
                                return (
                                    <tr key={key}>
                                        <td>{val.ap_rank || '-'}</td>
                                        {/* <td>{val.efficiency_rank}</td>
                                        <td>{val.total_dollar_rank}</td> */}
                                        <td><Link className='defaultTextColor' to={`/${val.school}`} state={{ full_school_name: val.full_school_name }}>{val.school === 'FloridaState' ? 'Florida State' : val.school || '-'}</Link></td>
                                        {/* <td>{val.full_school_name}</td> */}
                                        {/* <td>{val.team}</td> */}
                                        <td>{val.members || '-'}</td>
                                        {/* <td>{collectiveTitle && collectiveTitle.length && collectiveBody && collectiveBody.length ? <Accordion id={`rankTable${key}`} title={collectiveTitle} body={collectiveBody} /> : val.collective}</td> */}
                                        <td>{val.referrals || '-'}</td>
                                        <td>{formatCurrency(val.nil_raised) || '-'}</td>
                                        {/* <td>{val.focus}</td> */}
                                        {/* <td>{val.description}</td> */}
                                        {/* <td>{val.state}</td>
                                        <td>{val.total_revenue}</td>
                                        <td>{val.total_revenue_year}</td>
                                        <td>{val.total_expenses}</td>
                                        <td>{val.total_expenses_year}</td>
                                        <td>{val.total_profit}</td> */}
                                    </tr>
                                )
                            })
                            : dataFound === false ? <BsTableEmpty colSpan={5} /> : <TablePlaceholder tr="12" td="5" />}
                    </tbody>
                </table>
            </div>
            <div className='main-body'>
                <Pagination totalPages={totalPages} currentPage={currentPage} itemsPerPage={itemsPerPage} onChange={setItem} onClick={handlePageChange} />
            </div>
        </>
    );
}
