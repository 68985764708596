import { CountyProvider } from "../../served-components/field/county";
import { MsaProvider } from "../../served-components/field/msa";
import { StateProvider } from "../../served-components/field/state";
import RForm from './mainReferral';
export default function Form() {
    return (
        <StateProvider>
            <MsaProvider>
            <CountyProvider>
                <RForm />
            </CountyProvider>
            </MsaProvider>
        </StateProvider>
    )
}
