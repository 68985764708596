export function isLogedIn() {
    const token = localStorage.getItem('loginagenttoken');
    if (token && token != 'null' && token != 'undefined' && typeof token === 'string') {
        return true;
    }
    return false;
}

export function getToken() {
    return localStorage.getItem('loginagenttoken');
}

export function deleteLoginDetail() {
    localStorage.clear();
}

export function setLoginDetail(detail) {
    localStorage.setItem('login_agent_msa', detail.login_agent_msa);
    localStorage.setItem('login_agent_county', detail.login_agent_county);
    localStorage.setItem('login_agent_state', detail.login_agent_state);
    localStorage.setItem('loginagenttoken', detail.token)
}