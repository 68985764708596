import axios from "axios";
import { deleteLoginDetail, getToken, isLogedIn } from "../helpers/loginCheck";

export const API_KEY = 'broker3112linux117';
// const API_BASE_URL = 'http://broker.com/index.php/';
//const API_BASE_URL = 'http://127.0.0.1:8000/api/';
const API_BASE_URL = 'https://api.collectiveagent.co/api/';

// const API_BASE_URL = 'https://brokerlinx.com/crm/';

export async function get(path, params='',config){
    const API_TOKEN = getToken();
    // params = `apikey=${API_KEY}&token=${API_TOKEN}${params ? `&${params}` : ''}`;
    params = `apikey=${API_KEY}&${params ? `&${params}` : ''}`;
    if(isLogedIn()){
        config = {
            headers: { Authorization: `Bearer ${API_TOKEN}` }
        };
        path = `u/${path}`;
    }
    const x = await axios.get(`${API_BASE_URL}${path}?${params}`,config);
    return await x.data;
}

export async function post(path, data, config){
    const API_TOKEN = getToken();
    // const x = await axios.post(`${API_BASE_URL}${path}?apikey=${API_KEY}&token=${API_TOKEN}`, data, config);
    if(isLogedIn()){
        config = {
            headers: { Authorization: `Bearer ${API_TOKEN}` }
        };
        path = `u/${path}`;
    }
    const x = await axios.post(`${API_BASE_URL}${path}?apikey=${API_KEY}`, data, config);
    return await x.data;
}

function handleUnauthenticated(res){
    return new Promise((resolve, reject) => {
        if(res && res.message && res.message === 'Unauthenticated.'){
            deleteLoginDetail();
            setTimeout(()=>{
                window.location.reload();
            },100);
        }else{
            resolve();
        }
    })
}

