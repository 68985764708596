import React, { useState, useEffect } from 'react';
import { uniqKey } from '../../helpers/key';

const Pagination = ({ totalPages, currentPage, itemsPerPage, onChange, onClick, limitOptions = [10,15, 25, 30, 50, 100] }) => {
    return (
        <div className='container'>
            <nav aria-label="..." style={{ float: 'left' }}>
                <ul className="pagination pagination-sm">
                    <li className={`page-item ${currentPage === 1 ? 'disabled eventNone' : ''} pointer`} onClick={() => onClick(currentPage - 1)}>
                        <span className="page-link">        <span aria-hidden="true">&laquo;</span></span>
                    </li>
                    <li className="page-item active pointer" aria-current="page">
                        <span className="page-link">{currentPage}</span>
                    </li>
                    <li className={`page-item ${currentPage === totalPages ? 'disabled eventNone' : ''} pointer`} onClick={() => onClick(currentPage + 1)}>
                        <a className="page-link" href="#"><span aria-hidden="true">&raquo;</span></a>
                    </li>
                </ul>
            </nav>
            <div style={{ float: 'right' }}>
                <select name="limit" id="limit" className="form-select" onChange={e => onChange(e)} value={itemsPerPage}>
                    {
                        limitOptions.map(option => {
                            return <option key={uniqKey(option)} value={option} >{option}</option>
                        })
                    }
                </select>
            </div>
        </div>
    );
};

export default Pagination;
